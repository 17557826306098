var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PropertyEditor"),
      _vm.loading ? _c("LoadingSpinner") : _vm._e(),
      _c("WorkPacks", {
        attrs: {
          title: "Items",
          headers: _vm.headers,
          data: _vm.data,
          "toolbar-buttons": _vm.toolbarButtons(
            ["batchDelete", "transfer"],
            _vm.selectedRows.length === 0
          )
        },
        on: {
          onToolbarButtonClicked: _vm.handleToolbarButtonClicked,
          onSelectedRowsChanged: _vm.handleSelectedRowsChanged,
          onActionRequested: _vm.handleActionRequested
        }
      }),
      _c("CloseOutDialog", {
        attrs: {
          "initial-show": _vm.showSelectTargetWorkPack,
          options: _vm.availableWorkPacks,
          "option-ids": _vm.workPacks,
          "transfer-from": _vm.transferFrom,
          "transfer-items": _vm.selectedRows.map(function(ref) {
            var tag = ref.tag

            return tag
          })
        },
        on: {
          onTransfer: _vm.handleTransferItems,
          onCancel: _vm.handleCancelTransfer
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }