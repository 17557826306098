var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Settings", {
    attrs: {
      inspections: _vm.inspections,
      loading: _vm.loading,
      "application-id": _vm.applicationId,
      user: _vm.user,
      users: _vm.users,
      roles: _vm.roles,
      groups: _vm.groups,
      connections: _vm.connections,
      "group-management-user-capability": _vm.groupManagementUserCapability,
      "group-management-inspection-capability":
        _vm.groupManagementInspectionCapability,
      "send-invitation-capability": _vm.sendInvitationCapability
    },
    on: {
      onInspectionClick: _vm.handleInspectionClick,
      loading: _vm.setLoading
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }