var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DataTable",
        {
          attrs: {
            headers: _vm.inspectionConfig.aggregateEquipmentHeaders,
            data: _vm.data,
            unit: _vm.$store.state.unit,
            "items-per-page": 20,
            loading: _vm.loading,
            "loading-text": "Loading metrics, please wait...",
            "primary-key": "filterKey",
            selectable: "",
            "generate-csv-trigger": _vm.generateCsvTrigger
          },
          on: {
            onTableCellClick: _vm.handleTableCellClick,
            onCsvGenerated: _vm.handleCsvGenerated,
            onSelectedRowsChanged: _vm.handleSelectedRowsChanged
          }
        },
        [
          _c("ButtonToolbar", {
            attrs: { slot: "toolbar", buttons: _vm.toolbarButtons },
            on: { onButtonClicked: _vm.handleToolbarButtonClicked },
            slot: "toolbar"
          })
        ],
        1
      ),
      _c("Snackbar", {
        attrs: { type: _vm.notification, text: _vm.notificationMessage },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }