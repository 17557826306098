<template>
  <DeckPlan
    :data="data"
    :decks="decks"
    :snack-bar-type="snackBarType"
    :snack-bar-text="snackBarText"
    :corrosion-layers="inspectionConfig.corrosionLayers"
    :enable-heat-maps="inspectionConfig.platformFeatures.hasHeatMaps"
    :table-headers="inspectionConfig.deckPlanHeaders"
    @onSnackbarDisplay="handleSnackbarDisplay"
    @onSnackbarClose="handleSnackbarClose"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import MetricController from '@/controllers/MetricController';
import { notificationType } from '@/components/widgets';
import DeckPlan from './DeckPlan.vue';

export default {
  name: 'DeckPlanContainer',
  components: {
    DeckPlan,
  },
  data() {
    return {
      data: undefined,
      decks: ['All'],
      snackBarType: notificationType.none,
      snackBarText: '',
      selectedDeck: 'All',
    };
  },
  computed: mapGetters({
    inspectionConfig: 'config/inspectionConfig',
  }),
  async created() {
    ({ data: this.data } = await MetricController.getMetricChildren2(this.$route.query.id, 'deck', 2, []));
    this.decks = this.data.map(({ name }) => name).concat('All');
  },
  methods: {
    handleSnackbarDisplay(message, type = notificationType.message) {
      this.snackBarType = type;
      this.snackBarText = message;
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
  },
};
</script>
