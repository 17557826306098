<template>
  <div :class="{ 'filters-horizontal': horizontalToggler }">
    <ListInputField
      v-for="[key, { options, displayName }] in Object.entries(filterParams)"
      :key="key"
      :label="displayName"
      prepend-icon="mdi-database-search"
      placeholder="Start typing to Search"
      :initial-selected="state[key]"
      :options="options"
      :multiple="multiple"
      autoComplete
      :prevent-partial-match="preventPartialMatch"
      @onChange="handleChange($event, key)"
    />
  </div>
</template>

<script>
import { isNil } from 'lodash';
import { ListInputField } from '@/components';

const CORROSION_STATES = ['Clean', 'Light', 'Moderate', 'Heavy'];

export default {
  name: 'AdvancedFilter',
  components: {
    ListInputField,
  },
  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    updatedParams: {
      type: Array,
      default: () => [],
    },
    initialState: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontalToggler: {
      type: Boolean,
      default: false,
    },
    prefixIcon: {
      type: String,
      default: 'mdi-database-search',
    },
    dropdownInitialValue: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    preventPartialMatch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      state: {},
    };
  },
  watch: {
    // filter updated from external
    updatedParams(params) {
      this.state = params.reduce(
        (data, { key, value }) => ({
          ...data,
          [key]: value == null || Array.isArray(value) ? value : [value],
        }),
        { ...this.initialState }
      );

      const corrosionCategory = this.state['metrics.corrosion_category'];
      if (corrosionCategory) {
        this.state['metrics.corrosion_category'] = this.toCorrosionStrings(corrosionCategory);
      }
    },
    dropdownInitialValue() {
      this.registerInitialValue();
    },
  },
  beforeMount() {
    this.registerInitialValue();
  },
  methods: {
    registerInitialValue() {
      // Terrible HACK to set initial values based on the initialValue property in filterParams
      //   and triggered when dropdownInitialValue is set ???
      Object.entries(this.filterParams).forEach(([element, { initialValue }]) => {
        this.state[element] = initialValue;
      });
    },
    toCorrosionStrings(indexes) {
      return indexes.map((index) => CORROSION_STATES[index]);
    },
    toCorrosionIndexes(strings) {
      return strings.map((string) => CORROSION_STATES.indexOf(string));
    },
    handleChange(data, field) {
      this.state[field] = data?.length > 0 ? data : null;

      const [activeParams, clearedParamKeys] = Object.entries(this.state).reduce(
        ([active, cleared], [key, value]) => {
          if (isNil(value)) {
            return [active, cleared.concat(key)];
          }
          // format corrosion categories for queries
          if (key === 'metrics.corrosion_category') {
            return [active.concat({ key, value: this.toCorrosionIndexes(value) }), cleared];
          }
          return [active.concat({ key, value }), cleared];
        },
        [[], []]
      );

      this.$emit('onChange', activeParams, clearedParamKeys);
    },
  },
};
</script>

<style scoped>
.filters-horizontal {
  display: flex;
}
</style>
