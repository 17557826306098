var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle-2" }, [_vm._v("Corrosion")]),
      _c("CoveragePercentageRows", {
        attrs: {
          rows: _vm.filteredCorrosionRows,
          "metric-surface-area": _vm.props.metricSurfaceArea
        }
      }),
      _c("div", { staticClass: "body-2" }, [
        _vm._v(
          "Substrate condition: " +
            _vm._s(_vm.greatestAreaName(_vm.filteredCorrosionRows))
        )
      ]),
      _vm.filteredDegreeOfRustingRows.length > 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "subtitle-2" }, [
                _vm._v("Degree of Rusting")
              ]),
              _c("CoveragePercentageRows", {
                attrs: { rows: _vm.filteredDegreeOfRustingRows }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }