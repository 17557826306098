<template>
  <div>
    <div class="subtitle-2">Corrosion</div>
    <CoveragePercentageRows :rows="filteredCorrosionRows" :metric-surface-area="props.metricSurfaceArea" />
    <div class="body-2">Substrate condition: {{ greatestAreaName(filteredCorrosionRows) }}</div>
    <div v-if="filteredDegreeOfRustingRows.length > 0">
      <div class="subtitle-2">Degree of Rusting</div>
      <CoveragePercentageRows :rows="filteredDegreeOfRustingRows" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

import { getSubstrateCondition } from '@/utils';
import { CoveragePercentageRows } from './components';

export default {
  name: 'CoverageLevels',
  components: {
    CoveragePercentageRows,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    filteredCorrosionRows() {
      const order = ['AC-L', 'AC-M', 'AC-H'];
      const filterKeysRegex = new RegExp('^AC-[LMH]$');
      const data = this.props.coverageLayers
        .filter(({ key }) => filterKeysRegex.test(key))
        .sort((a, b) => order.findIndex((x) => x === a.key) - order.findIndex((x) => x === b.key));
      return order.map(
        (x) => data.find(({ key }) => key === `${x}`) || get(this.inspectionConfig, `corrosionLayers.${x}`, {})
      );
    },
    filteredDegreeOfRustingRows() {
      const filterKeysRegex = new RegExp('^Ri[0-5]$');
      const data = this.props.coverageLayers.filter(({ key }) => filterKeysRegex.test(key));
      return ['0', '1', '2', '3', '4', '5'].map(
        (x) =>
          data.find(({ key }) => key === `Ri${x}`) || {
            area: 0,
            displayExtraInfo: undefined,
            friendlyName: `Ri${x}`,
            key: `Ri${x}`,
          }
      );
    },
  },
  methods: {
    greatestAreaName(layers) {
      return getSubstrateCondition(layers).friendlyName;
    },
  },
};
</script>
