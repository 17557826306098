import { toString, get } from 'lodash';

// Convert an array e.g. ['one', 'two', 'three'] to a nested object: { one: { two: { three: {} } } }
export const arrayToNestedObject = (array) => {
  const data = {};
  array.reduce((ptr, curr) => {
    // eslint-disable-next-line no-param-reassign
    ptr[curr] = {};
    return ptr[curr];
  }, data);
  return data;
};

export const mapReferringImages = (images = [], additionalOptions = {}) =>
  images.map(({ image_id, image_name, metrics = {} }) => ({
    image_id,
    image_name: image_name.split('/').slice(-1)[0],
    depth_mean: metrics.depth_mean,
    x_mean: metrics.x_mean,
    y_mean: metrics.y_mean,
    z_mean: metrics.z_mean,
    corrosion_state: ['Clean', 'Light', 'Moderate', 'Heavy'][metrics.corrosion_category],
    total: Object.values(metrics.coverage || {})
      .reduce((a, b) => a + b, 0)
      .toFixed(4),
    ...additionalOptions,
  }));

export const getKeyValueStringFromJson = (data) =>
  data ? toString(Object.entries(data).map(([dataKey, dataValue]) => `${dataKey}: ${toString(dataValue)}`)) : undefined;

export const replaceMatchedKeys = (data, matchKey) => {
  const recursiveMerge = (node) => {
    if (Array.isArray(node)) {
      return node.map(recursiveMerge);
    }
    if (typeof node === 'object') {
      return Object.entries(node).reduce((merged, [key, value]) => {
        if (typeof value === 'object') {
          return { [key]: recursiveMerge(value), ...merged };
        }
        if (key === matchKey) {
          return { ...data[value], ...merged };
        }
        return { ...merged, [key]: value };
      }, {});
    }
    return node;
  };
  return recursiveMerge;
};

export const UNGROUPED = Symbol('Ungrouped');

export const groupBy = (data, key) =>
  data.reduce((groups, { [key]: group = UNGROUPED, ...rest }) => {
    // eslint-disable-next-line no-param-reassign
    groups[group] = (groups[group] || []).concat(rest);
    return groups;
  }, {});

export const groupByAndModify = (data, key, modifier = (x) => x) =>
  data.reduce((groups, { [key]: group = UNGROUPED, ...rest }) => {
    // eslint-disable-next-line no-param-reassign
    groups[group] = (groups[group] || []).concat(modifier({ ...rest, [key]: group }));
    return groups;
  }, {});

// get the index and value of the object containing the first matched (possibly nested) item
export const findIndexInNestedObjectValues = (data, path, match) => {
  let result;
  Object.values(data).some((value) => {
    const index = (path ? get(value, path, []) : value).findIndex(match);
    if (index >= 0) {
      result = { index, value };
      return true;
    }
    return false;
  });

  return result || { index: -1 };
};

export const flattenObj = (obj, parent, res = {}) =>
  Object.entries(obj).reduce((accu, [key, value]) => {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof value === 'object' && !Array.isArray(value)) {
      return flattenObj(obj[key], propName, accu);
    }
    return { ...accu, [propName]: obj[key] };
  }, res);

export const matchingIndexes = (items, predicate) =>
  items.reduce((indexes, item, index) => (predicate(item) ? indexes.concat(index) : indexes), []);

const matchedIndexes = (items, indexes) => indexes.reduce((matched, index) => matched.concat(items[index]), []);

export const matchedItemsByPosition = (items, matchOn, predicate) =>
  matchedIndexes(items, matchingIndexes(matchOn, predicate));

// Expand an array of key/value objects, such as:
// [
//   { key: 'A', value: [1, 2] },
//   { key: 'single', value: ['one'] },
//   { key: 'empty', value: [] },
//   { key: 'null', value: null }
// ]
// to: [ 'and', [ 'or', { A: 1 }, { A: 2 } ], { single: 'one' } ]
export const expandKeyValuesAsOperations = (data = []) => {
  const orOperations = (key, values) => {
    if (values.length === 1) {
      return { [key]: values[0] };
    }
    return values.reduce((operations, item) => operations.concat({ [key]: item }), ['or']);
  };

  const filtered = data.filter(({ value }) => value);
  if (filtered.length === 0) {
    return [];
  }
  if (filtered.length === 1) {
    const [{ key, value }] = filtered;
    return orOperations(key, value);
  }
  return filtered.reduce(
    (operations, { key, value }) => {
      if (value?.length > 0) {
        operations.push(orOperations(key, value));
      }
      return operations;
    },
    ['and']
  );
};
