import { formatAreaMeasurement } from '@/utils/measure';

export default ({ name, area, unit } = {}) => {
  const text = [
    '<div class="subtitle-2">Name</div>',
    `<div class="body-2">${name}</div>`,
    '<div class="subtitle-2">Area</div>',
  ];

  if (area != null) {
    const measurement = formatAreaMeasurement({
      squareMeters: area,
      unit,
      displayUnit: true,
    });

    text.push(`<div>${measurement}</div>`);
  } else {
    text.push('<div>Not calculated</div>');
  }

  return text.join('');
};
