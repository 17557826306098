<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="selectedDeck"
          :items="decks"
          label="Deck Selection"
          filled
          dense
          @change="handleSelectedDeckChanged"
        />
      </v-col>
      <v-col>
        <v-tabs v-model="tabsModel" @change="handleTabChanged">
          <v-tab>Camera Positions</v-tab>
          <v-tab>Paint Regions</v-tab>
          <v-tab v-if="enableHeatMaps">Heat Maps</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels v-model="defaultVisibleCollapsedLayers" class="padding12" multiple>
        <v-expansion-panel>
          <v-row v-if="showMapSizeToggler" class="d-flex justify-center">
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn color="secondary" style="z-index: 2" fab x-small dark v-on="on" @click="toggleOverlaySize">
                  <v-icon>mdi-arrow-top-left-bottom-right</v-icon>
                </v-btn>
              </template>
              <span>Toggle Map Size</span>
            </v-tooltip>
          </v-row>
          <v-expansion-panel-header ref="porto">
            Deck Map
            <v-spacer />
            {{ formatImageNameForDisplay(spatialHoverLocation) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col :style="{ transition: '0.3s', margin: '0 auto' }" sm="12" :md="computedOverlaySize">
              <Overlay
                :source="spatialDeckResource"
                :polygons="spatialPolygons"
                :heatmap-source="heatmapFilter.source"
                :active-polygon-id="spatialHoverLocation"
                @poly-hover="locationMouseOver"
                @poly-selected="locationClicked"
              />
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="tabsModel === TabsEnum.CAMERA || tabsModel === TabsEnum.REGION">
          <v-expansion-panel-header>Spatial View Parameters</v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- SpatialViewParameter component -->
            <SpatialViewParameter :spatial-filter="spatialFilter" :active-tab="tabsModel" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="tabsModel === TabsEnum.HEATMAPS">
          <v-expansion-panel-header ref="porto">Heat Map Filters</v-expansion-panel-header>
          <v-expansion-panel-content>
            <DeckPlanFilter
              ref="deckPlanFilter"
              :height-range="activeDeckDocument && activeDeckDocument.heightRange"
              :height-step="activeDeckDocument && activeDeckDocument.heightStep"
              :loading="loading"
              :is-source-loaded="Boolean(heatmapFilter.source)"
              @filter="updateSpatialHeatmap"
              @clearFilter="handleClearSpatialHeatmap"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-col>
        <v-card>
          <DataTable
            :headers="activeTableHeaders"
            :data="activeTableData"
            :unit="$store.state.unit"
            primary-key="id"
            @onTableCellClick="handleTableCellClick"
          />
        </v-card>
      </v-col>
    </v-row>
    <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
  </v-container>
</template>

<script>
import { get, isEqual } from 'lodash';
import MetricController from '@/controllers/MetricController';
import DeckController from '@/controllers/DeckController';
import Overlay from '@/components/Overlay.vue';
import SpatialViewParameter from '@/components/widgets/SpatialViewParameter.vue';
import DeckPlanFilter from '@/components/widgets/DeckPlanFilter.vue';
import utils from '@/utils';
import { Snackbar, notificationType } from '@/components/widgets';
import { DataTable } from '@/components';

import blankPlan from '@/assets/blank_plan.png';

const TabsEnum = {
  CAMERA: 0,
  REGION: 1,
  HEATMAPS: 2,
};

const OverlaySizeEnum = {
  SMALL: 'small',
  REGULAR: 'regular',
  LARGE: 'large',
};

export default {
  name: 'DeckPlan',
  components: {
    Overlay,
    SpatialViewParameter,
    Snackbar,
    DeckPlanFilter,
    DataTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    decks: {
      type: Array,
      default: () => [],
    },
    corrosionLayers: {
      type: Object,
      default: () => ({}),
    },
    snackBarType: {
      type: String,
      default: '',
    },
    snackBarText: {
      type: String,
      default: '',
    },
    enableHeatMaps: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      TabsEnum,
      OverlaySizeEnum,
      tabsModel: TabsEnum.CAMERA,
      selectedDeck: 'All',

      overlaySize: OverlaySizeEnum.REGULAR,

      defaultVisibleCollapsedLayers: [0], // Set first accordion to open

      rawTableData: [],

      cameraTable: [],
      regionTable: [],
      heatMapTable: [],

      loading: true,
      spatialHoverLocation: '',
      spatialDeckResource: blankPlan,
      spatialFilter: {
        activeMetric: 'AC-L',
        aggMethod: 'heatmap',
        metrics: {
          'AC-L': {
            text: 'AC-L',
            max: 0,
          },
        },
        markerScale: 4,
        threshold: 0.01,
        thresholdMax: 0,
        thresholdDisabled: false,
        color: 'primary',
      },
      heatmapFilter: {
        type: 'block',
        heightRange: [0, 1],
        modes: ['substrateConditionMax'],
        source: '',
      },
      activeTableHeaders: this.tableHeaders.cameraPositions,
    };
  },
  computed: {
    activeTableData() {
      switch (this.tabsModel) {
        case TabsEnum.CAMERA:
          return this.cameraTable;
        case TabsEnum.REGION:
          return this.regionTable;
        case TabsEnum.HEATMAPS:
          return this.heatMapTable;
        default:
          throw new Error(`Unexpected tabsModel '${this.tabsModel}' for DeckPlan`);
      }
    },
    activeDeckDocument() {
      return this.data.find((deck) => deck?.data?.resource === this.spatialDeckResource);
    },
    showMapSizeToggler() {
      return this.defaultVisibleCollapsedLayers.find((x) => x === 0) !== undefined;
    },
    computedOverlaySize() {
      switch (this.overlaySize) {
        case OverlaySizeEnum.SMALL:
        case OverlaySizeEnum.REGULAR:
          return 12;
        case OverlaySizeEnum.LARGE:
        default:
          return 9;
      }
    },

    spatialPolygons() {
      const clamp = (min, max) => (number) => Math.max(min, Math.min(number, max));
      const clamp0to1 = clamp(0, 1);

      const hslaColor = ({ coverage }, { activeMetric, thresholdMax }) => {
        const range = 90;
        const start = 250;
        const color = start + range * clamp0to1(coverage[activeMetric].mean / thresholdMax);
        return `hsla(${color}, 100%, 50%, 0.5)`;
      };

      const matchesSpatialDeckResource = ({ deckResource }) => deckResource === this.spatialDeckResource;

      let filtered = [];
      if (this.cameraTable && this.tabsModel === TabsEnum.CAMERA) {
        filtered = this.cameraTable.filter(matchesSpatialDeckResource);
      } else if (this.regionTable && this.tabsModel === TabsEnum.REGION) {
        filtered = this.regionTable.filter(matchesSpatialDeckResource);
      }

      return filtered.map(({ metrics, location, imagePlanInfo, polygons }) => {
        let size = this.spatialFilter.markerScale;
        let color = 'rgba(0,0,0,0.5)';
        let strokeColor = 'black';
        let strokeWidth = 1;

        const activeMetric = get(metrics.coverage, [this.spatialFilter.activeMetric]);

        if (this.spatialFilter.aggMethod === 'threshold' && activeMetric?.mean > this.spatialFilter.threshold) {
          color = 'rgba(255,0,0,0.5)';
        }

        if (this.spatialFilter.aggMethod === 'heatmap' && activeMetric) {
          color = hslaColor(metrics, this.spatialFilter);
        }

        if (this.spatialHoverLocation === location) {
          strokeColor = 'orange';
          strokeWidth = 2;
          size *= 2;
        }

        let points;
        if (imagePlanInfo) {
          const [p1, p2] = imagePlanInfo[0].position;
          points = [
            [p1 - size, p2 - size], // top left
            [p1 + size, p2 - size], // top right
            [p1 + size, p2 + size], // bottom right
            [p1 - size, p2 + size], // bottom left
          ];
        } else {
          [{ points }] = polygons;
        }

        return {
          id: location,
          points,
          color,
          strokeColor,
          strokeWidth,
        };
      });
    },
    currentWindowResolution() {
      return utils.getWindowResolution();
    },
  },
  watch: {
    data(_, previous) {
      // Hack (until logic moved to controller) to update the table when data first loaded
      if (previous.length === 0) {
        this.updateTable();
      }
    },
    spatialDeckResource(now, old) {
      if (now && now !== old) {
        // reset spatial heatmap filter
        this.heatmapFilter = {
          type: 'block',
          heightRange: [0, 1],
          modes: ['substrateConditionMax'],
          source: '',
        };
      }
    },
  },
  beforeMount() {
    this.initialise();
  },
  methods: {
    formatImageNameForDisplay: utils.formatImageNameForDisplay,
    async initialise() {
      try {
        const layers = Object.keys(this.corrosionLayers);
        layers.forEach((iterator) => {
          this.spatialFilter.metrics[iterator] = {
            text: iterator,
            max: this.corrosionLayers[iterator].spatialFilter.metricsMax,
          };
        });
      } catch (error) {
        console.log(error);
        this.handleError(error);
      }
    },

    locationMouseOver(location, item) {
      this.spatialHoverLocation = location;
      if (item) {
        this.spatialDeckResource = item.deckResource;
      }
    },

    locationClicked(location, event) {
      // only camera positions polygons clickable
      if (this.tabsModel !== this.TabsEnum.CAMERA) return;

      const imageId = this.rawTableData.find((x) => x.location === location)?.id;
      if (imageId) {
        if (event.ctrlKey || event.metaKey) {
          window.open(`/spherical?id=${this.$route.query.id}&image=${imageId}`);
        } else {
          this.$router.push({ path: `/spherical?id=${this.$route.query.id}&image=${imageId}` });
        }
      } else {
        this.handleError(`Location was not found: ${location}`);
      }
    },

    async fetchLocationsByRegion(region) {
      if (this.selectedDeck === 'All') {
        return this.getAllLocations(region);
      }
      return this.getFilteredLocations(region, this.selectedDeck);
    },
    async getAllLocations(region) {
      const locations = await Promise.all(
        this.data.map(({ _id: id }) => MetricController.getMetricChildren2(id, region, 2, []))
      );

      return this.data.map((deck, index) => ({
        ...deck,
        locations: locations[index].data,
      }));
    },
    async getFilteredLocations(region, deckName) {
      const selectedDeckData = this.data.find(({ name }) => name === deckName);
      const location = await MetricController.getMetricChildren2(selectedDeckData._id, region, 2, []);

      return [
        {
          ...selectedDeckData,
          locations: location.data,
        },
      ];
    },

    updateDeckMap() {
      const deck =
        this.selectedDeck === 'All' ? this.data[0] : this.data.find(({ name }) => name === this.selectedDeck);
      if (!deck) return;
      if (deck.data.resource) {
        this.spatialDeckResource = deck.data.resource;
      }
    },

    async updateSpatialHeatmap(activeFilter, filter, includesCorrosion) {
      this.loading = true;
      this.updateDeckMap();

      // change the deck heatmap svg resource
      if (activeFilter && !isEqual(activeFilter, this.heatmapFilter)) {
        this.heatmapFilter = activeFilter;
        const { svgBody: result, legend, paintRegionElements } = await DeckController.getDeckHeatMapV3(
          this.$route.query.id,
          this.activeDeckDocument?._id,
          this.heatmapFilter.heightRange,
          this.heatmapFilter.type,
          this.heatmapFilter.modes,
          filter,
          includesCorrosion
        );
        if (typeof result === 'string' && result.startsWith('<svg') && result.endsWith('</svg>')) {
          this.heatmapFilter.source = result;
        }
        // TODO: display legend & paintRegionElements?
        console.log(legend, paintRegionElements);
      }

      this.loading = false;
    },

    handleClearSpatialHeatmap() {
      this.heatmapFilter = {
        ...this.heatmapFilter,
        source: '',
      };
      this.updateDeckMap();
    },

    // process/load asset table items
    async updateSearchImage() {
      this.updateDeckMap();
      const deckDetails = (deck) =>
        deck.locations?.map(({ name, data, _id: id }) => {
          const corrosionLayers = Object.keys(this.corrosionLayers).reduce((layers, layer) => {
            // eslint-disable-next-line no-param-reassign
            layers[layer] = data.metrics.coverage?.[layer]?.mean.toFixed(4) || (0).toFixed(2);
            return layers;
          }, {});

          return {
            location: name.split('/').pop(),
            deck: deck.name,
            deckResource: deck.data.resource,
            imagePlanInfo: data.plan,
            id,
            metrics: data.metrics,
            ...corrosionLayers,
          };
        });

      const locations = (await this.fetchLocationsByRegion('image')) || [];
      const result = locations.map(deckDetails);

      [this.rawTableData] = result;
      this.cameraTable = result.flat();
    },

    async updateSearchRegions() {
      this.updateDeckMap();
      const deckDetails = (deck) =>
        deck.locations?.map(({ name, data }) => {
          const corrosionLayers = Object.keys(this.corrosionLayers).reduce((layers, layer) => {
            // eslint-disable-next-line no-param-reassign
            layers[layer] = data.metrics.coverage?.[layer]?.mean.toFixed(4) || (0).toFixed(2);
            return layers;
          }, {});

          return {
            location: name.split('/').pop(),
            deck: deck.name,
            deckResource: deck.data.resource,
            imagePlanInfo: data.plan,
            metrics: data.metrics,
            polygons: data.polygons,
            ...corrosionLayers,
          };
        });

      const locations = (await this.fetchLocationsByRegion('paint region')) || [];
      const result = locations.map(deckDetails);

      [this.rawTableData] = result;
      this.regionTable = result.flat();
    },

    // compute cached table metrics
    computeTableMetrics(table) {
      // compute max values for table
      Object.keys(this.spatialFilter.metrics).forEach((metric) => {
        // pull out metric values from tableRes
        const metricValues = table
          .map((r) => r[metric])
          .filter((r) => r && !Number.isNaN(Number(r)))
          .map((r) => Number(r));

        // set threshold slider max as metric max
        this.spatialFilter.metrics[metric].max = Math.max(...metricValues);
      });

      // check threshold disabled state
      const globalMax = Math.max(...Object.values(this.spatialFilter.metrics).map(({ max }) => max));
      this.spatialFilter.thresholdMax = globalMax;
      this.spatialFilter.thresholdDisabled = !globalMax;
    },
    async updateTable() {
      this.loading = true;
      // reset table state
      // empty table res while table loading
      this.rawTableData = [];
      // reset deck map active polygon state
      this.spatialHoverLocation = '';

      try {
        switch (this.tabsModel) {
          case TabsEnum.CAMERA: {
            await this.updateSearchImage();
            this.computeTableMetrics(this.cameraTable);
            break;
          }
          case TabsEnum.REGION: {
            await this.updateSearchRegions();
            this.computeTableMetrics(this.regionTable);
            break;
          }
          case TabsEnum.HEATMAPS: {
            await this.updateSpatialHeatmap();
            break;
          }
          default:
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    toggleOverlaySize() {
      if (this.overlaySize === OverlaySizeEnum.REGULAR) {
        this.overlaySize = OverlaySizeEnum.LARGE;
      } else if (this.overlaySize === OverlaySizeEnum.LARGE) {
        this.overlaySize = OverlaySizeEnum.REGULAR;
      }
    },
    scrollToDeckMap(refName) {
      const offSetTop = this.$refs[refName].$el.offsetParent.offsetParent.offsetTop;
      setTimeout(() => {
        window.scrollTo({ left: 0, top: offSetTop, behavior: 'smooth' });
      }, 200);
    },
    handleTabChanged(tab) {
      switch (tab) {
        case TabsEnum.CAMERA:
          this.activeTableHeaders = this.tableHeaders.cameraPositions;
          break;
        case TabsEnum.REGION:
          this.activeTableHeaders = this.tableHeaders.paintRegions;
          break;
        case TabsEnum.HEATMAPS:
          this.activeTableHeaders = this.tableHeaders.heatMaps;
          break;
        default:
          throw new Error(`Unexpected tab '${tab}' in DeckPlan`);
      }

      this.updateTable();
    },
    handleSelectedDeckChanged() {
      this.updateTable();
    },
    handleError(error) {
      this.displaySnackbar(error, notificationType.error);
    },
    displaySnackbar(message, type) {
      this.$emit('onSnackbarDisplay', message, type);
    },
    handleSnackbarClose() {
      this.$emit('onSnackbarClose');
    },
    handleTableCellClick({ row, event }) {
      if (event.ctrlKey || event.metaKey) {
        window.open(`/spherical?id=${this.$route.query.id}&image=${row.id}`);
      } else {
        this.$router.push({ path: `/spherical?id=${this.$route.query.id}&image=${row.id}` });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.flexDisplay {
  display: flex;
  align-items: center;
}

.displaySmall {
  max-width: 1080px;
}

.padding12 {
  padding: 12px;
}

@media screen and (max-width: 1903px) {
  .displayNon {
    display: none;
  }
}
</style>
