import { axiosApiInstance } from '@/utils/axios';
import config from '../config';

const ClientController = {
  // >= Admin role
  async getConnections() {
    const response = await axiosApiInstance.get(`${config.apiPath}/v1/clients/connections`);
    return response.data.data;
  },
  async getUserInfo() {
    const response = await axiosApiInstance.get(`${config.apiPath}/v1/clients/info`);
    return response.data.data;
  },

  // >= Admin role
  async getUserGroups(userId) {
    const response = await axiosApiInstance.get(`${config.apiPath}/v1/clients/users/${userId}/groups`);
    return response.data.data;
  },

  // >= Admin role
  async getUsers() {
    const response = await axiosApiInstance.get(`${config.apiPath}/v1/clients/users`);
    return response.data.data;
  },

  // >= Admin role
  async getGroups() {
    const response = await axiosApiInstance.get(`${config.apiPath}/v1/clients/groups`);
    return response.data.data;
  },

  async getRoles() {
    const response = await axiosApiInstance.get(`${config.apiPath}/v1/clients/users/roles`);
    return response.data.data;
  },

  async sendUserInvites(clientId, connection, userRoleIds = [], groupRoleIds = [], emails = []) {
    const response = await axiosApiInstance.post(`${config.apiPath}/v1/clients/users/invite`, {
      clientId,
      connection,
      userRoleIds,
      groupRoleIds,
      emails,
    });
    return response.data;
  },

  async attachGroupUser(userId, groupId) {
    const response = await axiosApiInstance.patch(`${config.apiPath}/v1/clients/users/${userId}/groups`, { groupId });
    return response.status;
  },

  async detachGroupUser(userId, groupId) {
    const data = { groupId };
    const response = await axiosApiInstance.delete(`${config.apiPath}/v1/clients/users/${userId}/groups`, { data });
    return response.status;
  },

  async attachGroupPlatforms(platformIds, groupId) {
    const response = await axiosApiInstance.patch(`${config.apiPath}/v1/clients/platforms/groups/${groupId}`, {
      platformIds,
    });
    return response.status;
  },

  async detachGroupPlatform(platformId, groupId) {
    const data = { groupId };
    const response = await axiosApiInstance.delete(`${config.apiPath}/v1/clients/platforms/${platformId}/groups`, {
      data,
    });
    return response.status;
  },
};

export default ClientController;
