var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { centered: "", "icons-and-text": "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tabs-slider"),
              _vm.showTab[_vm.tabsEnum.INFO]
                ? _c(
                    "v-tab",
                    { attrs: { href: "#tab-info" } },
                    [
                      _vm._v(" Info "),
                      _c("v-icon", [_vm._v("mdi-information")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showTab[_vm.tabsEnum.GROUPS]
                ? _c(
                    "v-tab",
                    { attrs: { href: "#tab-groups" } },
                    [
                      _vm._v(" Groups "),
                      _c("v-icon", [_vm._v("mdi-account-multiple")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showTab[_vm.tabsEnum.INVITATIONS]
                ? _c(
                    "v-tab",
                    { attrs: { href: "#tab-invites" } },
                    [
                      _vm._v(" Invitations "),
                      _c("v-icon", [_vm._v("mdi-email")])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm.showTab[_vm.tabsEnum.INFO]
                ? _c(
                    "v-tab-item",
                    { key: _vm.tabsEnum.INFO, attrs: { value: "tab-info" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", loading: _vm.loading } },
                        [
                          _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Application ID")
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(_vm.applicationId))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("User ID")
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(_vm.user._id))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("User Roles")
                                      ]),
                                      _c(
                                        "v-container",
                                        { staticClass: "py-0" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                align: "center",
                                                justify: "start"
                                              }
                                            },
                                            _vm._l(_vm.user.roles, function(
                                              userRole
                                            ) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: userRole,
                                                  staticClass: "shrink"
                                                },
                                                [
                                                  _c("v-chip", [
                                                    _vm._v(_vm._s(userRole))
                                                  ])
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("User Groups")
                                      ]),
                                      _c(
                                        "v-container",
                                        { staticClass: "py-0" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                align: "center",
                                                justify: "start"
                                              }
                                            },
                                            _vm._l(_vm.user.groups, function(
                                              userGroup
                                            ) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: userGroup,
                                                  staticClass: "shrink"
                                                },
                                                [
                                                  _c("v-chip", [
                                                    _vm._v(_vm._s(userGroup))
                                                  ])
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showTab[_vm.tabsEnum.GROUPS]
                ? _c(
                    "v-tab-item",
                    {
                      key: _vm.tabsEnum.GROUPS,
                      attrs: { value: "tab-groups" }
                    },
                    [
                      _vm.groupManagementUserCapability
                        ? _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-card-title", [
                                _vm._v("Assign Groups to Users")
                              ]),
                              _c("GroupSelector", {
                                attrs: {
                                  items: _vm.users,
                                  groups: _vm.groups,
                                  "search-fields": ["email", "name"],
                                  "id-field": "user_id",
                                  "text-field": "name",
                                  "subtext-field": "email",
                                  "image-field": "picture",
                                  "selection-name": "Users"
                                },
                                on: {
                                  attach: _vm.handleAttachUsers,
                                  detach: _vm.handleDetachUsers
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _c("v-spacer"),
                      _vm.groupManagementInspectionCapability
                        ? _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-card-title", [
                                _vm._v("Assign Groups to Inspections")
                              ]),
                              _c("GroupSelector", {
                                attrs: {
                                  items: _vm.inspections,
                                  groups: _vm.groups,
                                  "search-fields": ["id", "name"],
                                  "id-field": "id",
                                  "text-field": "name",
                                  "subtext-field": "id",
                                  "image-field": "image",
                                  "selection-name": "Inspections"
                                },
                                on: {
                                  attach: _vm.handleAttachInspections,
                                  detach: _vm.handleDetachInspections
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showTab[_vm.tabsEnum.INVITATIONS]
                ? _c(
                    "v-tab-item",
                    {
                      key: _vm.tabsEnum.INVITATIONS,
                      attrs: { value: "tab-invites" }
                    },
                    [
                      _c("InviteListBuilder", {
                        attrs: {
                          roles: _vm.roles,
                          groups: _vm.groups,
                          connections: _vm.connections
                        },
                        on: { send: _vm.handleInviteSend }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("Snackbar", {
        attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }