import { get } from 'lodash';

export const nonAggregateTableCustomFields = {
  inspection_uuid: 'inspection_uuid',
  equipment_class: 'data.meta["AutoCad:Class"]',
  equipment_id: '_id',
  images: 'data.referring_images',
  coverageLayers: 'data.metrics.coverage', // Include coverage for custom components
  metricSurfaceArea: 'data.metrics.surface_area.total', // Needed by CoatingBreakdown & DegreeOfRusting
};

const sum = (values) => values.reduce((total, value) => total + value, 0);

const aggregateNestedData = (fields, [path, ...remainingPaths]) => {
  const nestedFields = Object.entries(fields)
    .filter(([key]) => key.match(path))
    .map(([, value]) => value);

  if (remainingPaths.length === 0) {
    return sum(nestedFields);
  }
  return nestedFields.map((nestedField) => aggregateNestedData(nestedField, remainingPaths));
};

const displayTotals = (fields, label) => {
  const total = typeof fields === 'number' ? fields : fields?.length;
  switch (total) {
    case 0:
      return '';
    case 1:
      return `1 ${label}`;
    default:
      return `${total} ${label}s`;
  }
};

const aggregateList = (fields, field) => fields.map(({ [field]: text }) => text).join();

const applyAggregate = (fields, aggregate, defaultValue) => {
  switch (aggregate.type) {
    case 'count':
      if (aggregate.path) {
        return sum(aggregateNestedData(fields, aggregate.path));
      }
      return displayTotals(fields, defaultValue);
    case 'list':
      return aggregateList(fields, aggregate.field);
    default:
      throw new Error(`Unexpected aggregate type: ${aggregate.type}`);
  }
};

export const applyBinning = (value, bins) =>
  get(
    bins.find(({ min, inclusive }) => value > min || (inclusive && value === min)),
    'display',
    'Bin not found'
  );

export const extractNestedFields = (corrosionLayers, headers, data, customFields = {}) =>
  data.map((fields) => {
    /* eslint-disable no-param-reassign */
    const extractedData = headers.reduce(
      ({ dataFields, coveragePercentageBins }, { value, path, defaultValue, aggregate, bins }) => {
        switch (value) {
          case 'coveragePercentage':
            coveragePercentageBins = bins;
            break;
          default: {
            dataFields[value] = get(fields, path || value, defaultValue);

            if (bins) {
              dataFields[value] = applyBinning(dataFields[value], bins);
            } else if (aggregate) {
              dataFields[value] = applyAggregate(dataFields[value], aggregate, defaultValue);
            }
          }
        }

        return { dataFields, coveragePercentageBins };
      },
      { dataFields: {}, coveragePercentageBins: 0 }
    );

    // Add corrosionLayers e.g. AC-L, AC-M and AC-H and average keys and calculate total areaKey coverage
    const { coverage, surface_area: surfaceArea } = fields.data.metrics;
    const combined = corrosionLayers.reduce(
      (layers, [key, { areaKey }]) => {
        layers.fields[key] = surfaceArea?.total && coverage?.[key]?.area ? coverage[key].area / surfaceArea.total : 0;
        if (areaKey) {
          const coverageArea = coverage?.[key]?.area ?? 0;
          layers.totalCoverage += coverageArea;
          fields[areaKey] = coverageArea;
        }
        return layers;
      },
      { fields: extractedData.dataFields, totalCoverage: 0 }
    );
    /* eslint-enable no-param-reassign */

    let coveragePercentage = surfaceArea.total > 0 ? (combined.totalCoverage / surfaceArea.total) * 100 : 0;
    if (extractedData.coveragePercentageBins) {
      coveragePercentage = applyBinning(coveragePercentage, extractedData.coveragePercentageBins);
    }

    // TODO: Check if inspectionCorrosionCategory could be the source of truth
    const { remediated, corrosion, inspectionCorrosionCategory } = extractedData.dataFields;
    if (remediated && corrosion) {
      extractedData.dataFields.corrosion = `${corrosion} (${inspectionCorrosionCategory})`;
    }

    // Add any extra required custom fields
    return Object.entries(customFields).reduce(
      (custom, [key, value]) => ({
        ...custom,
        [key]: get(fields, value),
      }),
      { ...combined.fields, coveragePercentage }
    );
  });
