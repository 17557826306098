<template>
  <div v-if="props.labelTaggingMode === LabelTaggingMode.NONE">
    <div class="subtitle-2">Equipment Name</div>
    <div class="body-2" style="white-space: break-spaces">{{ props.displayName }}</div>
  </div>
  <LabelTagging v-else :label-tagging-mode="props.labelTaggingMode" />
</template>

<script>
import { LabelTaggingMode } from '@/utils';
import { LabelTagging } from '@/components/labelTagging';

export default {
  name: 'EquipmentTag',
  components: {
    LabelTagging,
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      LabelTaggingMode,
    };
  },
};
</script>
