var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("WorkPacksStats"),
      _vm.loading || _vm.generatingDocx ? _c("LoadingSpinner") : _vm._e(),
      _c("WorkPacks", {
        attrs: {
          title: "Work Pack Manager",
          "logged-in-user-id": _vm.user._id,
          headers: _vm.headers,
          "filter-keys": _vm.filterKeys,
          data: _vm.data,
          "toolbar-buttons": _vm.toolbarButtons(
            ["batchDelete"],
            _vm.selectedRows.length === 0
          )
        },
        on: {
          onToolbarButtonClicked: _vm.handleToolbarButtonClicked,
          onSelectedRowsChanged: _vm.handleSelectedRowsChanged,
          onActionRequested: _vm.handleActionRequested
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px", "retain-focus": false },
          model: {
            value: _vm.showCreation,
            callback: function($$v) {
              _vm.showCreation = $$v
            },
            expression: "showCreation"
          }
        },
        [
          _c("WorkPackCreator", {
            on: {
              onCreateWorkPackAttempt: _vm.handleCreateWorkPackAttempt,
              onCreateWorkPackSuccess: _vm.handleCreateWorkPackSuccess,
              onCreateWorkPackFailure: _vm.handleCreateWorkPackFailure
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }