<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="blue" dark flat>
            <v-toolbar-title>Fabric Web Portal Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="message" data-test-id="message" class="mx-auto" :type="messageType" outlined>
              {{ message }}
            </v-alert>
            <div v-if="showAuthenticationError">
              Authentication Error
              <br />
              Please contact our support team
            </div>
            <div v-if="error">
              <v-btn @click="retryHandler">Try Again</v-btn>
            </div>
            <div v-else-if="authLoading">
              <LoadingSpinner :inline="true" />
            </div>
            <div v-else>
              <v-btn v-if="!isAuthenticated" id="auth0loginBtn" class="button is-dark" @click="auth0Login">
                Sign in
              </v-btn>
              <v-btn v-else class="button is-dark" @click="auth0Logout">Log out</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LoginStatus } from '@/utils';
import { LoadingSpinner } from '@/react';

export default {
  name: 'LoginView',
  components: {
    LoadingSpinner,
  },
  props: {
    auth0ClientExists: {
      type: Boolean,
      default: false,
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: undefined,
    },
    status: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    showAuthenticationError() {
      return this.status !== LoginStatus.LOADING && !this.auth0ClientExists;
    },
    authLoading() {
      return this.$auth.loading;
    },
    message() {
      if (this.error) {
        return this.error;
      }

      switch (this.status) {
        case LoginStatus.LOGGING_IN:
          return 'Logging in...';
        case LoginStatus.LOGGED_IN:
          return 'Success!, logged in';
        case LoginStatus.LOGGED_OUT:
          return 'Logged out';
        default:
          return '';
      }
    },
    messageType() {
      if (this.error) {
        return 'warning';
      }

      switch (this.status) {
        case LoginStatus.ERROR:
          return 'warning';
        case LoginStatus.LOADING:
          return 'info';
        default:
          return 'success';
      }
    },
  },
  methods: {
    auth0Login() {
      this.$emit('onLogin');
    },
    auth0Logout() {
      this.$emit('onLogout');
    },
    pageReload() {
      window.location.reload();
    },
    retryHandler() {
      if (this.$auth.error) {
        this.pageReload();
      } else {
        this.auth0Logout();
      }
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  width: 80%;
  margin: 0px auto;
  text-align: center;
}
.loginbox {
  width: 25%;
  height: 40vh;
  margin: 0px auto;
}
.form-group {
  margin-bottom: 5px;

  input {
    width: 95%;
    padding: 14px 5px;
  }
  button {
    width: 100%;
    background-color: #5db2dd;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  button:hover {
    background-color: #75c3ea;
  }
}
.widget {
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  border-radius: 5px;
}
</style>
