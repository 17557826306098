import { WorkPackLevel } from '@/utils';
import WorkPackLevelSelector from './WorkPackLevelSelector.vue';

export default [
  {
    path: 'workPacks',
    name: WorkPackLevel.WORKPACKS,
    component: WorkPackLevelSelector,
    props: {
      level: WorkPackLevel.WORKPACKS,
    },
    meta: {
      breadcrumb() {
        return {
          getters: ['config/projectNameAndDate'],
          parents: ['home', 'Inspection'],
        };
      },
    },
  },
  {
    path: 'workPacks/:wpId',
    name: WorkPackLevel.ITEMS,
    component: WorkPackLevelSelector,
    props: {
      level: WorkPackLevel.ITEMS,
    },
    meta: {
      breadcrumb() {
        return {
          text: 'Work Pack - {0}',
          getters: ['workPacks/breadcrumbWorkPackName'],
          parents: ['home', 'Inspection', WorkPackLevel.WORKPACKS],
        };
      },
    },
  },
  {
    path: 'workPacks/:wpId/:wpItemId',
    name: WorkPackLevel.PARTS,
    component: WorkPackLevelSelector,
    props: {
      level: WorkPackLevel.PARTS,
    },
    meta: {
      breadcrumb() {
        return {
          text: 'item - {0}',
          getters: ['workPacks/breadcrumbItemName'],
          parents: ['home', 'Inspection', WorkPackLevel.WORKPACKS, WorkPackLevel.ITEMS],
        };
      },
    },
  },
  {
    path: 'workPacks/:wpId/:wpItemId/:wpPartId',
    name: WorkPackLevel.VIEWS,
    component: WorkPackLevelSelector,
    props: {
      level: WorkPackLevel.VIEWS,
    },
    meta: {
      breadcrumb() {
        return {
          text: 'part - {0}',
          getters: ['workPacks/breadcrumbPartName'],
          parents: ['home', 'Inspection', WorkPackLevel.WORKPACKS, WorkPackLevel.ITEMS, WorkPackLevel.PARTS],
        };
      },
    },
  },
];
