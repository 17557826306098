export enum MeasurementUnit {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export enum LoginStatus {
  ERROR = 'error',
  LOADING = 'loading',
  LOADED = 'loaded',
  LOGGING_IN = 'loggingIn',
  LOGGED_IN = 'loggedIn',
  LOGGED_OUT = 'loggedOut',
}

export enum WorkPackLevel {
  WORKPACKS = 'workPacks',
  ITEMS = 'workPackItems',
  PARTS = 'workPackParts',
  VIEWS = 'workPackViews',
}

export enum WorkPackReload {
  OPTIMISTIC = 'optimistic',
  SERVER = 'server',
}

export enum WorkPackItemAction {
  DELETE = 'delete',
  EXPORT = 'export',
  PRIVATE = 'isPrivate',
}

export enum ClientExtraEquipmentText {
  NONE = 'none',
  COATING_BREAKDOWN = 'coating breakdown',
  DEGREE_OF_RUSTING = 'degree of rusting',
}

export enum Orientation {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  BACK = 'back',
  CENTER = 'center',
}

export enum PanoMode {
  DEFAULT = 'default',
  LINE = 'line',
  BLISTER = 'blister',
}

export enum SpecialLabels {
  UNLABELLED = 'unlabelled',
  DELETED = 'delete',
}

export enum LabelTaggingMode {
  NONE = 'none',
  PART = 'part',
  ANNOTATION = 'annotation',
}

export enum HighlightStyle {
  TYPE = 'type',
  PART = 'part',
  ASSEMBLY = 'assembly',
  CORROSION = 'corrosion',
  SERVICE = 'service',
}

export enum ReviewPartStatus {
  REVIEW_PART = 'review Part',
  CANCEL_REVIEW = 'cancel review',
  ABANDON_ACTION = 'abandon action',
}

export enum LocalStorageKeys {
  CURRENT_MATERIAL = 'currentMaterial',
}

export enum MaterialTypes {
  PART = 'part',
  CORROSION = 'corrosion',
  RUSTING = 'rusting',
  COLOR = 'color',
}

export enum IconTooltipTypes {
  HISTORY = 'history',
  LINK_OFF = 'linkOff',
  VISIBILITY = 'visibility',
  VISIBILITY_OFF = 'visibilityOff',
}

export enum GroupSelectorPages {
  ITEMS = 'items',
  GROUPS = 'groups',
  CONFIRM = 'confirm',
}

export const UserRoles = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  WORK_PACK_MANAGER: 'workpack-manager',
  WORK_PACK_USER: 'workpack-user',
  ASSER_TAGGING_USER: 'asset-tagging-user',
  READ_ONLY_USER: 'read-only-user',
};
