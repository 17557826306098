
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormatUnit } from '@/components';

@Component({
  components: {
    FormatUnit,
  },
})
export default class CoveragePercentageRows extends Vue {
  @Prop({
    default: () => [],
  })
  rows!: Array<{ key: string; area: number; friendlyName: string }>;

  @Prop({
    default: null,
  })
  metricSurfaceArea!: number;

  formatAsPercentage(area: number) {
    return area !== undefined ? ` - (${((area / this.totalArea) * 100).toFixed(1)}%)` : `- (0.0%)`;
  }

  get totalArea() {
    return this.metricSurfaceArea || this.rows.reduce((sum, { area }) => sum + area, 0);
  }
}
