import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { VuePlugin } from 'vuera';
import LogRocket from 'logrocket';

import store from './store';
import { router } from './router';
import vuetify from './plugins/vuetify';
import config from './config';

import App from './App.vue';

import { Auth0Plugin } from './auth';
import { debugEnabled, Fabric } from './utils';

if (process.env.NODE_ENV !== 'production') {
  window.Fabric = Fabric;
}

Sentry.init({
  Vue,
  dsn: 'https://b13fe85c27bd4597ac6da93195d94c23@o575914.ingest.sentry.io/5728832',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  environment: config.environment,
});

if (debugEnabled) {
  LogRocket.init('dpprux/abyss-fabric');
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  onRedirectCallback: (appState: any) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.use(VuePlugin);

library.add(fas);
Vue.config.productionTip = false;
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

sync(store, router);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
