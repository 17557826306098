<template>
  <div>
    <DataTable
      :headers="inspectionConfig.aggregateEquipmentHeaders"
      :data="data"
      :unit="$store.state.unit"
      :items-per-page="20"
      :loading="loading"
      loading-text="Loading metrics, please wait..."
      primary-key="filterKey"
      selectable
      :generate-csv-trigger="generateCsvTrigger"
      @onTableCellClick="handleTableCellClick"
      @onCsvGenerated="handleCsvGenerated"
      @onSelectedRowsChanged="handleSelectedRowsChanged"
    >
      <ButtonToolbar slot="toolbar" :buttons="toolbarButtons" @onButtonClicked="handleToolbarButtonClicked" />
    </DataTable>
    <Snackbar :type="notification" :text="notificationMessage" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MetricController from '@/controllers/MetricController';
import { DataTable } from '@/components';
import ButtonToolbar from '@/components/ButtonToolbar.vue';
import { Snackbar, notificationType } from '@/components/widgets';
import { extractNestedFields } from '../utils';

const MAX_CHECKED_ROWS = 20;

export default {
  name: 'AggregateTableContainer',
  components: {
    DataTable,
    ButtonToolbar,
    Snackbar,
  },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
    generateCsvTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      data: undefined,
      selected: [],
      previousNumberOfSelectedRows: 0,
      notification: notificationType.none,
    };
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    toolbarButtons() {
      const disabled = this.selected.length === 0 || this.selected.length > this.checkedRowsLimit;
      const { hasLineIsometrics } = this.inspectionConfig.platformFeatures;
      return [
        { text: 'Aggregated Insights', value: 'selectedAssemblies', disabled },
        { text: 'Common Images', value: 'commonImages', disabled },
        ...(hasLineIsometrics ? [{ text: 'Isometric', value: 'isometric', disabled }] : []),
      ];
    },
    notificationMessage() {
      return `A maximum of ${this.checkedRowsLimit} items may be selected for mulitiple isometrics or images`;
    },
    checkedRowsLimit() {
      if (this.inspectionConfig?.aggregateEquipmentOptions?.maxSelectedRows) {
        return this.inspectionConfig.aggregateEquipmentOptions.maxSelectedRows;
      }
      return MAX_CHECKED_ROWS;
    },
  },
  watch: {
    filter(value, previous) {
      // prevent repeated calls from repeating work by checking for previous filter
      if (JSON.stringify(value) !== JSON.stringify(previous)) {
        this.loadData(value.filter(({ key }) => key !== 'undefined'));
      }
    },
  },
  methods: {
    async loadData(filter) {
      this.loading = true;

      const aggregationKey = '$data.meta.AutoCad:LineKey';
      const { data } = await MetricController.getMetricAggregate(
        this.$route.query.id,
        'asset',
        2,
        filter,
        aggregationKey
      );

      const { corrosionLayers, aggregateEquipmentHeaders } = this.inspectionConfig;
      const customFields = { equipmentId: '_id', filterKey: 'data.meta["AutoCad:LineKey"]' };
      this.data = extractNestedFields(Object.entries(corrosionLayers), aggregateEquipmentHeaders, data, customFields);

      this.loading = false;
    },
    handleTableCellClick(row) {
      switch (row.column) {
        case 'Line':
          this.$emit('onLineFieldClick', row);
          break;
        default:
          this.$emit('onDetailFieldClick', row);
          break;
      }
    },
    handleCsvGenerated(data) {
      this.$emit('onCsvGenerated', data);
    },
    handleSelectedRowsChanged(rows) {
      if (rows.length > this.checkedRowsLimit && this.previousNumberOfSelectedRows <= this.checkedRowsLimit) {
        this.notification = notificationType.warning;
      }
      this.previousNumberOfSelectedRows = rows.length;
      this.selected = rows;
    },
    handleToolbarButtonClicked(item, event) {
      switch (item) {
        case 'commonImages':
          this.$emit('onCommonImagesSelected', { rows: this.selected, event });
          break;
        case 'isometric':
          this.$emit('onMultiLineIsometricSelected', { lines: this.selected, event });
          break;
        case 'selectedAssemblies':
          this.$emit('onMultiAssembliesSelected', { rows: this.selected, event });
          break;
        default:
          throw new Error(`Unexpected toolbar button clicked: ${item}`);
      }
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
  },
};
</script>
