<template>
  <div class="pa-2">
    <div class="text-center">
      <v-menu open-on-hover offset-y bottom :nudge-bottom="10" :close-delay="200">
        <template #activator="{ on, attrs }">
          <font-awesome-icon class="clickable white--text" icon="question-circle" v-bind="attrs" v-on="on" />
        </template>

        <v-list>
          <!-- v-for="(item, index) in items" -->
          <v-list-item-group color="primary">
            <v-list-item>
              <v-list-item-title @click="handleUsageGuideClick">Usage guide</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="handleSupportEmailClick">
                <v-btn icon class="clipboard" :data-clipboard-text="supportEmail" @click="handleCopyEmailLink">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                Email Support: ({{ supportEmail }})
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { notificationType } from '@/components/widgets';

export default {
  name: 'HelpMenu',
  data() {
    return {
      supportEmail: 'fabric-support@abysssolutions.com.au',
    };
  },
  methods: {
    handleUsageGuideClick() {
      window.open('http://docs.abyssfabric.co/', '_blank');
    },
    handleSupportEmailClick() {
      window.open('mailto:fabric-support@abysssolutions.com.au');
    },
    handleCopyEmailLink(event) {
      // Stop event propagation to ensure list item handler isn't triggered
      event.stopPropagation();
      const message = `Copied ${this.supportEmail} to clipboard`;
      this.$emit('triggerNotification', message, notificationType.message);
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
