var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-select", {
            attrs: {
              items: Object.keys(_vm.spatialFilter.metrics).map(function(m) {
                return { text: _vm.spatialFilter.metrics[m].text, value: m }
              }),
              color: _vm.spatialFilter.color,
              label: "Metric"
            },
            model: {
              value: _vm.spatialFilter.activeMetric,
              callback: function($$v) {
                _vm.$set(_vm.spatialFilter, "activeMetric", $$v)
              },
              expression: "spatialFilter.activeMetric"
            }
          }),
          _c("v-select", {
            attrs: {
              items: ["threshold", "heatmap"],
              color: _vm.spatialFilter.color,
              label: "Aggregation Method"
            },
            model: {
              value: _vm.spatialFilter.aggMethod,
              callback: function($$v) {
                _vm.$set(_vm.spatialFilter, "aggMethod", $$v)
              },
              expression: "spatialFilter.aggMethod"
            }
          })
        ],
        1
      ),
      _vm.spatialFilter.aggMethod === "threshold" &&
      _vm.spatialFilter.metrics[_vm.spatialFilter.activeMetric]
        ? _c(
            "v-row",
            [
              _c("v-slider", {
                attrs: {
                  min: 0,
                  max:
                    _vm.spatialFilter.metrics[_vm.spatialFilter.activeMetric]
                      .max,
                  step:
                    _vm.spatialFilter.metrics[_vm.spatialFilter.activeMetric]
                      .max / _vm.thresholdStep,
                  color: _vm.spatialFilter.color,
                  label: "Threshold"
                },
                model: {
                  value: _vm.spatialFilter.threshold,
                  callback: function($$v) {
                    _vm.$set(_vm.spatialFilter, "threshold", $$v)
                  },
                  expression: "spatialFilter.threshold"
                }
              })
            ],
            1
          )
        : _vm.spatialFilter.aggMethod === "heatmap" &&
          _vm.spatialFilter.metrics[_vm.spatialFilter.activeMetric]
        ? _c(
            "v-row",
            [
              _c("v-slider", {
                attrs: {
                  min: 0,
                  max:
                    _vm.spatialFilter.metrics[_vm.spatialFilter.activeMetric]
                      .max,
                  step:
                    _vm.spatialFilter.metrics[_vm.spatialFilter.activeMetric]
                      .max / _vm.thresholdStep,
                  disabled: _vm.spatialFilter.thresholdDisabled,
                  color: _vm.spatialFilter.color,
                  label: "Threshold"
                },
                model: {
                  value: _vm.spatialFilter.thresholdMax,
                  callback: function($$v) {
                    _vm.$set(_vm.spatialFilter, "thresholdMax", $$v)
                  },
                  expression: "spatialFilter.thresholdMax"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab === 0
        ? _c(
            "v-row",
            [
              _c("v-slider", {
                attrs: {
                  min: _vm.markerMin,
                  max: _vm.markerMax,
                  step: _vm.markerMax / _vm.markerStep,
                  color: _vm.spatialFilter.color,
                  label: "Marker Scale"
                },
                model: {
                  value: _vm.spatialFilter.markerScale,
                  callback: function($$v) {
                    _vm.$set(_vm.spatialFilter, "markerScale", $$v)
                  },
                  expression: "spatialFilter.markerScale"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.spatialFilter.aggMethod === "threshold"
        ? _c("v-row", [
            _vm._v(" Coverage Percentage greater than "),
            _c("strong", [
              _vm._v(
                _vm._s((_vm.spatialFilter.threshold * 100).toFixed(4)) + "%"
              )
            ]),
            _vm._v(" is shown in red ")
          ])
        : _vm.spatialFilter.aggMethod === "heatmap"
        ? _c("v-row", [
            _vm._v(" Marker colour is a gradient from blue at 0% to red at "),
            _c("strong", [
              _vm._v(
                _vm._s((_vm.spatialFilter.thresholdMax * 100).toFixed(4)) + "%"
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }