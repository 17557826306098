import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  CameraType,
  useAbyssViewer,
  PointCloudMaterialProps,
  FloorLevelStyle,
  FloorLevelProps,
  PointPickResult,
} from '@abyss/3d-viewer';
import { LocalStorageKeys, MaterialTypes } from '@/utils/enums';
import { useLocalStorage } from '@/react/hooks';
import { withViewerProvider } from '@/react/Components/3dViewerProvider';
import styled from 'styled-components';
import { MenuBar } from './MenuBar';
import { MaterialSelector, MaterialSelectorProps } from './Overlay';

const StyledContainer = styled.div`
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  padding: 5px;
  float: right;
  font-size: 14px;
  color: #ccc;
  padding-left: 10px;
`;
const StyledHeader = styled.div`
  display: block;
  font-size: 14px;
  text-align: center;
  clear: both;
  padding-top: 10px;
  padding-bottom: 3px;
  color: #8aa;
`;

interface LineIsometricsProps {
  lines: {
    url: string;
    name?: string;
    contents: {
      'part-id'?: boolean;
      'corrosion-id'?: boolean;
      'rusting-id'?: boolean;
      color?: boolean;
    }[];
  }[];
  inspectionConfig: {
    isometricPointColors: {
      layer: string;
      colors: Iterable<[number, string]>;
    }[];
  };
  'canvas-height'?: string;
  'canvas-width'?: string;
  onPointSelect: (pickResult: PointPickResult) => void;
}

const getMaterials = (isometricPointColors: Array<{ layer: string; colors: Iterable<[number, string]> }>) => {
  const makeMap = (layer: string): Map<number, string> =>
    new Map(isometricPointColors.find((f) => f.layer === layer)?.colors || []);

  const partColorMap: Map<number, string> = new Map([
    // Hex colors with alpha chanel
    [0, '#809090FF'],
  ]);

  const pointCloudMaterials = new Map<string, PointCloudMaterialProps>([
    // not providing default color, random color will be used for part ids mising in the partColorMap
    ['part', { colorMap: partColorMap, attributeName: 'part', pointSize: 1 }],
    ['corrosion', { defaultColor: '#FFFF88FF', colorMap: makeMap('corrosion'), attributeName: 'corrosion' }],
    ['rusting', { defaultColor: '#FFFF88FF', colorMap: makeMap('rusting'), attributeName: 'rusting' }],
    ['color', { attributeName: 'color', isColor: true }],
  ]);

  return pointCloudMaterials;
};

const getDataFormat = (
  contents: {
    'part-id'?: boolean;
    'corrosion-id'?: boolean;
    'rusting-id'?: boolean;
    color?: boolean;
  }[]
) => {
  const fields = [{ name: 'position', offset: 0 }];
  let offset = 12;
  // eslint-disable-next-line no-restricted-syntax
  for (const field of contents) {
    if (field.color !== undefined) {
      fields.push({ name: 'color', offset });
      offset += 3;
    } else if (field['part-id'] !== undefined) {
      fields.push({ name: 'part', offset });
      offset += 4;
    } else if (field['corrosion-id'] !== undefined) {
      fields.push({ name: 'corrosion', offset });
      offset += 4;
    } else if (field['rusting-id'] !== undefined) {
      fields.push({ name: 'rusting', offset });
      offset += 4;
    }
  }
  return { fields, recordSize: offset };
};

export const LineIsometricsComponent: FC<LineIsometricsProps> = ({ lines, inspectionConfig, onPointSelect }) => {
  const { getAbyssViewer, resetCameraPositionToDefault, registerOnPointCloudClickCallback } = useAbyssViewer();

  const AbyssViewer = useMemo(() => getAbyssViewer(), [getAbyssViewer]);

  const onSceneLoaded = useCallback(() => {
    resetCameraPositionToDefault();
  }, [resetCameraPositionToDefault]);

  useEffect(() => {
    const removeOnPointCloudClick = registerOnPointCloudClickCallback(onPointSelect);
    return () => {
      removeOnPointCloudClick();
    };
  }, [registerOnPointCloudClickCallback]);

  const usedMaterials = useMemo(() => {
    // find materials used in lines
    const result = new Set<string>();

    // eslint-disable-next-line no-restricted-syntax
    for (const { contents } of lines) {
      const { fields } = getDataFormat(contents);
      // eslint-disable-next-line no-restricted-syntax
      for (const { name } of fields) {
        if (name !== 'position') {
          result.add(name);
        }
      }
    }
    return result;
  }, [lines]);

  const [currentMaterial, setCurrentMaterial] = useLocalStorage(
    LocalStorageKeys.CURRENT_MATERIAL,
    MaterialTypes.CORROSION
  );

  if (!usedMaterials.has(currentMaterial)) {
    setCurrentMaterial(usedMaterials.values().next().value);
  }

  const pointCloudMaterials = useMemo(() => getMaterials(inspectionConfig.isometricPointColors), []);

  const simplePointClouds = useMemo(
    () =>
      lines?.map(({ url, name, contents }) => ({
        name: `Line ${name}`,
        url,
        dataFormat: getDataFormat(contents),
        material: currentMaterial,
        showInSphericalView: true,
      })),
    [lines, currentMaterial, usedMaterials]
  );

  const floorLevelData = [
    { name: 'WD', z: 39.936, color: 0x442222 },
    { name: 'PD', z: 30.237, color: 0x224422 },
    { name: 'CD', z: 22.258, color: 0x222244 },
    { name: 'TS', z: 15.27, color: 0x444422 },
  ];

  const floorLevelStyle: FloorLevelStyle = {
    color: 0x222222,
    lineWidth: 0.05,
    opacity: 1,
  };

  const floorLevels = floorLevelData.map(({ name, z, color }) => {
    const levelProps: FloorLevelProps = {
      name,
      label: {
        color: '#888888',
        fontSize: 64,
      },
      labelOffset: [1, 1, 1],
      zLevel: z,
      style: { ...floorLevelStyle, color },
      gridCellSize: 5,
    };
    return levelProps;
  });

  const materialSelectorProps: MaterialSelectorProps = {
    items: [
      {
        value: MaterialTypes.PART,
        label: 'Part Id',
      },
      {
        value: MaterialTypes.CORROSION,
        label: 'Corrosion State',
      },
      {
        value: MaterialTypes.RUSTING,
        label: 'Coating Condition',
      },
      {
        value: MaterialTypes.COLOR,
        label: 'Color',
      },
    ].filter(({ value }) => usedMaterials.has(value)),
    selectedMaterialType: currentMaterial,
    selectMaterial: setCurrentMaterial,
  };

  return (
    <>
      <MenuBar />
      <StyledContainer>
        <StyledHeader>Materials</StyledHeader>
        <MaterialSelector {...materialSelectorProps} />
      </StyledContainer>
      <AbyssViewer
        canvasProps={{
          style: {
            background: '#263238',
            width: '100%',
            height: '100%',
          },
          gl: { antialias: true, preserveDrawingBuffer: true },
        }}
        cameraProps={{ cameraType: CameraType.Orthographic }}
        abyssViewerComponentProps={{
          onSceneLoaded,
          pointCloudMaterials,
          simplePointClouds,
          floorLevels,
          viewCube: true,
        }}
      />
    </>
  );
};

export const LineIsometrics = withViewerProvider(LineIsometricsComponent);
