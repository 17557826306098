var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-height", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-12" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "blue", dark: "", flat: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Fabric Web Portal Login")])
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _vm.message
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mx-auto",
                              attrs: {
                                "data-test-id": "message",
                                type: _vm.messageType,
                                outlined: ""
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.message) + " ")]
                          )
                        : _vm._e(),
                      _vm.showAuthenticationError
                        ? _c("div", [
                            _vm._v(" Authentication Error "),
                            _c("br"),
                            _vm._v(" Please contact our support team ")
                          ])
                        : _vm._e(),
                      _vm.error
                        ? _c(
                            "div",
                            [
                              _c("v-btn", { on: { click: _vm.retryHandler } }, [
                                _vm._v("Try Again")
                              ])
                            ],
                            1
                          )
                        : _vm.authLoading
                        ? _c(
                            "div",
                            [_c("LoadingSpinner", { attrs: { inline: true } })],
                            1
                          )
                        : _c(
                            "div",
                            [
                              !_vm.isAuthenticated
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "button is-dark",
                                      attrs: { id: "auth0loginBtn" },
                                      on: { click: _vm.auth0Login }
                                    },
                                    [_vm._v(" Sign in ")]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      staticClass: "button is-dark",
                                      on: { click: _vm.auth0Logout }
                                    },
                                    [_vm._v("Log out")]
                                  )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }