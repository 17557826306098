import { get } from 'lodash';
import { saveAs } from 'file-saver';
import { WorkPackController } from '@/controllers';
import { WorkPackLevel, WorkPackReload } from '@/utils';
import { disableOnGenerator, mapToObjectById, mapButtonsToObjectByLevel } from './utils';
import { nestedDataStateByParamIds, previousLevel } from './state';

const workPackLevelsWithTypes = (config) => {
  const levelDisplayNameEnum = ['WorkPack', 'Item', 'Part', 'Image'];
  // Link previous and next levels
  const linkedWorkPackLevels = Object.values(WorkPackLevel).reduce((levels, level, index, array) => {
    // eslint-disable-next-line no-param-reassign
    levels[level] = {
      previous: array[index - 1],
      next: array[index + 1],
      levelDisplayName: levelDisplayNameEnum[index],
      data: {},
    };
    return levels;
  }, {});

  return config.workPackHeaders.reduce(
    (levels, { type, friendlyName, headers, itemHeaders, partHeaders, viewHeaders, toolbarButtons }) => {
      const mappedButtons = mapButtonsToObjectByLevel(toolbarButtons);
      /* eslint-disable no-param-reassign */
      levels[WorkPackLevel.WORKPACKS].data[type] = {
        display: friendlyName,
        fields: headers,
        nameField: get(headers, [0, 'value']), // assume 1st field used to name the item e.g. for breadcrumbs
      };
      levels[WorkPackLevel.ITEMS].data[type] = {
        fields: itemHeaders,
        nameField: get(itemHeaders, [0, 'value']),
        toolbarButtons: mappedButtons.itemHeaders,
      };
      levels[WorkPackLevel.PARTS].data[type] = {
        fields: partHeaders,
        nameField: get(partHeaders, [0, 'value']),
        toolbarButtons: mappedButtons.partHeaders,
      };
      levels[WorkPackLevel.VIEWS].data[type] = {
        fields: viewHeaders,
        nameField: get(viewHeaders, [0, 'value']),
        toolbarButtons: mappedButtons.viewHeaders,
      };
      /* eslint-enable no-param-reassign */
      return levels;
    },
    linkedWorkPackLevels
  );
};

const loadWorkPacks = async ({ commit, location, inspectionId }) => {
  const data = await WorkPackController.getWorkPacks(inspectionId);
  if (!data.error) {
    commit('loadData', { location, data: mapToObjectById(data) });
  }
  return data.error;
};

export const extractPartsAndViews = (data) =>
  data.reduce(
    (extracted, { parts = [], ...extractedData }) => {
      extracted.data.push(extractedData);
      parts.forEach(({ views, ...extractParts }) => {
        // eslint-disable-next-line no-param-reassign
        extracted.parts[extractedData._id] = (extracted.parts[extractedData._id] || []).concat(extractParts);
        if (!extracted.views[extractedData._id]) {
          // eslint-disable-next-line no-param-reassign
          extracted.views[extractedData._id] = {
            [extractParts._id]: [],
          };
        } else if (!extracted.views[extractedData._id][extractParts._id]) {
          // eslint-disable-next-line no-param-reassign
          extracted.views[extractedData._id][extractParts._id] = [];
        }
        extracted.views[extractedData._id][extractParts._id].push(...views);
      });
      return extracted;
    },
    { data: [], parts: {}, views: {} }
  );

const loadItemsWithNestedPartsAndViews = async ({ commit, location, wpId, inspectionId }) => {
  const itemData = await WorkPackController.getWorkPackItems(wpId, inspectionId);
  if (!itemData.error) {
    const { data, parts, views } = extractPartsAndViews(itemData);
    commit('loadNestedDataWithConfig', { location, data: mapToObjectById(data), parts, views });
  }
  return itemData.error;
};

const reloadPartsWithoutViews = async ({ commit, location, wpId, wpItemId, inspectionId }) => {
  const data = await WorkPackController.getItemParts(wpId, wpItemId, inspectionId);
  if (!data.error) {
    const extractedParts = data.map(({ views: _views, ...parts }) => parts);
    if (extractedParts?.length > 0) {
      commit('loadDataWithConfig', { location, data: extractedParts });
    }
  }
};

const reloadViews = async ({ commit, location, wpId, wpItemId, wpPartId, inspectionId }) => {
  const data = await WorkPackController.getPartViews(wpId, wpItemId, wpPartId, inspectionId);
  if (!data.error) {
    if (data?.length > 0) {
      commit('loadDataWithConfig', { location, data });
    }
  }
};

const updateLevel = ({ level, data, wpId, wpItemId, wpPartId, inspectionId }) => {
  switch (level) {
    case WorkPackLevel.WORKPACKS: {
      return WorkPackController.updateWorkPack(wpId, data, inspectionId);
    }
    case WorkPackLevel.ITEMS: {
      return WorkPackController.updateWorkPackItem(wpId, wpItemId, data, inspectionId);
    }
    case WorkPackLevel.PARTS: {
      return WorkPackController.updateItemPart(wpId, wpItemId, wpPartId, data, inspectionId);
    }
    default: {
      return { error: `Invalid level to update: ${level}` };
    }
  }
};

const deleteAtLevel = ({ level, wpId, wpItemId, wpPartId, id, inspectionId }) => {
  switch (level) {
    case WorkPackLevel.WORKPACKS: {
      return WorkPackController.deleteWorkPack(id, inspectionId);
    }
    case WorkPackLevel.ITEMS: {
      return WorkPackController.deleteWorkPackItem(wpId, id, inspectionId);
    }
    case WorkPackLevel.PARTS: {
      return WorkPackController.deleteItemPart(wpId, wpItemId, id, inspectionId);
    }
    case WorkPackLevel.VIEWS: {
      return WorkPackController.deletePartView(wpId, wpItemId, wpPartId, id, inspectionId);
    }
    default: {
      return { error: `Unconfigured level: ${level}` };
    }
  }
};

const transferAtLevel = ({ level, wpId, destWpId, wpItemId, wpPartId, inspectionId, items }) => {
  switch (level) {
    case WorkPackLevel.ITEMS: {
      return WorkPackController.transferWorkPackItems(wpId, destWpId, items, inspectionId);
    }
    case WorkPackLevel.PARTS: {
      return WorkPackController.transferItemParts(wpId, destWpId, wpItemId, items, inspectionId);
    }
    case WorkPackLevel.VIEWS: {
      return WorkPackController.transferPartViews(wpId, destWpId, wpItemId, wpPartId, items, inspectionId);
    }
    default: {
      return { error: `Unconfigured level: ${level}` };
    }
  }
};

export const actions = {
  async initialise({ commit, state, rootState, rootGetters }) {
    // Apply workPackReload setting to remove optimisations
    const shouldReload = rootGetters.workPackReload === WorkPackReload.SERVER;

    // Setup required state based on the current url
    const {
      name,
      params: { wpId, wpItemId, wpPartId },
      query: { wpType, id },
    } = rootState.route;
    commit('setLevel', name);
    commit('setActiveInspectionId', id);
    commit('setActiveType', wpType);
    actions.loadFields({ commit, state, rootGetters });

    await actions.loadData(
      { commit, state },
      { level: name, shouldReload, wpId, wpItemId, wpPartId, inspectionId: id }
    );
  },
  reset({ commit }) {
    commit('reset');
  },
  loadFields({ commit, state, rootGetters }) {
    if (!state.levelFields) {
      commit('loadFields', workPackLevelsWithTypes(rootGetters['config/inspectionConfig']));
    }
  },
  async loadData({ commit, state }, { level, shouldReload, wpId, wpItemId, wpPartId, inspectionId }) {
    commit('setLoading', true);

    let error = false;
    let location = state;

    // Reset cached data if on current level and based on workPackReload setting
    if (!location.data || (shouldReload && level === WorkPackLevel.WORKPACKS)) {
      error = await loadWorkPacks({ commit, location, inspectionId });
    }

    location = !error && location.data[wpId];
    if (location && (!location.data || (shouldReload && level === WorkPackLevel.ITEMS))) {
      error = await loadItemsWithNestedPartsAndViews({
        commit,
        location,
        wpId,
        inspectionId,
      });
    }

    if (error) {
      commit('setError', 'Unable to load data!');
    } else if (shouldReload) {
      // Errors not handled in reloads as we already have the data
      location = location && location.data[wpItemId];
      if (location && level === WorkPackLevel.PARTS) {
        await reloadPartsWithoutViews({ commit, location, wpId, wpItemId, inspectionId });
      }

      location = location && location.data[wpPartId];
      if (location && level === WorkPackLevel.VIEWS) {
        await reloadViews({ commit, location, wpId, wpItemId, wpPartId, inspectionId });
      }
    }

    commit('setLoading', false);
  },
  async loadEntireWorkPack({ commit, state }, wpId) {
    const error = await loadItemsWithNestedPartsAndViews({
      commit,
      location: state.data[wpId],
      wpId,
      inspectionId: state.activeInspectionId,
    });
    if (error) {
      commit('setError', error);
    }
  },
  async update({ commit, state, rootState: { route } }, fields) {
    const { error } = await updateLevel({
      level: previousLevel(state),
      data: fields,
      ...route.params,
      inspectionId: route.query.id,
    });
    if (error) {
      commit('setError', error);
    } else {
      // Update fields not shown on current screen - no need to optimistically load fields
      commit('update', { fields, location: nestedDataStateByParamIds(route.params) });
    }
  },
  async delete({ commit, state, rootState }, itemId) {
    const { wpId, wpItemId, wpPartId } = rootState.route.params;
    const { id: inspectionId } = rootState.route.query;
    const location = [wpId, wpItemId, wpPartId]
      .filter(Boolean)
      .reduce((currentLocation, id) => currentLocation[id].data, state.data);

    commit('delete', { location, id: itemId });
    const { error } = await deleteAtLevel({
      level: state.level,
      wpId,
      wpItemId,
      wpPartId,
      id: itemId,
      inspectionId,
    });
    if (error) {
      commit('setError', error);
    } else {
      commit('setNotification', 'Item deleted!');
    }
  },
  async transfer({ commit, state, rootState }, { destWpId, items }) {
    const { wpId, wpItemId, wpPartId } = rootState.route.params;
    const { id: inspectionId } = rootState.route.query;

    const { error } = await transferAtLevel({
      level: state.level,
      wpId,
      destWpId,
      wpItemId,
      wpPartId,
      items,
      inspectionId,
    });

    if (error) {
      commit('setError', error);
    } else {
      commit('setNotification', 'Items transferred!');
    }
  },
  async closeout({ commit, state, rootState, getters }, parts) {
    const { wpId, wpItemId } = rootState.route.params;
    const location = nestedDataStateByParamIds({ wpId, wpItemId });
    const { data } = location(state);

    const disableButtons = Array.from(disableOnGenerator(state))
      .filter(({ disableOn: { field, includes } }) => field === 'state' && includes.includes('remediated'))
      .map(({ value }) => value);

    const [remediatedData, originalData] = parts.reduce(
      ([remediated, original], part) => {
        // eslint-disable-next-line no-param-reassign
        remediated[part] = {
          ...data[part].fields.data,
          state: 'remediated',
          disableButtons,
        };
        // eslint-disable-next-line no-param-reassign
        original[part] = data[part].fields.data;
        return [remediated, original];
      },
      [{}, {}]
    );

    commit('updateFieldsData', { location, fieldsData: remediatedData }); // Optimistic closeout

    const { error } = await WorkPackController.closeoutItemParts(wpId, wpItemId, parts, state.activeInspectionId);
    if (error) {
      commit('setError', error);
      commit('updateFieldsData', { location, fieldsData: originalData }); // revert closeout
    } else {
      commit(
        'setNotification',
        `${getters.currentLevelDisplayName}${parts.length > 1 ? 's' : ''} closed out succesfully`
      );
    }
  },
  async createWorkPack({ commit, rootState }, item) {
    commit('addOptimisticWorkPack', item);
    const { id: inspectionId } = rootState.route.query;
    const { error, ...fields } = await WorkPackController.createWorkPack(item, inspectionId);
    if (error) {
      commit('setError', 'Operation failed!');
      commit('setOptimisticWorkPackError');
    } else {
      commit('setNotification', 'Item created!');
      commit('updateOptimisticWorkPack', fields);
    }
  },
  async exportWorkPackAsCsv({ commit }, { id, inspectionId, baseUrl, fileName = 'export' }) {
    try {
      const { data: blob } = (await WorkPackController.exportWorkPackItems(id, [], inspectionId, baseUrl)) || {};
      saveAs(blob, `${fileName}.csv`);
    } catch (error) {
      commit('setError', 'Operation failed!');
    }
  },
  async setWorkPackAccess({ commit }, { inspectionId, wpId, makePrivate }) {
    commit('setWorkPackAccess', { wpId, makePrivate });
    const { error } = await WorkPackController.updateWorkPack(wpId, { isPrivate: makePrivate }, inspectionId);
    if (error) {
      commit('setError', error);
      commit('setWorkPackAccess', { wpId, makePrivate: !makePrivate }); // Revert optimistic update
    }
  },
  setError({ commit }, message) {
    commit('setError', message);
  },
  clearError({ commit }) {
    commit('clearError');
  },
  setNotification({ commit }, message) {
    commit('setNotification', message);
  },
  clearNotification({ commit }) {
    commit('clearNotification');
  },
};
