import React, { FC, useEffect, useState } from 'react';
import { EquipmentDetails } from '@/components';
import MetricController from '@/controllers/MetricController';

interface Props {
  selected: string;
  queryId: string;
}

export const EquipmentDetailWrapperBackup: FC<Props> = ({ selected, queryId }) => {
  const [apiData, setApiData] = useState({});
  const filter = {
    key: 'data.meta.AutoCad:LineKey',
    value: selected,
  };
  const aggregationKey = '$data.meta.AutoCad:LineKey';

  useEffect(() => {
    const filterParameter = [{ key: 'meta.AutoCad:LineKey', value: [filter] }];

    async function fetchMyAPI() {
      const { data } = await MetricController.getMetricAggregate(queryId, 'asset', 2, filterParameter, aggregationKey);
      setApiData(data);
    }
    fetchMyAPI();
  }, [selected, queryId]);

  return <EquipmentDetails equipmentData={apiData} />;
};
