var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { attrs: { "two-line": "" } },
    [
      _c(
        "v-list-item-content",
        [
          _c(
            "v-list-item-subtitle",
            [
              _vm.loading ? _c("LoadingSpinner") : _vm._e(),
              _vm.data && Object.entries(_vm.data).length
                ? _c("EquipmentDetails", {
                    attrs: { "equipment-data": _vm.data, isAggregatedTable: "" }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }