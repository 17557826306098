// NOTE: This is not used for staging builds in the CI pipeline

const devConfig = {
  get apiPath() {
    return (
      window.localStorage.getItem('api-endpoint') || process.env.VUE_APP_API_PATH || 'https://master.api.abyssfabric.co'
    );
  },
  environment: process.env.VUE_APP_ENVIRONMENT || 'development',
  buildVersion: process.env.VUE_APP_BUILD_VERSION || '2020-07-10 - 8194300e1abe4ab5fa6875779e396a544f326688',
  ...(process.env.VUE_APP_WHITE_LABEL_LOGO &&
    process.env.VUE_APP_WHITE_LABEL_URL && {
      whiteLabel: {
        logo: process.env.VUE_APP_WHITE_LABEL_LOGO,
        url: process.env.VUE_APP_WHITE_LABEL_URL,
      },
    }),
};

const prodConfig = {
  get apiPath() {
    return process.env.VUE_APP_API_PATH || 'https://release.api.abyssfabric.co';
  },
  environment: process.env.VUE_APP_ENVIRONMENT || 'production',
  buildVersion: process.env.VUE_APP_BUILD_VERSION || 'untagged - prod build',
  ...(process.env.VUE_APP_WHITE_LABEL_LOGO &&
    process.env.VUE_APP_WHITE_LABEL_URL && {
      whiteLabel: {
        logo: process.env.VUE_APP_WHITE_LABEL_LOGO,
        url: process.env.VUE_APP_WHITE_LABEL_URL,
      },
    }),
};

export default process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
