<template>
  <DataTable
    :headers="inspectionConfig.nonAggregateEquipmentHeaders"
    :data="data"
    :unit="$store.state.unit"
    :items-per-page="20"
    :loading="loading"
    loading-text="Loading metrics, please wait..."
    primary-key="equipment_id"
    :generate-csv-trigger="generateCsvTrigger"
    @onTableCellClick="handleTableCellClick"
    @onCsvGenerated="handleCsvGenerated"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import MetricController from '@/controllers/MetricController';
import { DataTable } from '@/components';
import { extractNestedFields, nonAggregateTableCustomFields } from '../utils';

export default {
  name: 'NonAggregateTableContainer',
  components: {
    DataTable,
  },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
    generateCsvTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      data: undefined,
    };
  },
  computed: mapGetters({
    inspectionConfig: 'config/inspectionConfig',
  }),
  watch: {
    filter: {
      immediate: true,
      handler(value, previous) {
        // prevent repeated calls from repeating work by checking for previous filter
        if (JSON.stringify(value) !== JSON.stringify(previous)) {
          this.loadData(value);
        }
      },
    },
  },
  methods: {
    async loadData(filter) {
      this.loading = true;
      const { data } = await MetricController.getMetricChildren2(this.$route.query.id, 'asset', 2, filter, {
        type: 1,
        'data.meta': 1,
        'data.metrics': 1,
        inspection_uuid: 1,
        'data.referring_images': 1,
        name: 1,
      });

      const { corrosionLayers, nonAggregateEquipmentHeaders } = this.inspectionConfig;
      this.data = extractNestedFields(
        Object.entries(corrosionLayers),
        nonAggregateEquipmentHeaders,
        data,
        nonAggregateTableCustomFields
      );

      this.loading = false;
    },
    handleTableCellClick(row) {
      this.$emit('onDetailFieldClick', row);
    },
    handleCsvGenerated(data) {
      this.$emit('onCsvGenerated', data);
    },
  },
};
</script>
