<template>
  <div>
    <AvailableImagesTable
      v-if="!showAssembliesInsights"
      :loading="loading"
      :equipment-tags="equipmentTags"
      :data="data"
      :open-by-common-images="openByCommonImages"
      @onClose="handleClose"
      @onImageSelected="handleImageSelected"
    />

    <AssembliesInsights v-if="showAssembliesInsights" :equipment-tags="equipmentTags" />
    <Snackbar :type="notification" :text="errorMessage" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import utils, { queryStringBuilder, groupBy } from '@/utils';
import MetricController from '@/controllers/MetricController';
import { Snackbar, notificationType } from '@/components/widgets';
import AvailableImagesTable from './AvailableImagesTable.vue';
import AssembliesInsights from './AssembliesInsights.vue';

export default {
  name: 'AvailableImagesTableContainer',
  components: {
    AssembliesInsights,
    AvailableImagesTable,
    Snackbar,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    openByCommonImages: {
      type: Boolean,
      default: false,
    },
    showAssembliesInsights: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      equipmentTags: [],
      data: [],
      errorMessage: undefined,
      notification: notificationType.none,
    };
  },
  watch: {
    selected: {
      immediate: true,
      async handler(images) {
        this.loading = true;
        this.data = [];
        const result = await Promise.all(
          images.map(async ({ equipmentId, filter }) => ({
            equipmentId,
            response: await MetricController.getAggregateImages(this.$route.query.id, filter),
          }))
        );

        const imagesData = result.flatMap(({ equipmentId, response: { data, error } }) =>
          error
            ? { error }
            : data.referringImages.map(({ image_id: imageId, image_name: imageName }) => ({
                equipmentId,
                equipmentTag: data._id,
                imageId,
                imageName: utils.extractPathAndExtension(imageName),
              }))
        );

        if (imagesData?.[0].error) {
          this.handleError(imagesData?.[0].error);
        } else {
          // Find intersection of imageIds by ensuring that they exist in each image (using length)
          const grouped = groupBy(imagesData, 'imageId');
          this.data = Object.entries(grouped).reduce(
            (intersection, [imageId, groupedImages]) => [
              ...intersection,
              ...(groupedImages.length === images.length ? groupedImages.map((image) => ({ ...image, imageId })) : []),
            ],
            []
          );
        }
        this.equipmentTags = Array.from(new Set(imagesData.map(({ equipmentTag }) => equipmentTag)));
        this.loading = false;
      },
    },
  },
  methods: {
    handleImageSelected({ equipmentId, equipmentTag, imageId, event }) {
      const queryString = queryStringBuilder({
        id: this.$route.query.id,
        equipid: equipmentId,
        tagname: equipmentTag,
        image: imageId,
      });
      if (event.ctrlKey || event.metaKey) {
        window.open(`/spherical?${queryString}`);
      } else {
        this.$router.push({ path: `/spherical?${queryString}` });
      }
    },
    handleClose() {
      this.$emit('onClose');
    },
    handleError(message) {
      this.errorMessage = message;
      this.notification = notificationType.error;
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
  },
};
</script>
