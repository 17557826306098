<template>
  <div>
    <div v-for="({ text, value, component, props }, index) in list" :key="index">
      <component :is="configComponent(component)" v-if="component" :props="props" />
      <div v-else>
        <div class="subtitle-2">{{ text }}</div>
        <div class="body-2">{{ value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomComponents from '../customComponents';

export default {
  name: 'EquipmentList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      configComponent(component) {
        if (CustomComponents[component]) {
          return CustomComponents[component];
        }
        return {
          render(h) {
            return h('div', `Unknown component ${component}`);
          },
        };
      },
    };
  },
};
</script>
