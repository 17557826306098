import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import SessionController from '@/controllers/SessionController';
import * as Sentry from '@sentry/vue';
import LogRocket from 'logrocket';
import { debugEnabled } from '@/utils';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...authOptions
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    async created() {
      const { data: keys } = await SessionController.getAuth0Keys();

      try {
        this.auth0Client = await this.createClient({ domain: keys.domain, clientId: keys.clientId });

        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client?.isAuthenticated();
        if (this.isAuthenticated) {
          this.user = await this.auth0Client.getUser();

          const { sub: userID, email, name, 'http://abyss.com/user/roles': roles } = this.user;

          if (debugEnabled) {
            LogRocket.identify(userID, {
              name,
              email,
              ...(roles?.length > 0 && { roles: roles.join(', ') }),
            });
          }
          Sentry.setUser({ id: userID, email });
        }
        this.loading = false;
      }
    },
    methods: {
      createClient({ domain, clientId }) {
        try {
          return createAuth0Client({
            ...authOptions,
            domain,
            client_id: clientId,
            redirect_uri: redirectUri,
          });
        } catch (e) {
          throw new Error('Failed to initialise authentication service');
        }
      },
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(options) {
        return this.auth0Client.loginWithRedirect(options);
      },
      getIdTokenClaims(options) {
        return this.auth0Client.getIdTokenClaims(options);
      },
      getTokenSilently(options) {
        return this.auth0Client.getTokenSilently(options);
      },
      getTokenWithPopup(options) {
        return this.auth0Client.getTokenWithPopup(options);
      },
      logout(options) {
        return this.auth0Client.logout({ ...options, returnTo: redirectUri });
      },
    },
  });
  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
