var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overlay" }, [
    _c("img", {
      ref: "olimage",
      attrs: { src: _vm.source },
      on: { load: _vm.loaded }
    }),
    _vm.heatmapSource
      ? _c("svg", {
          attrs: { viewBox: _vm.calculateViewBox },
          domProps: { innerHTML: _vm._s(_vm.heatmapSource) }
        })
      : _vm._e(),
    _vm.drawSVG
      ? _c(
          "svg",
          { attrs: { viewBox: _vm.calculateViewBox } },
          [
            _vm.drawCrosshair
              ? _c("line", {
                  style: _vm.calcCorsshairStyle,
                  attrs: {
                    x1: _vm.crosshair.x,
                    y1: "0",
                    x2: _vm.crosshair.x,
                    y2: "99999"
                  }
                })
              : _vm._e(),
            _vm.drawCrosshair
              ? _c("line", {
                  style: _vm.calcCorsshairStyle,
                  attrs: {
                    x1: "0",
                    y1: _vm.crosshair.y,
                    x2: "9999",
                    y2: _vm.crosshair.y
                  }
                })
              : _vm._e(),
            _vm._l(_vm.calculatePolygons, function(polygon, idx) {
              return _c("polygon", {
                key: idx,
                style: _vm.makeStyle(
                  polygon.color,
                  polygon.strokeColor,
                  polygon.strokeWidth
                ),
                attrs: { points: polygon.svg },
                on: {
                  mouseover: function($event) {
                    return _vm.polyHover(polygon.id)
                  },
                  click: function($event) {
                    return _vm.polyClicked(polygon.id, $event)
                  }
                }
              })
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }