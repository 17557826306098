var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.decks,
                  label: "Deck Selection",
                  filled: "",
                  dense: ""
                },
                on: { change: _vm.handleSelectedDeckChanged },
                model: {
                  value: _vm.selectedDeck,
                  callback: function($$v) {
                    _vm.selectedDeck = $$v
                  },
                  expression: "selectedDeck"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  on: { change: _vm.handleTabChanged },
                  model: {
                    value: _vm.tabsModel,
                    callback: function($$v) {
                      _vm.tabsModel = $$v
                    },
                    expression: "tabsModel"
                  }
                },
                [
                  _c("v-tab", [_vm._v("Camera Positions")]),
                  _c("v-tab", [_vm._v("Paint Regions")]),
                  _vm.enableHeatMaps
                    ? _c("v-tab", [_vm._v("Heat Maps")])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "padding12",
              attrs: { multiple: "" },
              model: {
                value: _vm.defaultVisibleCollapsedLayers,
                callback: function($$v) {
                  _vm.defaultVisibleCollapsedLayers = $$v
                },
                expression: "defaultVisibleCollapsedLayers"
              }
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _vm.showMapSizeToggler
                    ? _c(
                        "v-row",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticStyle: { "z-index": "2" },
                                              attrs: {
                                                color: "secondary",
                                                fab: "",
                                                "x-small": "",
                                                dark: ""
                                              },
                                              on: {
                                                click: _vm.toggleOverlaySize
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "mdi-arrow-top-left-bottom-right"
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4277521342
                              )
                            },
                            [_c("span", [_vm._v("Toggle Map Size")])]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-expansion-panel-header",
                    { ref: "porto" },
                    [
                      _vm._v(" Deck Map "),
                      _c("v-spacer"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatImageNameForDisplay(
                              _vm.spatialHoverLocation
                            )
                          ) +
                          " "
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-col",
                        {
                          style: { transition: "0.3s", margin: "0 auto" },
                          attrs: { sm: "12", md: _vm.computedOverlaySize }
                        },
                        [
                          _c("Overlay", {
                            attrs: {
                              source: _vm.spatialDeckResource,
                              polygons: _vm.spatialPolygons,
                              "heatmap-source": _vm.heatmapFilter.source,
                              "active-polygon-id": _vm.spatialHoverLocation
                            },
                            on: {
                              "poly-hover": _vm.locationMouseOver,
                              "poly-selected": _vm.locationClicked
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.tabsModel === _vm.TabsEnum.CAMERA ||
              _vm.tabsModel === _vm.TabsEnum.REGION
                ? _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Spatial View Parameters")
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("SpatialViewParameter", {
                            attrs: {
                              "spatial-filter": _vm.spatialFilter,
                              "active-tab": _vm.tabsModel
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabsModel === _vm.TabsEnum.HEATMAPS
                ? _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", { ref: "porto" }, [
                        _vm._v("Heat Map Filters")
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("DeckPlanFilter", {
                            ref: "deckPlanFilter",
                            attrs: {
                              "height-range":
                                _vm.activeDeckDocument &&
                                _vm.activeDeckDocument.heightRange,
                              "height-step":
                                _vm.activeDeckDocument &&
                                _vm.activeDeckDocument.heightStep,
                              loading: _vm.loading,
                              "is-source-loaded": Boolean(
                                _vm.heatmapFilter.source
                              )
                            },
                            on: {
                              filter: _vm.updateSpatialHeatmap,
                              clearFilter: _vm.handleClearSpatialHeatmap
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c("DataTable", {
                    attrs: {
                      headers: _vm.activeTableHeaders,
                      data: _vm.activeTableData,
                      unit: _vm.$store.state.unit,
                      "primary-key": "id"
                    },
                    on: { onTableCellClick: _vm.handleTableCellClick }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Snackbar", {
        attrs: { type: _vm.snackBarType, text: _vm.snackBarText },
        on: { onClose: _vm.handleSnackbarClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }