// TODO: add tests.
// TODO: convert into typescript --> should <vuex-module-decorators> be involved?
import Vue from 'vue';
import { partialUpdateMessage } from '@/utils';
import { AssetController, MetricController, PartController } from '@/controllers';

const initialState = {
  // current inspection information on spherical view.
  currentInspectionInfo: {},
  // current asset parts information on spherical view.
  currentAssetParts: [],
  imageDocument: undefined,
};

const getters = {
  currentInspectionInfo: (state) => state.currentInspectionInfo,
  currentAssetParts: (state) => state.currentAssetParts,
  imageDocument: (state) => state.imageDocument,
  assetPartById: (state) => (id) => state.currentAssetParts.some((part) => part.id === id),
};

const actions = {
  setCurrentInspectionDocument({ commit }, inspectionDocument) {
    commit('setCurrentInspectionDocument', inspectionDocument);
  },
  async loadCurrentAssetParts({ commit, dispatch }, platformId) {
    const { data, error } = await AssetController.getParts({
      platformId,
      fields: ['_id', 'name'],
    });
    if (data) {
      commit(
        'setCurrentAssetParts',
        data.map(({ _id, name }) => ({ name, id: _id }))
      );
    } else {
      dispatch('spherical/setError', `Unable to load parts ${error ?? ''}`, { root: true });
    }
  },
  updateImageDocument({ commit }, annotations) {
    commit('updateImageDocumentAssets', annotations);
  },
  async loadImageDocument({ commit, dispatch }, image) {
    const { data, error } = await MetricController.getMetricData(image);
    if (data?.[0]) {
      commit('setImageDocument', data[0]);
    } else if (error === 400) {
      dispatch('config/setNotFoundMessage', 'Image Not Found', { root: true });
    } else {
      dispatch('spherical/setError', `Unable to get image document ${error ?? ''}`, { root: true });
    }
  },
  async updateCurrentAssetParts({ commit, dispatch, getters: { assetPartById } }, tagId) {
    // check if id in currentAssetParts
    // if doesn't exist - api call to get single asset by id
    // append to currentAssetParts
    if (!assetPartById(tagId)) {
      const data = await AssetController.getAsset(tagId);
      if (data) {
        commit('addCurrentAssetParts', data);
      } else {
        dispatch('spherical/setError', 'Unable to update current asset parts', { root: true });
      }
    }
  },
  async updatePartAssembly({ commit, dispatch }, { platformId, equipmentId, assemblyId, assemblyName, imageId }) {
    const { data, error, message } = await PartController.updatePartAssembly({
      platformId,
      assemblyId,
      partId: equipmentId,
      imageId,
    });
    if (data) {
      commit('updatePartAssembly', { equipmentId, assemblyName });
      dispatch('spherical/updateAssetsWithAssemblies', [{ equipmentId, assemblyName, assemblyId }], { root: true });
      dispatch('spherical/setNotification', 'Successfully updated assembly', { root: true });
    } else {
      dispatch('spherical/setError', `Unable to update assembly ${error || message || ''}`, { root: true });
    }
  },
  async updatePartAssemblies({ commit, dispatch, state, rootGetters }, { assemblyId, markers, imageId }) {
    const platformId = state.currentInspectionInfo._id;
    const matchedEquipmentIds = new Set(markers.map(rootGetters['spherical/matchedMarkerEquipmentId']));

    const results = await Promise.all(
      Array.from(matchedEquipmentIds).map((partId) =>
        PartController.updatePartAssembly({ platformId, assemblyId, partId, imageId })
      )
    );

    const [equipmentIds, failures] = results.reduce(
      ([successful, failed], { error, data: { _id: id }, message }) => {
        if (error) {
          return [successful, failed.concat(error || message || '')];
        }
        return [successful.concat(id), failed];
      },
      [[], []]
    );

    const totalSuccessful = equipmentIds.length;
    if (totalSuccessful === 0) {
      // All failed
      dispatch('spherical/setError', failures.join(' '), { root: true });
    } else {
      const assemblyName = rootGetters['labelTagging/assemblyNameById'](assemblyId);
      commit('updatePartAssemblies', { equipmentIds, assemblyName });
      dispatch(
        'spherical/updateAssetsWithAssemblies',
        equipmentIds.map((equipmentId) => ({ equipmentId, assemblyName })),
        { root: true }
      );

      const { success, fail } = partialUpdateMessage(
        totalSuccessful,
        results.length - totalSuccessful,
        'assembl',
        'y',
        'ies'
      );
      if (success) {
        dispatch('spherical/setNotification', success, { root: true });
      } else {
        dispatch('spherical/setError', fail, { root: true });
      }
    }
  },
};

const mutations = {
  setCurrentInspectionDocument(state, inspectionDocument) {
    state.currentInspectionInfo = inspectionDocument;
  },
  setCurrentAssetParts(state, assetParts) {
    state.currentAssetParts = assetParts;
  },
  addCurrentAssetParts(state, { name, _id: id }) {
    state.currentAssetParts.push({ name, id });
  },
  setImageDocument(state, imageDocument) {
    state.imageDocument = imageDocument;
  },
  updateImageDocumentAssets(state, annotations) {
    annotations.forEach(({ annotationId, equipmentId }) => {
      const matchedAnnotation = state.imageDocument.data.assets.find(
        ({ annotation_id }) => annotation_id === annotationId
      );
      if (matchedAnnotation) {
        matchedAnnotation.equipment_id = equipmentId; // Mutates matched annotation
      }
    });
  },
  updatePartAssembly(state, { equipmentId, assemblyName }) {
    const matchedPart = state.currentAssetParts.find(({ id }) => id === equipmentId);
    if (matchedPart) {
      Vue.set(matchedPart, 'name', assemblyName);
    }
  },
  updatePartAssemblies(state, { equipmentIds, assemblyName }) {
    const updatePartAssembly = (equipmentId) => mutations.updatePartAssembly(state, { equipmentId, assemblyName });
    equipmentIds.forEach(updatePartAssembly);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
