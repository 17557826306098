<template>
  <v-container class="px-0 py-0" fluid>
    <v-row align="start">
      <v-col cols="12">
        <AdvancedFilter
          :filter-params="basicFilterParams"
          :updated-params="filter"
          :initial-state="initialFilters"
          horizontal-toggler
          multiple
          @onChange="filterComponentUpdate"
        />
      </v-col>
      <v-col cols="2">
        <div class="text-h6 mb-6 text-left">Detail</div>
        <v-radio-group v-model="activeFilterType">
          <v-radio
            v-for="filter of filterTypes"
            :key="filter.value"
            :label="filter.name"
            :value="filter.value"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <div class="text-h6 mb-6 text-left">Value</div>
        <v-radio-group v-model="activeFilterKey">
          <v-radio
            v-for="filter of filterKeys"
            :key="filter.value"
            :label="filter.name"
            :value="filter.value"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>
        <div :style="[activeFilterKey === 'substrateConditionArea' ? { display: 'block' } : { display: 'none' }]">
          <div class="text-h6 mb-6 text-left">Corrosion filter</div>
          <AdvancedFilter
            :filter-params="corrosionFilterParams"
            :updated-params="filter"
            :initial-state="initialFilters"
            horizontal-toggler
            multiple
            @onChange="filterComponentUpdate"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h6 mb-6 text-left">Height</div>
        <v-range-slider
          v-model="activeHeightRange"
          hint="Select height range"
          :max="heightRange[1]"
          :min="heightRange[0]"
          :step="heightStep"
          class="align-center"
        >
          <template #prepend>
            <v-text-field
              :value="activeHeightRange[0]"
              :rules="minHeightRule"
              class="mt-0 pt-0 text-field"
              hide-details
              single-line
              type="number"
              @change="$set(activeHeightRange, 0, $event)"
            ></v-text-field>
          </template>
          <template #append>
            <v-text-field
              :value="activeHeightRange[1]"
              :rules="maxHeightRule"
              class="mt-0 pt-0 text-field"
              hide-details
              single-line
              type="number"
              @change="$set(activeHeightRange, 1, $event)"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h6 mb-6 text-left">Value</div>
        <v-checkbox v-model="paintBlockOutline" label="Paint Block Outline" />
      </v-col>
      <v-col cols="1" class="my-auto">
        <v-row>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn icon :loading="loading" v-on="on" @click="apply">
                <v-icon v-if="isSourceLoaded">mdi-cached</v-icon>
                <v-icon v-else>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Apply Filters</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn :disabled="!isSourceLoaded" icon v-on="on" @click="$emit('clearFilter')">
                <v-icon>mdi-eraser</v-icon>
              </v-btn>
            </template>
            <span>Clear Heat Maps</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get, omit } from 'lodash';
import { mapGetters } from 'vuex';

import { MetricController } from '@/controllers';
import AdvancedFilter from '@/components/widgets/AdvancedFilter.vue';
import utils from '@/utils';

export default {
  name: 'DeckPlanFilter',
  components: {
    AdvancedFilter,
  },
  props: {
    heightRange: {
      type: Array,
      default: () => [-1, 20],
    },
    initialActiveHeightRange: {
      type: Array,
      default: () => [0, 20],
    },
    heightStep: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSourceLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeFilterKey: 'substrateConditionMax',
      filterKeys: [
        { name: 'Substrate Condition (Max)', value: 'substrateConditionMax' },
        { name: 'Coating Condition (Max)', value: 'coatingConditionMax' },
        { name: 'Substrate Condition (Area)', value: 'substrateConditionArea' },
      ],
      activeFilterType: 'block',
      filterTypes: [
        { name: 'Paint Block', value: 'block' },
        { name: 'Detailed(Voxel)', value: 'voxel' },
      ],
      activeHeightRange: this.initialActiveHeightRange,
      renderMode: 'voxel',
      parts: [],
      paintBlockOutline: false,
      filter: [{ key: 'initial', value: 'initial' }],
      initialFilters: {
        'metrics.remediated': null,
        nonaggregate: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      filterParams: 'config/filterParams',
    }),
    basicFilterParams() {
      const basicParams = [
        'meta.AutoCad:Size',
        'meta.AutoCad:Service',
        'meta.AutoCad:Spec',
        'meta.AutoCad:LineKey',
        'meta.AutoCad:Class',
        'meta.AutoCad:Group',
      ];

      return basicParams.reduce(
        (result, param) => ({
          ...result,
          ...(this.filterParams[param] && { [param]: this.filterParams[param] }),
        }),
        {}
      );
    },
    corrosionFilterParams() {
      const initial = {
        'metrics.corrosion_category': {
          displayName: 'Corrosion Category',
          options: ['Clean', 'Light', 'Moderate', 'Heavy'],
        },
      };

      return initial;
    },
    activeFilter() {
      const modes = [this.activeFilterKey];
      if (this.paintBlockOutline) modes.push('paintBlockOutline');
      return {
        type: this.activeFilterType,
        heightRange: this.activeHeightRange,
        modes,
        renderMode: this.renderMode,
        filters: this.filter.map(({ key }) => key),
      };
    },
    minHeightRule() {
      return [(v) => v >= this.heightRange[0] || `Min height value is ${this.heightRange[0]}`];
    },
    maxHeightRule() {
      return [(v) => v <= this.heightRange[1] || `Max height value is ${this.heightRange[1]}`];
    },
  },
  watch: {
    heightRange(now) {
      this.activeHeightRange = now;
    },
  },
  async created() {
    await this.initializeFilter();
  },
  methods: {
    apply() {
      this.$emit(
        'filter',
        omit(this.activeFilter, ['source']),
        this.filter,
        this.activeFilterKey === 'substrateConditionArea'
      );
    },

    filterComponentUpdate(activeParams, clearedParamKeys) {
      this.updateFilterState(activeParams, clearedParamKeys);
    },

    updateFilterState(activeParams, clearParamKeys = [], pushQuery) {
      const filterObject = activeParams.reduce((filter, { key, value }) => {
        if (!clearParamKeys.includes(key)) {
          if (!filter[key]) {
            // eslint-disable-next-line no-param-reassign
            filter[key] = new Set(value);
          } else {
            filter[key].add(...value);
          }
        }
        return filter;
      }, {});

      this.filter = Object.entries(filterObject).map(([key, value]) => ({ key, value: [...value] }));

      // Update query param
      if (activeParams.length > 0 || clearParamKeys.length > 0) {
        // Check for any change in the query params
        this.updateQueryParams(activeParams, clearParamKeys, pushQuery);
      }
    },

    updateQueryParams(activeParams, clearParamKeys, pushQuery) {
      // Remove clearParamKeys from the current query params
      const clearedQuery = clearParamKeys.reduce(
        (queries, key) => {
          // eslint-disable-next-line no-param-reassign
          delete queries[key];
          return queries;
        },
        { ...this.$route.query, ...pushQuery }
      );

      // Add activeParams to the new query params
      const query = activeParams.reduce(
        (queries, { key, value }) => ({
          ...queries,
          ...(value && key !== 'undefined' && { [key]: value.map((item) => encodeURIComponent(item)).join(',') }),
        }),
        clearedQuery
      );

      if (pushQuery) {
        // enable browser back button
        this.$router.push({ query });
      } else {
        this.$router.replace({ query }, () => {}); // Ignore NavigationDuplicated error
      }
    },
    updateInitialFilters(query) {
      const filters = Object.entries(query).filter(([key]) => key !== 'id' && key !== 'nonaggregate');

      if (filters.length === 0) {
        this.updateFilterState([{}]); // Hack to load data the first time when no query param filters
      } else {
        this.updateFilterState(
          filters.map(([key, value]) => ({
            key,
            value: decodeURIComponent(value)
              .split(',')
              .map((item) => (key === 'metrics.corrosion_category' ? utils.getNumeric(item) : item)),
          }))
        );
      }
    },
    async initializeFilter() {
      const { query } = this.$route;
      if (query.source === 'external') {
        const { system, tag, id } = query;
        const { data, error } = await MetricController.getExternalSystem(id, system, tag);
        if (error) {
          this.handleError(error);
        } else {
          this.updateFilterState([{ key: 'meta.AutoCad:LineKey', value: get(data, 'tagName') }]);
        }
      } else {
        // Needed to render the graphs when there is no source query
        this.$nextTick(() => {
          this.updateInitialFilters(query);
        });
      }
    },
  },
};
</script>

<style scoped>
.text-field {
  width: 45px;
}
</style>
