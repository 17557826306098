



















import { mapActions, mapGetters } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { HighlightStyle, LabelTaggingMode } from '@/utils';

@Component({
  computed: mapGetters({
    highlightingStyle: 'config/highlightingStyle',
    showUnlabelledHighlighting: 'config/showUnlabelledHighlighting',
    labelTaggingMode: 'config/labelTaggingMode',
  }),
  methods: mapActions({
    setHighlightingStyle: 'config/setHighlightingStyle',
    setShowUnlabelledHighlighting: 'config/setShowUnlabelledHighlighting',
  }),
})
export default class HighlightStyleSwitcher extends Vue {
  highlightingStyle!: HighlightStyle;

  showUnlabelledHighlighting!: boolean;

  labelTaggingMode!: LabelTaggingMode;

  private radioValue = HighlightStyle.PART;

  private switcherValue = false;

  setHighlightingStyle!: (radioValue: HighlightStyle) => void;

  setShowUnlabelledHighlighting!: (switcherValue: boolean) => void;

  @Watch('showUnlabelledHighlighting')
  nameChanged(value: boolean) {
    this.switcherValue = value;
  }

  handleRadioChanged(radioValue: HighlightStyle) {
    this.setHighlightingStyle(radioValue);
  }

  handleSwitcherChange(switcherValue: boolean) {
    this.setShowUnlabelledHighlighting(switcherValue);
  }

  mounted() {
    this.radioValue = this.highlightingStyle;
    this.switcherValue = this.showUnlabelledHighlighting;
  }

  get radioLabel(): object {
    switch (this.labelTaggingMode) {
      case LabelTaggingMode.NONE:
        return {
          type: 'Type',
          part: 'Part',
          assembly: 'Assembly',
          corrosion: 'Corrosion',
          service: 'Service',
        };
      default:
        return {
          type: 'Equipment Class',
          part: 'Part',
          assembly: 'Equipment Tag',
          corrosion: 'Corrosion',
          service: 'Service',
        };
    }
  }

  get showHighlightUnlabelledSwitcher() {
    return this.labelTaggingMode !== LabelTaggingMode.NONE;
  }
}
