<template>
  <div class="overlay">
    <img ref="olimage" :src="source" @load="loaded" />
    <svg v-if="heatmapSource" :viewBox="calculateViewBox" v-html="heatmapSource"></svg>
    <svg v-if="drawSVG" :viewBox="calculateViewBox">
      <line v-if="drawCrosshair" :x1="crosshair.x" y1="0" :x2="crosshair.x" y2="99999" :style="calcCorsshairStyle" />
      <line v-if="drawCrosshair" x1="0" :y1="crosshair.y" x2="9999" :y2="crosshair.y" :style="calcCorsshairStyle" />

      <polygon
        v-for="(polygon, idx) in calculatePolygons"
        :key="idx"
        :points="polygon.svg"
        :style="makeStyle(polygon.color, polygon.strokeColor, polygon.strokeWidth)"
        @mouseover="polyHover(polygon.id)"
        @click="polyClicked(polygon.id, $event)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    source: undefined,
    polygons: undefined,
    heatmapSource: { type: String, default: '' },
    activePolygonId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      overlay: {
        viewBoxW: 1494,
        viewBoxH: 902,
      },
      drawSVG: false,
    };
  },

  computed: {
    drawCrosshair() {
      if (this.polygons && this.activePolygonId && this.activePolygon) return true;
      // do not draw crosshair when no active polygon
      return false;
    },
    crosshair() {
      if (!this.drawCrosshair) return { x: 0, y: 0 };
      // return active polygon centroid coordinates
      const { points } = this.activePolygon;
      const getCenterPointInAxis = (pts) => (Math.min(...pts) + Math.max(...pts)) / 2;
      const range = points.reduce(
        ({ x, y }, [pt1, pt2]) => ({
          x: x.concat(pt1),
          y: y.concat(pt2),
        }),
        { x: [], y: [] }
      );
      return { x: getCenterPointInAxis(range.x), y: getCenterPointInAxis(range.y) };
    },
    activePolygon() {
      if (!this.polygons || !this.activePolygonId) return {};
      return this.polygons.find(({ id }) => id === this.activePolygonId);
    },
    calculatePolygons() {
      const result = this.polygons.map((x) => ({ ...x, svg: x.points.flat(10).join(',') }));
      return result;
    },

    calculateViewBox() {
      return `0 0 ${this.overlay.viewBoxW} ${this.overlay.viewBoxH}`;
    },

    calcCorsshairStyle() {
      const x = Math.sqrt((0.0025 * this.overlay.viewBoxW) ** 2 + (0.0025 * this.overlay.viewBoxH) ** 2);
      return `stroke:rgb(255,0,0);stroke-width:${0.5 * x}`;
    },
  },

  watch: {},
  destroyed() {},

  methods: {
    polyClicked(val, event) {
      this.$emit('poly-selected', val, event);
    },

    polyHover(val) {
      this.$emit('poly-hover', val);
    },

    makeStyle(color, strokeColor, strokeWidth) {
      return `fill:${color};stroke:${strokeColor || 'black'};stroke-width:${strokeWidth || 1};cursor:pointer;`;
    },
    loaded() {
      this.overlay.viewBoxW = this.$refs.olimage.naturalWidth;
      this.overlay.viewBoxH = this.$refs.olimage.naturalHeight;
      this.drawSVG = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.overlay {
  position: relative;
  width: 100%;
  // height:100%;
  margin: auto;

  img {
    width: 100%;
    height: auto;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    // width:50%;
  }
}
</style>
