var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                { on: { click: _vm.resetFilters } },
                [
                  _c("v-icon", [_vm._v("mdi-refresh")]),
                  _vm._v(" Reset Filter ")
                ],
                1
              ),
              _c(
                "v-btn",
                { on: { click: _vm.exportTableToCSV } },
                [
                  _c("v-icon", [_vm._v("mdi-export")]),
                  _vm._v(" Export Results ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("AdvancedFilter", {
                        attrs: {
                          "filter-params": _vm.basicFilterParams,
                          "updated-params": _vm.filter,
                          "initial-state": _vm.advancedFilterQueryState,
                          "horizontal-toggler": "",
                          multiple: ""
                        },
                        on: { onChange: _vm.filterComponentUpdate }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "flex-display", attrs: { cols: "1" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "65vw", "retain-focus": false },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g({ attrs: { fab: "" } }, on),
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-filter-plus-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [_vm._v("Advanced Filtering")]),
                          _c(
                            "v-card",
                            {
                              staticStyle: { "overflow-y": "scroll" },
                              attrs: { outlined: "", height: "65vh" }
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("AdvancedFilter", {
                                    attrs: {
                                      "filter-params":
                                        _vm.blacklistedFilterParams,
                                      height: "",
                                      multiple: ""
                                    },
                                    on: { onChange: _vm.filterComponentUpdate }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.metricTables.currentState,
                                        expression: "metricTables.currentState"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "true-value":
                                        _vm.metricTables.currentStateEnum
                                          .specific,
                                      "false-value":
                                        _vm.metricTables.currentStateEnum
                                          .aggregate
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.metricTables.currentState
                                      )
                                        ? _vm._i(
                                            _vm.metricTables.currentState,
                                            null
                                          ) > -1
                                        : _vm._q(
                                            _vm.metricTables.currentState,
                                            _vm.metricTables.currentStateEnum
                                              .specific
                                          )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateFilterState(_vm.filter)
                                      },
                                      change: function($event) {
                                        var $$a = _vm.metricTables.currentState,
                                          $$el = $event.target,
                                          $$c = $$el.checked
                                            ? _vm.metricTables.currentStateEnum
                                                .specific
                                            : _vm.metricTables.currentStateEnum
                                                .aggregate
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.metricTables,
                                                "currentState",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.metricTables,
                                                "currentState",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.metricTables,
                                            "currentState",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" De-Aggregate ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.pieCharts, function(v) {
            return _c(
              "v-col",
              { key: v.key, attrs: { cols: "6" } },
              [
                _c("ChartGroup", {
                  ref: "pieChart",
                  refInFor: true,
                  attrs: { id: v.key, title: v.name, source: v.chartGroupData },
                  on: { onSegmentSelected: _vm.handleChartSegmentSelected }
                })
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("ActiveFiltersDisplay", {
                attrs: {
                  aggregated:
                    _vm.metricTables.currentState ===
                    _vm.metricTables.currentStateEnum.aggregate,
                  filters: _vm.filter,
                  "filter-params": _vm.filterParams
                },
                on: { onClearFilter: _vm.handleClearFilter }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _vm.metricTables.currentState ===
                  _vm.metricTables.currentStateEnum.aggregate
                    ? _c("AggregateTable", {
                        attrs: {
                          filter: _vm.filter,
                          "generate-csv-trigger": _vm.generateCsvTrigger
                        },
                        on: {
                          onDetailFieldClick:
                            _vm.handleAggregateDetailFieldClick,
                          onLineFieldClick: _vm.handleAggregateLineFieldClick,
                          onCommonImagesSelected:
                            _vm.handleCommonImagesSelected,
                          onMultiLineIsometricSelected:
                            _vm.handleMultiLineIsometricSelected,
                          onMultiAssembliesSelected:
                            _vm.handleMultiAssembliesSelected,
                          onCsvGenerated: _vm.handleCsvGenerated
                        }
                      })
                    : _c("NonAggregateTable", {
                        attrs: {
                          filter: _vm.filter,
                          "generate-csv-trigger": _vm.generateCsvTrigger
                        },
                        on: {
                          onDetailFieldClick:
                            _vm.handleNonAggregateDetailFieldClick,
                          onCsvGenerated: _vm.handleCsvGenerated
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200", "retain-focus": false },
          model: {
            value: _vm.showReferringImages,
            callback: function($$v) {
              _vm.showReferringImages = $$v
            },
            expression: "showReferringImages"
          }
        },
        [
          _c("ReferringImagesTable", {
            attrs: { data: _vm.referringImagesData },
            on: { onClose: _vm.handleCloseReferringImagesTable }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200", "retain-focus": false },
          model: {
            value: _vm.showAvailableImages,
            callback: function($$v) {
              _vm.showAvailableImages = $$v
            },
            expression: "showAvailableImages"
          }
        },
        [
          _c("AvailableImagesTable", {
            attrs: {
              selected: _vm.selectedAvailableImages,
              "open-by-common-images": _vm.showByCommonImages,
              showAssembliesInsights: _vm.showAssembliesInsights
            },
            on: { onClose: _vm.handleCloseAvailableImagesTable }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200", "retain-focus": false },
          model: {
            value: _vm.showAssembliesInsights,
            callback: function($$v) {
              _vm.showAssembliesInsights = $$v
            },
            expression: "showAssembliesInsights"
          }
        },
        [
          _c("AvailableImagesTable", {
            attrs: {
              selected: _vm.selectedAvailableImages,
              "open-by-common-images": _vm.showByCommonImages,
              showAssembliesInsights: _vm.showAssembliesInsights
            },
            on: { onClose: _vm.handleCloseAvailableImagesTable }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            "hide-overlay": "",
            transition: "fade-transition"
          },
          model: {
            value: _vm.showLineIsometrics,
            callback: function($$v) {
              _vm.showLineIsometrics = $$v
            },
            expression: "showLineIsometrics"
          }
        },
        [
          _vm.pointCloudLines.length === 0
            ? _c(
                "v-card",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { height: "80vh" }
                },
                [
                  _vm.isLoadingResourceUrl ? _c("LoadingSpinner") : _vm._e(),
                  _vm.failedToGetResource
                    ? _c("span", [
                        _vm._v(
                          "Failed to load resources, please try it later or contact support"
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _c(
                "v-card",
                { attrs: { height: "80vh" } },
                [
                  _vm.showLineIsometrics
                    ? _c("LineIsometrics", {
                        staticStyle: { height: "100%" },
                        attrs: {
                          lines: _vm.pointCloudLines,
                          inspectionConfig: _vm.inspectionConfig
                        },
                        on: { onPointSelect: _vm.handlePointPick }
                      })
                    : _vm._e(),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "1200", "retain-focus": false },
                      model: {
                        value: _vm.showPointCloudReferringImages,
                        callback: function($$v) {
                          _vm.showPointCloudReferringImages = $$v
                        },
                        expression: "showPointCloudReferringImages"
                      }
                    },
                    [
                      _vm.isLoadingPointCloudReferringImages ||
                      _vm.failedToGetPointCloudReferringImages
                        ? _c(
                            "v-card",
                            {
                              staticClass: "d-flex justify-center align-center",
                              attrs: { height: "50vh" }
                            },
                            [
                              _vm.isLoadingPointCloudReferringImages
                                ? _c("LoadingSpinner")
                                : _vm._e(),
                              _vm.failedToGetPointCloudReferringImages
                                ? _c("span", [
                                    _vm._v(
                                      " Failed to load referring images, please try it later or contact support "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("ReferringImagesTable", {
                            attrs: {
                              data: _vm.pointCloudReferringImages,
                              multiSelectedEquipments:
                                _vm.selectedMultiLineIsometricsEquipments,
                              calledByMultiLineIsometrics:
                                _vm.calledByMultiLineIsometrics
                            },
                            on: {
                              onClose: _vm.handleClosePointCloudReferringImages
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }