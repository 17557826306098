var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.props.labelTaggingMode === _vm.LabelTaggingMode.NONE
    ? _c("div", [
        _c("div", { staticClass: "subtitle-2" }, [_vm._v("Equipment Name")]),
        _c(
          "div",
          {
            staticClass: "body-2",
            staticStyle: { "white-space": "break-spaces" }
          },
          [_vm._v(_vm._s(_vm.props.displayName))]
        )
      ])
    : _c("LabelTagging", {
        attrs: { "label-tagging-mode": _vm.props.labelTaggingMode }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }