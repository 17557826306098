var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", "retain-focus": false },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "white" } },
        [
          _c("v-card-title", [_vm._v("Transfer Items")]),
          _vm.options.length === 0
            ? _c("v-card-text", [
                _vm._v("No other open Work Pack available to transfer items to")
              ])
            : _c(
                "v-card-text",
                [
                  _c("ListInputField", {
                    attrs: {
                      label: "Select Work Pack to transfer to",
                      options: _vm.options,
                      "initial-selected": _vm.selected,
                      required: "",
                      autoComplete: ""
                    },
                    on: { onChange: _vm.handleChange }
                  })
                ],
                1
              ),
          _vm.selected
            ? _c(
                "v-alert",
                {
                  attrs: {
                    icon: "mdi-alert-circle",
                    "colored-border": "",
                    color: "warning",
                    border: "top",
                    prominent: ""
                  }
                },
                [
                  _vm._v(
                    " Are you sure you want to transfer the following item" +
                      _vm._s(_vm.transferItems.length > 1 ? "s" : "") +
                      " "
                  ),
                  _vm._l(_vm.transferItems, function(item) {
                    return _c("ul", { key: item }, [
                      _c("li", [_vm._v(_vm._s(item))])
                    ])
                  }),
                  _c("br"),
                  _vm._v(" From "),
                  _vm.transferFrom.equipmentTag
                    ? _c("span", [
                        _vm._v(
                          "Equipment tag: " +
                            _vm._s(_vm.transferFrom.equipmentTag) +
                            " on "
                        )
                      ])
                    : _vm._e(),
                  _c("strong", [_vm._v(_vm._s(_vm.transferFrom.workPack))]),
                  _vm._v(" to "),
                  _c("strong", [_vm._v(_vm._s(_vm.selected[0]))])
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", outlined: "", text: "" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    outlined: "",
                    text: "",
                    disabled: !_vm.selected
                  },
                  on: { click: _vm.handleConfirm }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }