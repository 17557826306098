var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tabsModel,
                    callback: function($$v) {
                      _vm.tabsModel = $$v
                    },
                    expression: "tabsModel"
                  }
                },
                _vm._l(_vm.formatTabs, function(ref) {
                  var display = ref.display
                  var to = ref.to
                  return _c("v-tab", { key: to.name, attrs: { to: to } }, [
                    _vm._v(_vm._s(display))
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12" } }, [_c("router-view")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }