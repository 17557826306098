<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tabsModel">
          <v-tab v-for="{ display, to } in formatTabs" :key="to.name" :to="to">{{ display }}</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Inspection',
  data() {
    return {
      tabsModel: null,
      tabs: [
        {
          display: 'Equipment',
          route: 'equipment',
        },
        {
          display: 'Deck Plan',
          route: 'deckplans',
        },
        {
          display: 'Work Packs',
          route: 'workPacks',
        },
      ],
    };
  },
  computed: {
    formatTabs() {
      return this.tabs.map(({ display, route }) => ({
        display,
        to: {
          name: route,
          query: this.$route.query,
        },
      }));
    },
  },
};
</script>
