<template>
  <v-card flat class="mx-auto">
    <v-container class="py-0">
      <v-row align="center" justify="start">
        <v-col v-if="!selections.length" class="shrink"><v-chip :style="{ visibility: 'hidden' }" /></v-col>
        <v-col v-for="(selection, i) in selections" :key="selection[idField]" class="shrink">
          <v-chip :disabled="loading" close @click:close="selected[page].splice(i, 1)">
            <v-avatar v-if="selection[imageField]" left>
              <img :src="selection[imageField]" alt="profile-pic" />
            </v-avatar>
            {{ selection[textField] }}
          </v-chip>
        </v-col>

        <v-col v-if="!allSelected" cols="12">
          <v-text-field
            ref="search"
            v-model="search"
            full-width
            hide-details
            :disabled="page !== GroupSelectorPages.ITEMS"
            :label="page === GroupSelectorPages.GROUPS ? 'Select Groups' : `Search ${selectionName}`"
            single-line
            :suffix="`${filteredItems.length} / ${pageItems.length}`"
          />
        </v-col>
        <v-col v-else-if="page === GroupSelectorPages.CONFIRM" cols="12">
          <v-card-text :style="{ height: '28.05vh' }">
            You are about to link {{ selected[GroupSelectorPages.ITEMS].length }} {{ selectionName.toLowerCase() }} with
            {{ selected[GroupSelectorPages.GROUPS].length }} groups. Click attach to add
            {{ selectionName.toLowerCase() }} to groups and detach to remove {{ selectionName.toLowerCase() }} from
            groups.
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-divider v-if="!allSelected" />

    <v-list v-if="page === GroupSelectorPages.ITEMS" height="22.4vh" :style="{ overflow: 'auto' }">
      <template v-for="item in filteredItems">
        <v-list-item
          v-if="!selected[page].includes(item)"
          :key="item[idField]"
          :disabled="loading"
          @click="selected[page].push(item)"
        >
          <v-list-item-avatar>
            <v-img :disabled="loading" :src="item[imageField]" />
          </v-list-item-avatar>
          <v-list-item-title v-text="item[textField]" />
          <v-spacer />
          <v-list-item-subtitle v-if="item[subtextField]" class="text-right" v-text="item[subtextField]" />
        </v-list-item>
      </template>
    </v-list>

    <v-list v-else-if="page === GroupSelectorPages.GROUPS" height="22.4vh" :style="{ overflow: 'auto' }">
      <template v-for="item in groups">
        <v-list-item
          v-if="!selected[page].includes(item)"
          :key="item[idField]"
          :disabled="loading"
          @click="selected[page].push(item)"
        >
          <v-list-item-avatar>
            <v-img :disabled="loading" :src="item[imageField]" />
          </v-list-item-avatar>
          <v-list-item-title v-text="item[textField]" />
        </v-list-item>
      </template>
    </v-list>

    <v-divider />

    <v-card-actions>
      <v-btn v-if="page === GroupSelectorPages.CONFIRM" color="purple" text @click="reset">Reset</v-btn>
      <v-btn
        v-else
        :disabled="!(selected[page] && selected[page].length > 0)"
        color="purple"
        text
        @click="selected[page] = []"
      >
        Clear
      </v-btn>
      <v-spacer />
      <div v-if="page === GroupSelectorPages.CONFIRM">
        <v-btn :loading="loading" color="purple" text @click="handleDetach">Detach</v-btn>
        <v-btn :loading="loading" color="purple" text @click="handleAttach">Attach</v-btn>
      </div>
      <v-spacer />
      <div>
        <v-btn :disabled="page === GroupSelectorPages.ITEMS" :loading="loading" color="purple" text @click="previous">
          Previous
        </v-btn>
        <v-btn
          :disabled="!(selected[page] && selected[page].length > 0)"
          :loading="loading"
          color="purple"
          text
          @click="next"
        >
          Next
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Fuse from 'fuse.js';
import { GroupSelectorPages } from '@/utils';

export default {
  name: 'GroupSelector',
  props: {
    selectionName: {
      type: String,
      default: '',
    },
    textField: {
      type: String,
      default: 'text',
    },
    subtextField: {
      type: String,
      default: 'subtext',
    },
    imageField: {
      type: String,
      default: 'icon',
    },
    idField: {
      type: String,
      default: '_id',
    },
    searchFields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    GroupSelectorPages,
    loading: false,
    search: '',
    selected: {
      [GroupSelectorPages.ITEMS]: [],
      [GroupSelectorPages.GROUPS]: [],
    },
    fuseOptions: {
      keys: [],
    },
    pages: {
      items: 0,
      groups: 1,
    },
    page: GroupSelectorPages.ITEMS,
  }),

  computed: {
    pageItems() {
      switch (this.page) {
        case GroupSelectorPages.ITEMS:
          return this.items;
        case GroupSelectorPages.GROUPS:
          return this.groups;
        default:
          return [];
      }
    },
    fuse() {
      return new Fuse(this.pageItems, this.fuseOptions);
    },
    allSelected() {
      if (!this.selected[this.page]) return true;
      return this.selected[this.page].length === this.pageItems.length;
    },
    filteredItems() {
      if (!this.fuse) return this.pageItems;
      const matches = this.fuse.search(this.search);
      if (matches.length === 0) return this.pageItems;

      return matches.map(({ item }) => item);
    },
    selections() {
      if (!this.selected[this.page]) return [];
      return [...this.selected[this.page]];
    },
  },

  mounted() {
    this.fuseOptions = { keys: this.searchFields };
  },

  methods: {
    reset() {
      this.search = '';
      this.selected = {
        [GroupSelectorPages.ITEMS]: [],
        [GroupSelectorPages.GROUPS]: [],
      };
      this.loading = false;
      this.page = GroupSelectorPages.ITEMS;
    },

    next() {
      this.search = '';

      // switch pages
      if (this.page === GroupSelectorPages.ITEMS) {
        // advance to group selection
        this.page = GroupSelectorPages.GROUPS;
      } else if (this.page === GroupSelectorPages.GROUPS) {
        this.page = GroupSelectorPages.CONFIRM;
      }
    },

    previous() {
      this.search = '';

      // switch pages
      if (this.page === GroupSelectorPages.GROUPS) {
        // revert to items selection
        this.page = GroupSelectorPages.ITEMS;
      } else if (this.page === GroupSelectorPages.CONFIRM) {
        // revert to groups selection
        this.page = GroupSelectorPages.GROUPS;
      }
    },

    handleAttach() {
      this.$emit('attach', this.selected[GroupSelectorPages.ITEMS], this.selected[GroupSelectorPages.GROUPS]);
    },

    handleDetach() {
      this.$emit('detach', this.selected[GroupSelectorPages.ITEMS], this.selected[GroupSelectorPages.GROUPS]);
    },
  },
};
</script>
