import { get } from 'lodash';

export const filterAndSortTableData = (data, field) => (filter) =>
  data.filter((row) => filter.includes(row[field])).sort((a, b) => filter.indexOf(a[field]) - filter.indexOf(b[field]));

export const flattenHeaderValues = ({ value, ...header }) => ({
  ...header,
  value: value.split('.').pop(),
});

// Only flattens 1 level deep - add primary key
export const flattenDataBasedOnHeaderStructure = (headers, primaryKey) => (fields) =>
  headers.reduce(
    (field, { value }) => {
      // eslint-disable-next-line no-param-reassign
      field[value.split('.').pop()] = get(fields, value);
      return field;
    },
    { [primaryKey]: fields[primaryKey] }
  );

const applyFormatter = (formatter, value) => {
  switch (typeof formatter) {
    case 'function':
      return formatter(value);
    case 'string':
      switch (formatter) {
        case 'numeric':
          return value.toFixed(2);
        case 'percentage':
          return (value * 100).toFixed(2);
        case 'boolean':
          return Boolean(value);
        case 'truthyAsYes':
          return value ? 'Yes' : '';
        case 'extractExtension':
          return value.replace(/^(.*?)(?:\.[^.]*)?$/, '$1'); // Remove everything after last period, if it exists
        default:
          return value;
      }
    default:
      return value;
  }
};

export const formatCustomFields = (headers) => (fields) =>
  Object.entries(fields).reduce((formattedField, [fieldName, value]) => {
    const { formatter } = headers.find((x) => x.value === fieldName) || {};
    // eslint-disable-next-line no-param-reassign
    formattedField[fieldName] = applyFormatter(formatter, value);
    return formattedField;
  }, {});

const extractFeetAndInches = new RegExp(/^(-*?\d*?)'?\s*(-?\d*\.?\d*)"?$/);

export const orderImperialDistances = (a, b) => {
  const [, aFeet, aInches] = extractFeetAndInches.exec(a) || [];
  const [, bFeet, bInches] = extractFeetAndInches.exec(b) || [];

  return +aFeet > +bFeet || (aFeet === bFeet && +aInches > +bInches) ? 1 : -1;
};

export const orderDates = (a, b) => Date.parse(a) - Date.parse(b);

export const applyStartsWithSort = (items, column, order, direction) => {
  items.sort((a, b) => {
    const aIndex = order.findIndex((item) => a[column].startsWith(item));
    const bIndex = order.findIndex((item) => b[column].startsWith(item));
    if (aIndex === bIndex) {
      return a[column] < b[column] ? -direction : direction;
    }
    return (aIndex - bIndex) * direction;
  });
};

export const applyNumericalSort = (items, column, direction) => {
  items.sort((a, b) => {
    const aNumerical = parseInt(a[column], 10);
    const bNumerical = parseInt(b[column], 10);
    if (aNumerical > bNumerical) {
      return direction;
    }
    if (aNumerical < bNumerical) {
      return -direction;
    }
    return a[column] > b[column] ? direction : -direction;
  });
};
