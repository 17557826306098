<template>
  <SphericalView
    :numeric-unit="numericUnit"
    :inspection-config="inspectionConfig"
    :snack-bar-type="type"
    :snack-bar-text="message"
    :developer-mode="developerMode"
    :assets="assets"
    :checked-layers="checkedLayers"
    :platform-name="inspectionDocument.name"
    :platform-id="inspectionDocument._id"
    :corrosion-data="corrosionData"
    :selected-asset="selectedAsset"
    :defect-layers="defectLayers"
    :asset-layers="assetLayers"
    :selected-polygon="selectedPolygon"
    :markers="markers"
    :is-measurement-line-by-id-selected="isMeasurementLineByIdSelected"
    :image-document="imageDocument"
    :reload-image="reloadImage"
    :neighbouring-images="neighbouringImagesInRange"
    :neighbouring-images-max-range="neighbouringImagesMaxRange"
    :neighbouring-images-range="neighbouringImagesRange"
    :stroke-width="strokeWidth"
    :asset-fill-enabled="assetFillEnabled"
    :highlight-reviewed-enabled="highlightReviewedEnabled"
    :active-pano-mode="activePanoMode"
    :pano-initial-equipment-id="panoInitialEquipmentId"
    :set-camera-on-corrosion="setCameraOnCorrosion"
    :set-camera-on-value="setCameraOnValue"
    :blister-error="blisterError"
    :blister-loading="blisterLoading"
    :blister-data="blisterData"
    :blister-layers="selectedBlisterLayers"
    @onUpdateMarkers="handleUpdateMarkers"
    @onSelectMarker="handleSelectMarker"
    @onCtrlUpdateAnnotations="handleCtrlUpdateAnnotations"
    @onAltUpdateAnnotations="handleAltUpdateAnnotations"
    @onDeleteAnnotation="handleDeleteAnnotation"
    @onUpdateCheckedLayers="handleUpdateCheckedLayers"
    @onUpdateLayers="handleUpdateLayers"
    @onSelectEquipment="handleSelectEquipment"
    @onClearSelectedPolygon="handleClearSelectedPolygon"
    @onLoadURLQuery="handleLoadURLQuery"
    @onNeighbouringImagesRangeChange="handleNeighbouringImagesRangeChange"
    @onToggleAssetFillEnabled="handleToggleAssetFillEnabled"
    @onToggleHighlighReviewedEnabled="handleToggleHighlighReviewedEnabled"
    @onSnackbarDisplay="handleSnackbarDisplay"
    @onSnackbarClose="handleSnackbarClose"
    @onToggleSelectedMeasurementLine="handleToggleSelectedMeasurementLine"
    @onExportData="handleExportDataToCsv"
    @onReviewPartConfirmed="handleReviewPartConfirmed"
    @onLoadBlister="handleLoadBlister"
    @onBlisterMarkerSelected="handleBlisterMarkerSelected"
    @onUpdateCheckedBlisterLayers="handleUpdateCheckedBlisterLayers"
    @onCloseBlisterHeight="handleCloseBlisterHeight"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, get } from 'lodash';
import { notificationType } from '@/components/widgets';
import utils, { LabelTaggingMode, PanoMode } from '@/utils';
import { updateQuery, deleteQueries } from '@/utils/queryActions';
import { MetricController, PartController } from '@/controllers';
import { router } from '@/router';
import SphericalView from './SphericalView.vue';

export default {
  name: 'SphericalContainer',
  components: {
    SphericalView,
  },
  imageId: undefined,
  data() {
    return {
      dataLoaded: false,
      reloadImage: false,
      reloadImageRequestNumber: 0,
      panoInitialEquipmentId: null,
      setCameraOnCorrosion: null,
      setCameraOnValue: null,
      activePanoMode: PanoMode.DEFAULT,
    };
  },
  computed: {
    ...mapGetters({
      numericUnit: 'numericUnit',
      assemblyNameById: 'labelTagging/assemblyNameById',
      assemblyIdByName: 'labelTagging/assemblyIdByName',
      inspectionConfig: 'config/inspectionConfig',
      assetFillEnabled: 'config/assetFillEnabled',
      highlightReviewedEnabled: 'config/highlightReviewedEnabled',
      strokeWidth: 'config/strokeWidth',
      neighbouringImagesRange: 'config/neighbouringImagesRange',
      developerMode: 'config/developerMode',
      notification: 'spherical/notification',
      error: 'spherical/error',
      assets: 'spherical/assets',
      checkedLayers: 'spherical/checkedLayers',
      selectedEquipmentId: 'spherical/selectedEquipmentId',
      inspectionDocument: 'inspectionDocument/currentInspectionInfo',
      selectedAsset: 'spherical/selectedAsset',
      defectLayers: 'spherical/defectLayers',
      assetLayers: 'spherical/assetLayers',
      corrosionData: 'spherical/corrosionData',
      selectedPolygon: 'spherical/selectedPolygon',
      markers: 'spherical/markers',
      imageDocument: 'inspectionDocument/imageDocument',
      neighbouringImages: 'spherical/neighbouringImages',
      labelTaggingMode: 'config/labelTaggingMode',
      highlightingStyle: 'config/highlightingStyle',
      showUnlabelledHighlighting: 'config/showUnlabelledHighlighting',
      latestSelectedAnnotationTagId: 'labelTagging/latestSelectedAnnotationTagId',
      isMeasurementLineByIdSelected: 'lineMeasurement/isLineByIdSelected',
      blisterError: 'blisters/error',
      blisterLoading: 'blisters/loading',
      blisterData: 'blisters/data',
      blisterLayers: 'blisters/blisterLayers',
      selectedPolygons: 'blisters/selectedPolygons',
    }),
    neighbouringImagesMaxRange() {
      return Math.ceil(Math.max(...this.neighbouringImages.map((image) => image.spherical.range)));
    },
    neighbouringImagesInRange() {
      return this.neighbouringImages.filter(({ spherical }) => spherical.range <= this.neighbouringImagesRange);
    },
    message() {
      return this.error || this.notification;
    },
    type() {
      if (this.error) {
        return notificationType.error;
      }
      if (this.notification) {
        return notificationType.message;
      }
      return notificationType.none;
    },
    selectedBlisterLayers() {
      return this.blisterLayers.map((layer) => ({
        ...layer,
        selected: this.selectedPolygons[layer.blisterId],
      }));
    },
  },
  watch: {
    blisterLoading(loading) {
      // Check if finished loading blister data but the modal was already closed
      if (loading === false && this.activePanoMode !== PanoMode.BLISTER) {
        if (this.blisterError) {
          this.setError(`There was an error loading the blister: ${this.blisterError}`);
          this.clearBlisterError();
        } else {
          this.setNotification('Successfully loaded Blister Heights');
        }
      }
    },
    imageDocument() {
      // May need to wait for imageDocument to be loaded, if it was not ready in the created lifecycle method
      if (!this.dataLoaded) {
        this.dataLoaded = !isEmpty(this.assets);
      }
      this.resetBlisters();
    },
    'selectedAsset.name': function tagNameWatcher(name) {
      updateQuery('tagname', name);
    },
    selectedEquipmentId(value) {
      updateQuery('equipid', value);
      this.setMarkers();
    },
    '$route.query.poly': function queryPolyWatcher(value) {
      this.setSelectedMarker({ markerId: value });
    },
    numericUnit(value) {
      this.updateDefectAndAssetLayers(value);
    },
    highlightingStyle() {
      this.setMarkers();
    },
    showUnlabelledHighlighting() {
      this.setMarkers();
    },
  },
  beforeMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  async created() {
    const { path, inspection_uuid: inspection, id } = this.$route.query;
    let { image } = this.$route.query;
    let error = null;
    if (!image && path && inspection) {
      const response = await MetricController.lookupSpherical(inspection, path);
      if (response.error) {
        error = response.error;
      } else {
        image = response.data._id;
        router.push({ query: { ...this.$route.query, image } });
      }
    }

    if (!error && !image) {
      error = 'Missing image path';
    }
    if (!error && !id) {
      error = 'Missing id path';
    }

    if (error) {
      this.setError(error);
    } else {
      await Promise.all([
        this.overrideConfigSettings(image),
        this.loadAssetsAndLayers(image),
        this.getNeighbouringImages(image),
        this.loadImageDocument(image),
        this.loadDeleteEquipmentId(id),
      ]);
    }

    this.dataLoaded = !isEmpty(this.imageDocument);
    this.reloadImage = this.dataLoaded; // Terrible HACK to update SphericalView by Eli
  },
  methods: {
    ...mapActions({
      loadAssetsAndLayers: 'spherical/loadAssetsAndLayers',
      loadDeleteEquipmentId: 'spherical/loadDeleteEquipmentId',
      reloadAssets: 'spherical/reloadAssets',
      resetBlisters: 'blisters/resetBlisters',
      loadBlister: 'blisters/loadBlister',
      setActiveBlister: 'blisters/setActiveBlister',
      updateCheckedBlisterLayers: 'blisters/updateCheckedBlisterLayers',
      toggleSelectedBlisterPolygon: 'blisters/toggleSelectedPolygon',
      clearBlisterError: 'blisters/clearError',
      getNeighbouringImages: 'spherical/getNeighbouringImages',
      setMarkers: 'spherical/setMarkers',
      loadImageDocument: 'inspectionDocument/loadImageDocument',
      setSelectedAssetAndEquipmentId: 'spherical/setSelectedAssetAndEquipmentId',
      unselectPolygon: 'spherical/unselectPolygon',
      setSelectedMarker: 'spherical/setSelectedMarker',
      updateAnnotations: 'spherical/updateAnnotations',
      deleteAnnotation: 'spherical/deleteAnnotation',
      clearSelectedPolygon: 'spherical/clearSelectedPolygon',
      updateLayers: 'spherical/updateLayers',
      updateCheckedLayers: 'spherical/updateCheckedLayers',
      updateDefectAndAssetLayers: 'spherical/updateDefectAndAssetLayers',
      overrideConfigSettings: 'config/overrideConfigSettings',
      setAssetFillEnabled: 'config/setAssetFillEnabled',
      setHighlightReviewedEnabled: 'config/setHighlightReviewedEnabled',
      setNeighbouringImagesRange: 'config/setNeighbouringImagesRange',
      setNotification: 'spherical/setNotification',
      setError: 'spherical/setError',
      clearError: 'spherical/clearError',
      clearNotification: 'spherical/clearNotification',
      addToRecentSelectedTags: 'labelTagging/addToRecentSelectedTags',
      updatePartAssemblies: 'inspectionDocument/updatePartAssemblies',
      toggleSelectedMeasurementLine: 'lineMeasurement/toggleSelectedLine',
      clearSelectedMeasurementLines: 'lineMeasurement/clearSelectedLines',
      toggleUnlabelledHighlighting: 'config/toggleUnlabelledHighlighting',
      updateAssetStatus: 'spherical/updateAssetStatus',
    }),
    handleUpdateMarkers() {
      this.setMarkers();
    },
    handleSelectMarker(id) {
      this.setSelectedMarker(id);
      this.clearSelectedMeasurementLines();
    },
    handleCtrlUpdateAnnotations(markers) {
      // on part tag mode we use update part assembly otherwise we use the update
      if (this.labelTaggingMode === LabelTaggingMode.PART && this.selectedAsset) {
        let { assemblyId, name } = this.selectedAsset;
        if (assemblyId) {
          name = this.assemblyNameById(assemblyId);
        } else {
          assemblyId = this.assemblyIdByName(name);
        }

        if (name !== 'delete') {
          const tag = {
            tagId: assemblyId,
            tagName: name,
          };

          this.addToRecentSelectedTags({ taggingMode: LabelTaggingMode.PART, tag });
          this.updatePartAssemblies({ assemblyId, markers, imageId: this.$route.query.image });
        }
      } else if (this.latestSelectedAnnotationTagId) {
        this.updateAnnotations({ markers, equipmentId: this.latestSelectedAnnotationTagId });
      }
    },
    handleAltUpdateAnnotations(markers) {
      // only enable alt click on part mode
      if (this.selectedEquipmentId && this.labelTaggingMode === LabelTaggingMode.PART) {
        this.updateAnnotations({ markers, equipmentId: this.selectedEquipmentId });
      }
    },
    handleDeleteAnnotation() {
      this.deleteAnnotation(this.selectedPolygon.annotationId);
    },
    handleUpdateCheckedLayers(payload) {
      this.updateCheckedLayers(payload);
    },
    handleUpdateLayers() {
      this.updateLayers();
    },
    handleSelectEquipment() {
      const findAsset = (assetList, target) => assetList.find(({ equipment_id }) => equipment_id === target);
      const findRelativeAsset = (assetList, tagName) => Object.values(assetList).find(({ name }) => name === tagName);

      const { equipid, tagname } = this.$route.query;
      let relativeAsset = null;

      if (equipid || tagname) {
        const tagName = tagname ? decodeURIComponent(tagname) : undefined;
        const asset = findAsset(this.imageDocument.data.assets, equipid);

        if (!asset && tagName) {
          const relativeEquipmentId = findRelativeAsset(this.assets, tagName)?._id;
          relativeAsset = findAsset(this.imageDocument.data.assets, relativeEquipmentId);
          this.setSelectedAssetAndEquipmentId(relativeEquipmentId);
          this.panoInitialEquipmentId = relativeEquipmentId;
        } else {
          this.setSelectedAssetAndEquipmentId(equipid);
          this.panoInitialEquipmentId = equipid;
        }

        const { bearing, elevation, zoom } = this.$route.query;
        const hasInitialCameraPosition = bearing || elevation || zoom;
        if (asset && !hasInitialCameraPosition) {
          this.setCameraOnValue = asset.polar.centroid;
        } else if (!asset && tagName && !hasInitialCameraPosition) {
          if (relativeAsset) {
            this.setCameraOnValue = relativeAsset.polar.centroid;
          } else {
            this.setCameraOnCorrosion = this.imageDocument.data.coverage;
          }
          deleteQueries(['tagname']);
        } else {
          this.setCameraOnCorrosion = this.imageDocument.data.coverage;
        }
      } else {
        this.setCameraOnCorrosion = this.imageDocument.data.coverage;
      }
    },
    handleClearSelectedPolygon() {
      this.clearSelectedPolygon();
    },
    async handleLoadURLQuery(options = {}) {
      this.reloadImage = false; // May need to wait until assets are reloaded
      this.reloadImageRequestNumber += 1; // Debounce reloading the image

      const { image } = this.$route.query;
      const actions = [this.loadImageDocument(image)];
      if (options.loadAssets) {
        actions.push(this.reloadAssets(image), this.getNeighbouringImages(image));
      }

      await Promise.all(actions);

      this.reloadImageRequestNumber -= 1;
      if (this.reloadImageRequestNumber === 0) {
        this.reloadImage = true;
      }
    },
    handleNeighbouringImagesRangeChange(value) {
      this.setNeighbouringImagesRange(value);
    },
    handleToggleAssetFillEnabled() {
      this.setAssetFillEnabled(!this.assetFillEnabled);
      this.setMarkers();
    },
    handleToggleHighlighReviewedEnabled() {
      this.setHighlightReviewedEnabled(!this.highlightReviewedEnabled);
      this.setMarkers();
    },
    handleSnackbarDisplay(message, type) {
      if (type === notificationType.error) {
        this.setError(message);
      } else {
        this.setNotification(message);
      }
    },
    handleSnackbarClose() {
      this.clearNotification();
      this.clearError();
    },
    handleKeyDown(key) {
      switch (key.code) {
        case 'Escape':
          this.unselectPolygon();
          break;
        case 'KeyU':
          if (key.altKey) {
            this.toggleUnlabelledHighlighting();
          }
          break;
        default:
      }
    },
    handleToggleSelectedMeasurementLine(id) {
      this.toggleSelectedMeasurementLine(id);
      this.setMarkers();
      if (this.isMeasurementLineByIdSelected(id)) {
        this.unselectPolygon();
      }
    },
    handleExportDataToCsv() {
      utils.exportTableToCSV(
        utils.equipmentCsvDataCleaner(
          this.selectedAsset.data,
          this.inspectionConfig.corrosionLayers,
          this.inspectionConfig.areaMetrics,
          this.inspectionConfig.distanceMetrics,
          this.$store.state.unit
        ),
        this.selectedAsset.data.meta['AutoCad:Tag']
      );
    },
    async handleReviewPartConfirmed({ platformId, partId, isReviewed, comment }) {
      const status = { reviewed: { value: isReviewed, comment } };
      const { error } = await PartController.updatePartStatus({ platformId, partId, status });
      if (error) {
        this.setError(get(error, 'response.data.message', 'Update Part infomation failed, please try it later'));
      } else {
        this.updateAssetStatus({ equipmentId: partId, status });
        this.setMarkers();
        this.setNotification('Operation success');
      }
    },
    async handleLoadBlister({ source, locations }) {
      this.activePanoMode = PanoMode.BLISTER;
      this.loadBlister({ source, imageId: this.$route.query.image, locations });
    },
    handleBlisterMarkerSelected({ markerId, event }) {
      if (event.ctrlKey || event.metaKey) {
        this.toggleSelectedBlisterPolygon(markerId);
      } else {
        this.activePanoMode = PanoMode.BLISTER;
        this.setActiveBlister(markerId);
      }
    },
    handleUpdateCheckedBlisterLayers(checkedLayers) {
      this.updateCheckedBlisterLayers(checkedLayers);
    },
    handleCloseBlisterHeight() {
      this.activePanoMode = PanoMode.DEFAULT;
      this.clearBlisterError();
    },
  },
};
</script>
