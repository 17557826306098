import { render, staticRenderFns } from "./EquipmentDetailWrapper.vue?vue&type=template&id=f7f81936&"
import script from "./EquipmentDetailWrapper.vue?vue&type=script&lang=js&"
export * from "./EquipmentDetailWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemContent,VListItemSubtitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/semaphore/fabric-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f7f81936')) {
      api.createRecord('f7f81936', component.options)
    } else {
      api.reload('f7f81936', component.options)
    }
    module.hot.accept("./EquipmentDetailWrapper.vue?vue&type=template&id=f7f81936&", function () {
      api.rerender('f7f81936', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/equipment/EquipmentDetailWrapper.vue"
export default component.exports