<template>
  <div id="app">
    <v-app id="inspire">
      <NavigationDrawer :open-drawer="showNavigationDrawer" @onCloseDrawer="handleCloseDrawer" />
      <v-app-bar app color="blue" dark>
        <v-app-bar-nav-icon v-if="isAuthenticated" @click.stop="handleToggleDrawer" />
        <v-toolbar-title class="clickable" @click="handleLogoClicked">
          <img class="brand-logo" src="./assets/abyss_fabric_logo_white.png" alt="Abyss Fabric" />
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-title v-if="config.whiteLabel">
          <a target="_blank" :href="config.whiteLabel.url">
            <img class="white-label-logo" :src="`./${config.whiteLabel.logo}`" alt="client logo" />
          </a>
        </v-toolbar-title>
        <v-spacer />
        <HelpMenu @triggerNotification="handleNotification" />
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
      <v-footer color="blue" app>
        <span class="white--text">
          <span @click="handleCopyIconClick">&copy;</span>
          {{ currentYear }} Abyss Solutions -
          {{ clientName || 'all trademarks are the property of their respective owners' }}
        </span>
        <v-spacer />
        <v-tooltip top>
          <template #activator="{ on }">
            <font-awesome-icon
              class="clickable white--text clipboard"
              icon="info-circle"
              :data-clipboard-text="String(config.buildVersion)"
              v-on="on"
              @click="handleBuildVersionClicked"
            />
          </template>
          <span>Build: {{ config.buildVersion }}</span>
        </v-tooltip>
      </v-footer>
      <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ClipboardJS from 'clipboard';
import config from '@/config';
import { Snackbar, notificationType, HelpMenu } from '@/components/widgets';
import { NavigationDrawer } from '@/components';
import { debugEnabled, enableDebug } from './utils';

// eslint-disable-next-line no-new
new ClipboardJS('.clipboard');

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    Snackbar,
    HelpMenu,
  },
  data() {
    return {
      showNavigationDrawer: false,
      config,
      snackBarText: '',
      snackBarType: notificationType.none,
      copyClickCounter: 0,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      error: 'auth/error',
      clientName: 'auth/clientName',
    }),
    currentYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    error(value) {
      this.snackBarText = value;
      this.snackBarType = value ? notificationType.error : notificationType.none;
    },
    '$auth.error': function authError(error) {
      if (error && !debugEnabled) {
        this.enableDebugMode();
      }
    },
  },
  created() {
    window.addEventListener('keyup', this.onKey);
    this.initialiseAuth();
  },
  methods: {
    ...mapActions({
      toggleDeveloperMode: 'config/toggleDeveloperMode',
      initialiseAuthToken: 'auth/initialiseAuthToken',
      clearError: 'auth/clearError',
    }),
    async initialiseAuth() {
      await this.initialiseAuthToken();
    },
    handleToggleDrawer() {
      this.showNavigationDrawer = !this.showNavigationDrawer;
    },
    handleCloseDrawer() {
      this.showNavigationDrawer = false;
    },
    onKey(key) {
      // @J Key binds
      switch (key.keyCode) {
        // ctrl + alt + /
        case 191:
          if (key.ctrlKey && key.altKey) this.toggleDeveloperMode();
          break;
        default:
          break;
      }
      // End
      key.preventDefault();
    },
    handleLogoClicked() {
      if (this.$route.path !== '/projects') {
        this.$router.push({ path: '/projects' });
      }
    },
    handleBuildVersionClicked() {
      this.snackBarText = 'Copied build to clipboard!';
      this.snackBarType = notificationType.message;
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
      this.clearError();
    },
    enableDebugMode() {
      this.snackBarText = 'Debug mode enabled! The browser will refresh in 3 seconds...';
      this.snackBarType = notificationType.message;

      enableDebug();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    handleNotification(message, type) {
      this.snackBarText = message;
      this.snackBarType = type;
    },
    handleCopyIconClick() {
      this.copyClickCounter += 1;
      if (this.copyClickCounter === 7) {
        this.enableDebugMode();
      }
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
</style>

<style scoped>
.clickable {
  cursor: pointer;
}

.brand-title {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: bottom;
}
.brand-logo {
  height: 20px;
  margin-right: 0.8rem;
}
.white-label-logo {
  height: 50px;
  padding-top: 4px;
}
</style>
