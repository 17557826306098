<template>
  <div>
    <Equipment
      :point-cloud-lines="pointCloudLines"
      :is-loading-resource-url="isLoadingResourceUrl"
      :failed-to-get-resource="failedToGetResource"
      :inspection-config="inspectionConfig"
      :filter-params="filterParams"
      :extra-equipment-text="extraEquipmentText"
      :initial-filters="initialFilters"
      :area-metrics="inspectionConfig.areaMetrics"
      :distance-metrics="inspectionConfig.distanceMetrics"
      :corrosion-graph-colors="mergedCorrosionColorMetrics"
      :point-cloud-referring-images="pointCloudReferringImages"
      :is-loading-point-cloud-referring-images="isLoadingPointCloudReferringImages"
      :failed-to-get-point-cloud-referring-images="failedToGetPointCloudReferringImages"
      :show-aggregate-table="showAggregateTable"
      :generate-csv-trigger="generateCsvTrigger"
      @onAggregateLineFieldClick="handleAggregateLineFieldClick"
      @onMultiLineIsometricSelected="handleMultiLineIsometricSelected"
      @onPointClicked="handlePointCloudClicked"
      @onExportTableToCsv="handleExportTableToCsv"
      @onCsvGenerated="handleCsvGenerated"
    />
    <Snackbar :type="notification" :text="errorMessage" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get, merge } from 'lodash';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import utils from '@/utils';
import MetricController from '@/controllers/MetricController';
import { Snackbar, notificationType } from '@/components/widgets';
import Equipment from './Equipment.vue';

export default {
  name: 'EquipmentContainer',
  components: {
    Equipment,
    Snackbar,
  },
  data() {
    return {
      initialFilters: undefined,
      errorMessage: undefined,
      notification: notificationType.none,
      showAggregateTable: true,
      generateCsvTrigger: false,
    };
  },
  computed: {
    ...mapGetters({
      filterParams: 'config/filterParams',
      inspectionConfig: 'config/inspectionConfig',
      extraEquipmentText: 'config/clientExtraEquipmentText',
      pointCloudLines: 'pointCloud/lines',
      isLoadingResourceUrl: 'pointCloud/isLoadingResourceUrl',
      failedToGetResource: 'pointCloud/failedToGetResource',
      assemblies: 'labelTagging/assemblies',
      loadedAssembliesInspectionId: 'labelTagging/loadedAssembliesInspectionId',
      pointCloudReferringImages: 'pointCloud/pointCloudReferringImages',
      isLoadingPointCloudReferringImages: 'pointCloud/isLoadingPointCloudReferringImages',
      failedToGetPointCloudReferringImages: 'pointCloud/failedToGetPointCloudReferringImages',
    }),
    mergedCorrosionColorMetrics() {
      const { corrosionColors, corrosionGraphColors } = this.inspectionConfig;
      return merge(corrosionColors, corrosionGraphColors);
    },
  },
  watch: {
    '$route.query.nonaggregate': {
      immediate: !true,
      handler(value, previous) {
        this.showAggregateTable = !value || value === 'false';
        // detect when browser back selected
        if (this.showAggregateTable && previous === 'true') {
          const { query } = this.$route;
          this.initialFilters = [];
          this.$nextTick(() => {
            this.updateInitialFilters(query);
          });
        }
      },
    },
  },
  async created() {
    const { query } = this.$route;

    if (query.source === 'external') {
      const { system, tag, id } = query;
      const { data, error } = await MetricController.getExternalSystem(id, system, tag);
      if (error) {
        this.handleError(error);
      } else {
        this.initialFilters = [{ key: 'meta.AutoCad:LineKey', value: get(data, 'tagName') }];
      }
    } else {
      // Needed to render the graphs when there is no source query
      this.$nextTick(() => {
        this.showAggregateTable = !query.nonaggregate || query.nonaggregate === 'false';
        this.updateInitialFilters(query);
      });
    }
    if (!this.assemblies || this.loadedAssembliesInspectionId !== query.id) {
      this.loadAssemblies(query.id);
    }
  },
  methods: {
    ...mapActions({
      loadPointCloudLines: 'pointCloud/loadLines',
      loadAssemblies: 'labelTagging/loadAssemblies',
      setPointCloudReferringImages: 'pointCloud/setPointCloudReferringImages',
    }),
    updateInitialFilters(query) {
      const filters = Object.entries(query).filter(([key]) => key !== 'id' && key !== 'nonaggregate');

      if (filters.length === 0) {
        this.initialFilters = [{}]; // Hack to load data the first time when no query param filters
      } else {
        this.initialFilters = filters.map(([key, value]) => ({
          key,
          value: decodeURIComponent(value)
            .split(',')
            .map((item) => (key === 'metrics.corrosion_category' ? utils.getNumeric(item) : item)),
        }));
      }
    },
    handleError(message) {
      this.errorMessage = message;
      this.notification = notificationType.error;
    },
    handleSnackbarClose() {
      this.notification = notificationType.none;
    },
    handleAggregateLineFieldClick(equipmentName) {
      this.loadPointCloudLines({ platformId: this.$route.query.id, equipmentNames: [equipmentName] });
    },
    handleMultiLineIsometricSelected({ lines }) {
      this.loadPointCloudLines({ platformId: this.$route.query.id, equipmentNames: lines.map(({ name }) => name) });
    },
    async handlePointCloudClicked(point) {
      this.setPointCloudReferringImages({ platformId: this.$route.query.id, assetId: point });
    },
    handleExportTableToCsv() {
      this.generateCsvTrigger = true;
    },
    handleCsvGenerated({ fields, data }) {
      const csv = Papa.unparse({ fields, data });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'export.csv');

      this.generateCsvTrigger = false;
    },
  },
};
</script>
