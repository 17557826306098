<template>
  <div class="container">
    <v-card>
      <v-tabs v-model="tab" centered icons-and-text>
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-if="showTab[tabsEnum.INFO]" href="#tab-info">
          Info
          <v-icon>mdi-information</v-icon>
        </v-tab>

        <v-tab v-if="showTab[tabsEnum.GROUPS]" href="#tab-groups">
          Groups
          <v-icon>mdi-account-multiple</v-icon>
        </v-tab>

        <v-tab v-if="showTab[tabsEnum.INVITATIONS]" href="#tab-invites">
          Invitations
          <v-icon>mdi-email</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="showTab[tabsEnum.INFO]" :key="tabsEnum.INFO" :value="'tab-info'">
          <v-card flat :loading="loading">
            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Application ID</v-list-item-title>
                  <v-list-item-subtitle>{{ applicationId }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>User ID</v-list-item-title>
                  <v-list-item-subtitle>{{ user._id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>User Roles</v-list-item-title>
                  <v-container class="py-0">
                    <v-row align="center" justify="start">
                      <v-col v-for="userRole in user.roles" :key="userRole" class="shrink">
                        <v-chip>{{ userRole }}</v-chip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>User Groups</v-list-item-title>
                  <v-container class="py-0">
                    <v-row align="center" justify="start">
                      <v-col v-for="userGroup in user.groups" :key="userGroup" class="shrink">
                        <v-chip>{{ userGroup }}</v-chip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="showTab[tabsEnum.GROUPS]" :key="tabsEnum.GROUPS" :value="'tab-groups'">
          <v-card v-if="groupManagementUserCapability" flat>
            <v-card-title>Assign Groups to Users</v-card-title>
            <GroupSelector
              :items="users"
              :groups="groups"
              :search-fields="['email', 'name']"
              id-field="user_id"
              text-field="name"
              subtext-field="email"
              image-field="picture"
              selection-name="Users"
              @attach="handleAttachUsers"
              @detach="handleDetachUsers"
            />
          </v-card>
          <v-divider />
          <v-spacer />
          <v-card v-if="groupManagementInspectionCapability" flat>
            <v-card-title>Assign Groups to Inspections</v-card-title>
            <GroupSelector
              :items="inspections"
              :groups="groups"
              :search-fields="['id', 'name']"
              id-field="id"
              text-field="name"
              subtext-field="id"
              image-field="image"
              selection-name="Inspections"
              @attach="handleAttachInspections"
              @detach="handleDetachInspections"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="showTab[tabsEnum.INVITATIONS]" :key="tabsEnum.INVITATIONS" :value="'tab-invites'">
          <InviteListBuilder :roles="roles" :groups="groups" :connections="connections" @send="handleInviteSend" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
  </div>
</template>

<script>
import { GroupSelector, InviteListBuilder } from '@/components/settings';
import { Snackbar, notificationType } from '@/components/widgets';
import ClientController from '@/controllers/ClientController';

const tabsEnum = {
  INFO: 0,
  GROUPS: 1,
  INVITATIONS: 2,
};

export default {
  name: 'SettingsView',

  components: {
    Snackbar,
    GroupSelector,
    InviteListBuilder,
  },

  props: {
    inspections: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    applicationId: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    users: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    connections: {
      type: Array,
      default: () => [],
    },
    groupManagementUserCapability: {
      type: Boolean,
      default: false,
    },
    groupManagementInspectionCapability: {
      type: Boolean,
      default: false,
    },
    sendInvitationCapability: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    tabsEnum,
    snackBarText: '',
    snackBarType: notificationType.none,
    tab: null,
  }),

  computed: {
    showTab() {
      return {
        [tabsEnum.INFO]: true,
        [tabsEnum.GROUPS]: this.groupManagementUserCapability || this.groupManagementInspectionCapability,
        [tabsEnum.INVITATIONS]: this.sendInvitationCapability,
      };
    },
  },

  methods: {
    handleError(error) {
      this.snackBarText = error;
      this.snackBarType = notificationType.error;
    },
    handleNotification(message) {
      this.snackBarText = message;
      this.snackBarType = notificationType.message;
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
    async handleRequests(requests, success, error) {
      try {
        this.$emit('loading', true);
        const responses = await Promise.all(requests);
        if (responses.every((statusCode) => statusCode === 200)) {
          this.handleNotification(success);
        } else {
          this.handleError(error);
        }
      } catch (error) {
        this.handleError(error.message);
      } finally {
        this.$emit('loading', false);
      }
    },
    async handleAttachUsers(users, groups) {
      const requests = groups.reduce(
        (acc, { _id: groupId }) =>
          acc.concat(...users.map(({ user_id: userId }) => ClientController.attachGroupUser(userId, groupId))),
        []
      );
      const success = 'Successfully attached users to groups';
      const error = 'Error attaching users to groups';
      await this.handleRequests(requests, success, error);
    },
    async handleDetachUsers(users, groups) {
      const requests = groups.reduce(
        (acc, { _id: groupId }) =>
          acc.concat(...users.map(({ user_id: userId }) => ClientController.detachGroupUser(userId, groupId))),
        []
      );
      const success = 'Successfully detached users from groups';
      const error = 'Error detaching users from groups';
      await this.handleRequests(requests, success, error);
    },
    async handleAttachInspections(inspections, groups) {
      const platformIds = inspections.map(({ id }) => id);
      const requests = groups.map(({ _id: groupId }) => ClientController.attachGroupPlatforms(platformIds, groupId));
      const success = 'Successfully attached inspections to groups';
      const error = 'Error attaching inspections to groups';
      await this.handleRequests(requests, success, error);
    },
    async handleDetachInspections(inspections, groups) {
      const requests = groups.reduce(
        (acc, { _id: groupId }) =>
          acc.concat(
            ...inspections.map(({ id: platformId }) => ClientController.detachGroupPlatform(platformId, groupId))
          ),
        []
      );
      const success = 'Successfully detached inspections from groups';
      const error = 'Error detaching inspections from groups';
      await this.handleRequests(requests, success, error);
    },
    async handleInviteSend({ connection, roles, groups, emails }) {
      try {
        this.$emit('loading', true);
        const { userCreated = [], emailSent = [] } = await ClientController.sendUserInvites(
          this.applicationId,
          connection,
          roles,
          groups,
          emails
        );
        const userCount = userCreated.filter((status) => status === true).length;
        const emailCount = emailSent.filter((status) => status === true).length;
        const message = `Sucessfully created ${userCount} new accounts and sent ${emailCount} emails`;
        this.handleNotification(message);
      } catch (error) {
        this.handleError(error.message);
      } finally {
        this.$emit('loading', false);
      }
    },
  },
};
</script>
