var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-4" },
    [
      _c(
        "v-row",
        { staticClass: "grey lighten-5" },
        [
          _c("TableHeader", {
            attrs: { "current-selection-info": _vm.tableHeader }
          })
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-card",
                [
                  _c("DataTable", {
                    attrs: {
                      headers: _vm.headers,
                      data: _vm.filteredData,
                      unit: _vm.$store.state.unit,
                      "primary-key": "image_id"
                    },
                    on: { onTableCellClick: _vm.handleTableCellClick }
                  }),
                  _c("CloseFilterTableData", {
                    attrs: {
                      options: _vm.options,
                      "initial-selected": _vm.selected,
                      data: _vm.imagesData,
                      "filter-on": "corrosion_state"
                    },
                    on: {
                      onFilterChange: _vm.handleFilterChange,
                      onCloseFilterTableData: _vm.handleCloseFilterTableData
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel"
                  }
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Equipment Condition")
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        { staticClass: "innerExPan" },
                        [
                          _c("EquipmentInfo", {
                            attrs: {
                              "equipment-data": _vm.data,
                              "called-by-spherical-view":
                                _vm.calledBySphericalView,
                              "modal-view": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Assembly Condition")
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        { staticClass: "innerExPan" },
                        [
                          _c("EquipmentDetailWrapper", {
                            attrs: { filter: _vm.data.name }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.calledByMultiLineIsometrics
                    ? _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Selected Assemblies Condition")
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "innerExPan" },
                            [
                              _c("EquipmentDetailWrapper", {
                                attrs: { filter: _vm.multiSelectedEquipments }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }