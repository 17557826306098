<template>
  <div v-if="activeFilters.length > 0">
    Active Filter {{ aggregated ? '(Aggregated)' : '' }}
    <span v-for="filter in activeFilters" :key="filter.key">
      <v-chip class="ma-2" close color="secondary" label outlined @click:close="handleClose(filter.key)">
        {{ formatFilter(filter) }}
      </v-chip>
    </span>
  </div>
</template>

<script>
import get from 'lodash/get';

const CORROSION_STATES = ['Clean', 'Light', 'Moderate', 'Heavy'];

export default {
  name: 'ActiveFiltersDisplay',
  props: {
    aggregated: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    filterParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    activeFilters() {
      return this.filters.filter(({ key, value }) => key !== 'undefined' && value != null);
    },
  },
  methods: {
    formatFilter({ key, value }) {
      const displayMultiple = (items) => (items.length > 1 ? `${items.length} items selected` : items[0]);

      if (key === 'metrics.corrosion_category') {
        return `Corrosion Category: ${displayMultiple(value.map((index) => CORROSION_STATES[index]))}`;
      }
      return `${get(this, ['filterParams', key, 'displayName'], '')}: ${displayMultiple(value)}`;
    },
    handleClose(filter) {
      this.$emit('onClearFilter', filter);
    },
  },
};
</script>
