<template>
  <v-container fluid fill-height style="display: flex; flex-direction: column; padding: 0; align-content: baseline">
    <div style="width: 300px; height: 100%">
      <v-navigation-drawer permanent="permanent" absolute="" width="300px">
        <v-tabs v-model="tabs" background-color="blue accent-4" class="elevation-2" dark centered icons-and-text>
          <v-tab>
            Info
            <v-icon>mdi-information-outline</v-icon>
          </v-tab>
          <v-tab>
            Layers
            <v-icon>mdi-layers-triple</v-icon>
          </v-tab>
          <v-tab>
            Config
            <v-icon>mdi-cog</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-row>
            <v-col>
              <v-tab-item>
                <v-card class="mx-auto">
                  <v-card-text>
                    <div>Equipment Information</div>
                  </v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Image Name</v-list-item-title>
                      <v-list-item-subtitle>{{ formatImageNameForDisplay(pano.imageName) }}</v-list-item-subtitle>
                      <hr />
                      <EquipmentDetails :equipment-data="selectedAsset" class="equipment-details" />
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header class="rig">Assembly Condition</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <EquipmentDetailWrapper :filter="selectedAsset.name" />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <ImageDocumentDetails :details="imageDocument && imageDocument.details" />
                      <OverLappingSelector :pano="pano" :platform-id="platformId" />
                      <v-btn v-if="equipmentDataLoaded" @click="info.referDialog.visible = !info.referDialog.visible">
                        {{ numImages }} Images
                      </v-btn>
                      <!-- Button click below only used to display the snackbar with text and type -->
                      <v-btn
                        v-if="equipmentDataLoaded && developerMode"
                        class="clipboard"
                        :data-clipboard-text="JSON.stringify(selectedAsset.data)"
                        @click="handleCopyToClipboardClicked"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                        Equipment Data
                      </v-btn>
                      <v-btn v-if="equipmentDataLoaded" class="clipboard" @click="handleExportData()">
                        <v-icon>mdi-export</v-icon>
                        Export Data
                      </v-btn>
                      <!-- Button click below only used to display the snackbar with text and type -->
                      <v-btn
                        class="clipboard"
                        :data-clipboard-text="computeClipboard"
                        @click="handleCopyToClipboardClicked"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                        Copy Link
                      </v-btn>
                      <PartForm
                        v-if="equipmentDataLoaded"
                        :asset-id="selectedAsset._id"
                        :asset-name="selectedAsset.data.meta['AutoCad:LineKey']"
                        :asset-class="selectedAsset.data.meta['AutoCad:Class']"
                        :corrosion-data="corrosionData"
                        :bearing="pano.bearing"
                        :elevation="pano.elevation"
                        :zoom="pano.zoom"
                        :display-snackbar="displaySnackbar"
                        :image-name="formatImageNameForDisplay(pano.imageName)"
                        :inspection-id="platformId"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="equipmentDataLoaded">
                    <v-list-item-content>
                      <ReviewPartButton :isReviewed="isReviewed" @onClick="handleReviewPartClicked" />
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-tab-item>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-tab-item>
                <v-list class="overflow-y-auto">
                  <LayerListGroup
                    initial-expand
                    name="Corrosion"
                    :aggregation="defectLayers"
                    :layers="checkedLayers.defects"
                    :coverage="pano.coverage"
                    color="blue"
                    @onLayerChange="handleCheckedLayerChange('defects', $event)"
                  />
                  <LayerListGroup
                    name="Equipment"
                    :aggregation="assetLayers"
                    :layers="checkedLayers.assets"
                    :coverage="pano.coverage"
                    color="blue"
                    @onLayerChange="handleCheckedLayerChange('assets', $event)"
                  />
                  <LayerListGroup
                    v-if="line.points.length > 0"
                    initial-expand
                    name="Line"
                    :aggregation="computedLines"
                    :layers="checkedLayers.lines"
                    :coverage="pano.coverage"
                    color="red"
                    @onLayerChange="handleCheckedLayerChange('lines', $event)"
                  />
                  <LayerListGroup
                    v-if="blisterLayers.length > 0"
                    initial-expand
                    name="Blisters"
                    :aggregation="aggregatedBlisters"
                    :layers="checkedBlisterLayers"
                    :coverage="pano.coverage"
                    color="brown"
                    @onLayerChange="handleBlisterLayerCheckedChange"
                  />
                </v-list>
              </v-tab-item>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tab-item>
                <v-list>
                  <TogglerListItem
                    title="Fill Equipment"
                    :input="assetFillEnabled"
                    @toggle="handleToggleAssetFillEnabled"
                  />
                  <TogglerListItem
                    title="Highlight Reviewed"
                    :input="highlightReviewedEnabled"
                    @toggle="handleToggleHighlightReviewedEnabled"
                  />
                  <HighlightStyleSwitcher />
                  <v-list-item>
                    <Slider
                      label="Image Range"
                      :range="neighbouringImagesMaxRange"
                      :initial-value="neighbouringImagesRange"
                      @on-slider-changed="handleNeighbouringImagesRangeChange"
                    />
                  </v-list-item>
                  <v-list-item>
                    <StrokeWidthSlider />
                  </v-list-item>
                </v-list>
              </v-tab-item>
            </v-col>
          </v-row>
        </v-tabs-items>
      </v-navigation-drawer>
    </div>
    <div class="sticky-pano-viewer">
      <v-breadcrumbs :items="breadcrumbLinks" />
      <PanoViewer
        v-if="pano.render"
        :pano="pano"
        :pano-mode="activePanoMode"
        :markers="markers"
        :neighbouring-images="neighbouringImages"
        :line="line"
        :checked-lines="checkedLayers.lines"
        :checked-blister-polygons="checkedBlisterPolygons"
        :inspection-config="inspectionConfig"
        :display-snackbar="displaySnackbar"
        @onMarkerSelected="handleMarkerSelected"
        @onMarkerUnselected="handleMarkerUnselected"
        @onMarkerUpdated="handleMarkerUpdated"
        @onLinesUpdated="handleLineUpdated"
        @onPositionUpdated="handlePanoPositionUpdated"
        @onBlisterHeightSelected="handleBlisterHeightSelected"
      />
      <Compass
        v-if="inspectionConfig.platformFeatures.showCompass"
        class="compass"
        :offset="bearingOffset"
        :bearing="pano.bearing"
      />
      <v-tooltip top>
        <template #activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-show="neighbourHistory.length"
              absolute
              dark
              fab
              bottom
              right
              color="pink"
              class="mb-10 undo-button"
              v-on="on"
              @click="undoWalkthrough"
            >
              <v-icon>mdi-undo-variant</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <span>Go back</span>
      </v-tooltip>
    </div>
    <v-dialog v-model="info.referDialog.visible" max-width="1200" :retain-focus="false">
      <ReferringImagesTable called-by-spherical-view :data="selectedAsset" @onClose="showReferringImagesTable" />
    </v-dialog>
    <v-dialog :value="showBlisterHeights" max-width="1200" :retain-focus="false" @input="handleHideBlisterHeight">
      <v-card>
        <v-card-title>
          <span>Blister Height Analysis</span>
          <v-spacer />
          <v-card-actions>
            <v-btn icon @click="handleHideBlisterHeight">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-title>
        <v-card-text>
          <!-- dispose Ploty using v-if to free up resources when BlisterHeights closed -->
          <BlisterHeights
            v-if="showBlisterHeights"
            :unit="numericUnit"
            :error="blisterError"
            :loading="blisterLoading"
            :blister-data="blisterData"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showReviewPartConfirmation" max-width="800" :retain-focus="false">
      <ReviewPartConfirmation
        :isReviewed="isReviewed"
        :comment="reviewPartComment"
        @onChange="handleReviewPartText"
        @onClick="handleReviewPartConfirmation"
      />
    </v-dialog>
    <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
  </v-container>
</template>

<script>
import { isEmpty, get, debounce } from 'lodash';
import TogglerListItem from '@/components/widgets/TogglerListItem.vue';
import { ReviewPartButton, ReviewPartConfirmation } from '@/react/features/ReviewPart';
import { OverLappingSelector } from '@/components/overlapping';
import { updateQueries } from '@/utils/queryActions';
import utils, {
  queryStringBuilder,
  formatDistanceMeasurement,
  formatMillimeterDistanceMeasurement,
  PanoMode,
} from '@/utils';
import { ReviewPartStatus } from '@/utils/enums';
import {
  PanoViewer,
  EquipmentDetails,
  PartForm,
  ReferringImagesTable,
  ImageDocumentDetails,
  StrokeWidthSlider,
  HighlightStyleSwitcher,
  Compass,
  LayerListGroup,
} from '@/components';
import { Snackbar, notificationType, Slider } from '@/components/widgets';
import { BlisterHeights } from '@/react';
import EquipmentDetailWrapper from '../components/equipment/EquipmentDetailWrapper.vue';

const DEBOUNCE_UPDATE_ANNOTATIONS_TIME = 1500;

export default {
  name: 'SphericalView',
  components: {
    PanoViewer,
    TogglerListItem,
    ReferringImagesTable,
    EquipmentDetails,
    PartForm,
    Snackbar,
    ImageDocumentDetails,
    OverLappingSelector,
    StrokeWidthSlider,
    HighlightStyleSwitcher,
    Slider,
    Compass,
    ReviewPartButton,
    ReviewPartConfirmation,
    BlisterHeights,
    LayerListGroup,
    EquipmentDetailWrapper,
  },
  props: {
    activePanoMode: {
      type: String,
      default: PanoMode.DEFAULT,
    },
    numericUnit: {
      type: String,
      default: null,
    },
    // Move to PanoViewerContainer
    inspectionConfig: {
      type: Object,
      default: () => ({}),
    },
    assetFillEnabled: {
      type: Boolean,
      default: false,
    },
    highlightReviewedEnabled: {
      type: Boolean,
      default: false,
    },
    strokeWidth: {
      type: Number,
      default: 0,
    },
    developerMode: {
      type: Boolean,
      default: false,
    },
    snackBarType: {
      type: String,
      default: '',
    },
    snackBarText: {
      type: String,
      default: '',
    },
    selectedEquipmentId: {
      type: String,
      default: null,
    },
    assets: {
      type: Object,
      default: () => ({}),
    },
    checkedLayers: {
      type: Object,
      default: () => ({}),
    },
    platformName: {
      type: String,
      default: '',
    },
    platformId: {
      type: String,
      default: '',
    },
    selectedAsset: {
      type: Object,
      default: () => ({}),
    },
    defectLayers: {
      type: Array,
      default: () => [],
    },
    assetLayers: {
      type: Array,
      default: () => [],
    },
    imageDocument: {
      type: Object,
      default: () => ({}),
    },
    reloadImage: {
      type: Boolean,
      default: false,
    },
    selectedPolygon: {
      type: Object,
      default: () => ({}),
    },
    markers: {
      type: Array,
      default: () => [],
    },
    isMeasurementLineByIdSelected: {
      type: Function,
      default: () => false,
    },
    corrosionData: {
      type: Object,
      default: () => ({}),
    },
    neighbouringImages: {
      type: Array,
      default: () => [],
    },
    neighbouringImagesMaxRange: {
      type: Number,
      default: 10,
    },
    neighbouringImagesRange: {
      type: Number,
      default: 10,
    },
    panoInitialEquipmentId: {
      type: String,
      default: null,
    },
    setCameraOnCorrosion: {
      type: Array,
      default: () => [],
    },
    setCameraOnValue: {
      type: Array,
      default: () => [],
    },
    blisterError: {
      type: String,
      default: null,
    },
    blisterLoading: {
      type: Boolean,
      default: true,
    },
    blisterData: {
      type: Object,
      default: () => ({}),
    },
    blisterLayers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      info: {
        equipmentData: {},
        referDialog: {
          visible: false,
        },
        partForm: {
          visible: false,
        },
      },
      pano: {
        imageName: '',
        source: '',
        markers: [],
        bearing: 0,
        elevation: 0,
        zoom: 0,
        highlightedPoly: {
          id: '',
          lineAssetIds: new Set(),
          annotationId: null,
        },
        render: false,
        initial: {
          bearing: 0,
          elevation: 0,
          zoom: 0,
          markerId: '',
          equipmentId: null,
        },
      },
      breadCrumbText: '',
      bearingOffset: 0,
      tabs: 0,
      tabsEnum: {
        info: 0,
        layers: 1,
        config: 2,
      },
      urlSnap: {
        imagePath: '',
        inspection_uuid: '',
      },
      neighbourHistory: [],
      line: {
        points: [], // lines in pano viewer
        measurements: [], // measurements for lines
      },
      selectedCtrlMarkers: new Set(),
      selectedAltMarkers: new Set(),
      showReviewPartConfirmation: false,
      reviewPartComment: '',
    };
  },
  computed: {
    breadcrumbLinks() {
      return [
        {
          text: this.platformName,
          disabled: false,
          to: `/inspection?id=${this.platformId}`,
        },
        {
          text: this.breadCrumbText,
          disabled: true,
        },
      ];
    },
    computedLines() {
      return this.line.points.map((l, idx) => {
        let formatDistance = '';
        if (this.line.measurements[idx] !== undefined) {
          formatDistance = formatDistanceMeasurement({
            meters: this.line.measurements[idx],
            unit: this.$store.state.unit,
            displayUnit: true,
          });
        }

        return {
          name: `line_${idx}`,
          friendlyName: `line_${idx} ${formatDistance}`,
          color: 'green',
        };
      });
    },
    aggregatedBlisters() {
      const formatHeight = (meters) =>
        formatMillimeterDistanceMeasurement({
          meters,
          unit: this.$store.state.unit,
        });

      return this.blisterLayers.map(({ blisterId, maxHeight }) => ({
        name: blisterId,
        friendlyName: `${blisterId} ${formatHeight(maxHeight)}`,
        color: 'orange',
      }));
    },
    checkedBlisterLayers() {
      return this.blisterLayers.reduce((layers, { blisterId, checked }) => layers.concat(checked ? blisterId : []), []);
    },
    numImages() {
      return get(this.selectedAsset, 'data.referring_images.length', 0);
    },
    corrosionSet() {
      return new Set(this.defectLayers.map((e) => e.name));
    },
    equipmentSet() {
      return new Set(this.assetLayers.map((e) => e.name));
    },
    computeClipboard() {
      const queryParameters = {
        id: this.$route.query.id,
        path: this.urlSnap.imagePath,
        inspection_uuid: this.urlSnap.inspection_uuid,
        bearing: this.pano.bearing.toFixed(3),
        elevation: this.pano.elevation.toFixed(3),
        zoom: this.pano.zoom.toFixed(3),
        equipid: this.$route.query.equipid,
      };
      const queryString = queryStringBuilder(queryParameters);
      return `${window.location.protocol}//${window.location.host}/spherical?${queryString}`;
    },
    equipmentDataLoaded() {
      return !isEmpty(this.selectedAsset);
    },
    isReviewed() {
      return get(this.selectedAsset, ['status', 'reviewed', 'value'], false);
    },
    checkedBlisterPolygons() {
      return this.blisterLayers.filter(({ checked }) => checked);
    },
    showBlisterHeights() {
      return this.activePanoMode === PanoMode.BLISTER;
    },
  },
  watch: {
    selectedPolygon(value) {
      this.switchTabTo(this.tabsEnum.info);
      this.pano.highlightedPoly = value;
    },
    // update line markers and tooltips on unit switch
    '$store.state.unit': function unitWatcher() {
      this.handleMarkerUpdated();
    },
    '$route.query': function routeQueryWatcher(current, previous) {
      if (current.image !== previous.image) {
        this.showReferringImagesTable(false);
        this.$emit('onLoadURLQuery', { loadAssets: true });
      } else if (current.id !== previous.id) {
        this.$emit('onLoadURLQuery');
      }
      document.title = ['Abyss Fabric Web'].concat(this.breadcrumbLinks.map(({ text }) => text)).join(' | ');
    },
    strokeWidth() {
      this.$emit('onUpdateMarkers');
    },
    reloadImage(value) {
      if (value) {
        this.loadImage(this.imageDocument);
      }
    },
    panoInitialEquipmentId(value) {
      this.pano.initial.equipmentId = value;
    },
    setCameraOnCorrosion(value) {
      const cameraDirectionUpdated = this.updateInitialCameraDirectionByQuery(this.$route.query);
      if (!cameraDirectionUpdated) {
        this.focusOnHighestCorrosion(value);
      }
    },
    setCameraOnValue(value) {
      this.alignCameraPosition(value);
    },
  },
  created() {
    window.addEventListener('keyup', this.onKey);
  },
  destroyed() {
    window.removeEventListener('keyup', this.onKey);
  },
  methods: {
    updateURL: debounce(({ bearing, elevation, zoom }) => {
      updateQueries(['bearing', 'elevation', 'zoom'], [bearing, elevation, zoom]);
    }, 300),

    formatImageNameForDisplay: utils.formatImageNameForDisplay,

    handleCheckedLayerChange(layer, layerStatus) {
      this.$emit('onUpdateCheckedLayers', { layer, layerStatus });
    },
    handleBlisterLayerCheckedChange(checkedItems) {
      this.$emit('onUpdateCheckedBlisterLayers', checkedItems);
      this.$emit('onUpdateMarkers');
    },
    undoWalkthrough() {
      const image = this.neighbourHistory.pop();
      if (image) {
        this.imageClicked(image, false);
        this.$emit('onLoadURLQuery', { loadAssets: true });
      }
    },
    handleExportData() {
      this.$emit('onExportData');
    },
    showReferringImagesTable(params) {
      this.info.referDialog.visible = params;
    },
    toggleAll(aggregation, layers) {
      return aggregation.length > layers.length ? aggregation.map(({ name }) => name) : [];
    },
    onKey(key) {
      // @J Key binds
      switch (key.code) {
        case 'KeyC': // alt + c
          if (key.altKey) {
            this.$emit('onUpdateCheckedLayers', {
              layer: 'defects',
              layerStatus: this.toggleAll(this.defectLayers, this.checkedLayers.defects),
            });
          }
          break;
        case 'KeyA': // alt + a
          if (key.altKey) {
            this.$emit('onUpdateCheckedLayers', {
              layer: 'assets',
              layerStatus: this.toggleAll(this.assetLayers, this.checkedLayers.assets),
            });
          }
          break;
        case 'Delete':
        case 'Backspace':
          this.$emit('onDeleteAnnotation');
          break;
        default:
          break;
      }
      // End
      key.preventDefault();
    },
    updateInitialCameraDirectionByQuery({ bearing, elevation, zoom }) {
      let updated = false;
      let { isNumeric, value } = utils.checkNumeric(bearing);
      if (isNumeric) {
        this.pano.initial.bearing = value;
        updated = true;
      }
      ({ isNumeric, value } = utils.checkNumeric(elevation));
      if (isNumeric) {
        this.pano.initial.elevation = value;
        updated = true;
      }
      ({ isNumeric, value } = utils.checkNumeric(zoom));
      if (isNumeric) {
        this.pano.initial.zoom = value;
      }

      return updated;
    },
    focusOnHighestCorrosion(coverage, priority = ['AC-H', 'AC-M', 'AC-L']) {
      let found = null;
      let index = 0;
      while (!found && index < priority.length) {
        // eslint-disable-next-line no-loop-func
        const filter = coverage.filter(({ type }) => type === priority[index]);
        if (filter.length > 0) {
          const maxArea = Math.max(...filter.map(({ metrics: { area } }) => area));
          found = filter.find(({ metrics: { area } }) => area === maxArea);
        }
        index += 1;
      }

      const [bearing, elevation] = found?.polar?.centroid ?? [0, 0];
      this.pano.initial.bearing = +bearing;
      this.pano.initial.elevation = -elevation;
    },
    loadImage(imageDocument) {
      this.urlSnap.imagePath = imageDocument.name;
      this.urlSnap.inspection_uuid = imageDocument.inspection_uuid;
      this.breadCrumbText = utils.formatImageNameForDisplay(imageDocument.name);
      this.bearingOffset = Math.PI - (imageDocument.pose?.yaw ?? 0); // Needed for how the spherical bearing works compared to coordinates

      this.$emit('onSelectEquipment');

      this.pano.source = imageDocument.data.resource;
      [this.pano.imageName] = this.pano.source.split('/').slice(-1);

      this.pano.coverage = get(imageDocument, 'data.metrics.coverage');

      this.populateLayers(imageDocument);
    },
    sectorClicked(angle) {
      this.pano.bearing = angle;
    },
    alignCameraPosition(centroid) {
      this.pano.initial.bearing = +centroid[0];
      this.pano.initial.elevation = -centroid[1];
      // align pano information
      this.pano.bearing = +centroid[0];
      this.pano.elevation = -centroid[1];
    },
    populateLayers() {
      this.$emit('onUpdateLayers');
      if (this.$route.query.nolayers) {
        this.$emit('onUpdateCheckedLayers', {
          layer: 'defects',
          layerStatus: [],
        });
        this.$emit('onUpdateCheckedLayers', {
          layer: 'assets',
          layerStatus: [],
        });
      } else {
        this.$emit('onUpdateCheckedLayers', {
          layer: 'assets',
          layerStatus: this.assetLayers.map(({ name }) => name),
        });
      }

      this.pano.render = true;
    },
    handleMarkerSelected({ marker, event }) {
      if (marker.id === undefined) {
        return;
      }

      const [startsWith, target] = marker.id.split('_');
      switch (startsWith) {
        case 'neighbour': // handle walkthrough icon click
          this.imageClicked(target);
          break;
        case 'line': {
          this.$emit('onToggleSelectedMeasurementLine', target);
          const status = this.isMeasurementLineByIdSelected(target);
          const snackbarMessage = status ? 'Selected annotation (DEL to delete)' : 'Unselected annotation';
          this.displaySnackbar(snackbarMessage);
          this.switchTabTo(this.tabsEnum.layers);
          break;
        }
        case 'lineend': // handle line annotation end click
          this.$emit('onToggleSelectedMeasurementLine', target);
          this.switchTabTo(this.tabsEnum.layers);
          break;
        case 'blister':
          this.$emit('onBlisterMarkerSelected', { markerId: marker.id, event });
          this.switchTabTo(this.tabsEnum.layers);
          break;
        default:
          // Ctrl+click functionality only for internal use - no need to support Mac
          if (event?.ctrlKey) {
            this.selectedCtrlMarkers.add(marker.id);
            this.updateCtrlAnnotations(this.selectedCtrlMarkers);
          } else if (event?.altKey) {
            this.selectedAltMarkers.add(marker.id);
            this.updateAltAnnotations(this.selectedAltMarkers);
          } else {
            this.$emit('onSelectMarker', marker.id);
          }
      }
    },
    updateCtrlAnnotations: debounce(function updateCtrlAnnotations(selectedMarkers) {
      this.$emit('onCtrlUpdateAnnotations', Array.from(selectedMarkers));
      selectedMarkers.clear();
    }, DEBOUNCE_UPDATE_ANNOTATIONS_TIME),
    updateAltAnnotations: debounce(function updateAltAnnotations(selectedMarkers) {
      this.$emit('onAltUpdateAnnotations', Array.from(selectedMarkers));
      selectedMarkers.clear();
    }, DEBOUNCE_UPDATE_ANNOTATIONS_TIME),
    handleMarkerUnselected() {
      this.$emit('onClearSelectedPolygon');
    },
    handleMarkerUpdated() {
      this.$emit('onUpdateMarkers');
    },
    handleToggleAssetFillEnabled() {
      this.$emit('onToggleAssetFillEnabled');
    },
    handleToggleHighlightReviewedEnabled() {
      this.$emit('onToggleHighlighReviewedEnabled');
    },
    handleLineUpdated(line) {
      this.line = line;
      this.$emit('onUpdateCheckedLayers', {
        layer: 'lines',
        layerStatus: this.line.points.map((_, index) => `line_${index}`),
      });
      this.switchTabTo(this.tabsEnum.layers);
    },
    handlePanoPositionUpdated({ longitude: bearing, latitude: elevation, zoom }) {
      this.pano.bearing = bearing;
      this.pano.elevation = elevation;
      this.pano.zoom = zoom;
      this.updateURL({ bearing, elevation, zoom });
    },
    handleBlisterHeightSelected(locations) {
      this.$emit('onLoadBlister', { source: this.pano.source, locations });
      this.$emit('onUpdateMarkers');
      this.switchTabTo(this.tabsEnum.layers);
    },
    handleHideBlisterHeight() {
      this.$emit('onCloseBlisterHeight');
    },
    imageClicked(imageId, pushHistory = true) {
      if (pushHistory && this.$route.query.image) {
        this.neighbourHistory.push(this.$route.query.image);
      }
      // eslint-disable-next-line no-unused-vars
      const { bearing, elevation, zoom, ...queryWithoutCameraInfo } = this.$route.query;
      this.$router.replace({ query: { ...queryWithoutCameraInfo, image: imageId } });
    },
    switchTabTo(tab) {
      this.tabs = tab;
    },
    handleReviewPartClicked() {
      this.showReviewPartConfirmation = true;
    },
    handleReviewPartConfirmation(confirmation) {
      switch (confirmation) {
        case ReviewPartStatus.REVIEW_PART:
          this.$emit('onReviewPartConfirmed', {
            platformId: this.platformId,
            partId: this.selectedAsset._id,
            isReviewed: true,
            comment: this.reviewPartComment,
          });
          this.reviewPartComment = '';
          break;
        case ReviewPartStatus.CANCEL_REVIEW:
          this.$emit('onReviewPartConfirmed', {
            platformId: this.platformId,
            partId: this.selectedAsset._id,
            isReviewed: false,
            comment: this.reviewPartComment,
          });
          this.reviewPartComment = '';
          break;
        default:
          break;
      }
      this.showReviewPartConfirmation = false;
    },
    handleReviewPartText(text) {
      this.reviewPartComment = text;
    },
    handleCopyToClipboardClicked() {
      this.displaySnackbar('Copied to clipboard!');
    },
    handleNeighbouringImagesRangeChange(value) {
      this.$emit('onNeighbouringImagesRangeChange', value);
    },
    handleReviewPartError(message) {
      this.displaySnackbar(message, notificationType.error);
    },
    displaySnackbar(message, type) {
      this.$emit('onSnackbarDisplay', message, type);
    },
    handleSnackbarClose() {
      this.$emit('onSnackbarClose');
    },
  },
};
</script>

<style scoped lang="less">
.rig {
  padding-left: 5px;
}

.layers {
  span {
    min-width: 100%;
    display: inline-block;
  }
}

.panoview {
  margin-top: 15px;
  min-height: 80vh;
}

.rad-indicator {
  padding: 10%;
}

.widget {
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 15px;
  border-radius: 5px;
}

.sidebar {
  padding: 10px;
}

.info-card {
  max-height: 30vh;
  overflow-y: scroll;
}

.sticky-pano-viewer {
  padding: 1px;
  width: calc(100% - 300px);
  height: 100%;
  position: sticky;
  top: 45px;
}

.undo-button {
  z-index: 10000;
}

.equipment-details {
  overflow: hidden;
}

.compass {
  position: absolute;
  right: 0;
  top: 4.5rem;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-expansion-panel-content::v-deep .v-list-item {
  padding: 0 !important;
}
</style>
