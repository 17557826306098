<template>
  <v-data-table
    v-model="selectedRows"
    :headers="formattedHeaders"
    :items="formattedItems"
    :item-key="primaryKey"
    class="data-table elevation-1"
    calculate-widths
    :items-per-page="itemsPerPage"
    :footer-props="{
      'items-per-page-options': [5, 10, 20, 30, 40, 50, -1],
    }"
    :show-select="selectable"
    dense
    :loading="loading"
    :loading-text="loadingText"
    :sort-by="sortBy"
    :sort-desc="sortDescending"
    :custom-sort="sort"
    @input="handleSelectedRowsChanged"
  >
    <template #top>
      <slot name="toolbar" />
    </template>
    <template #body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item[primaryKey]" @click="handleRowClick(item)">
          <td v-if="selectable">
            <v-checkbox
              v-model="selectedRows"
              :value="item"
              class="selectable-table-cell"
              hide-details
              @click.prevent.stop
            />
          </td>
          <td v-for="(header, index) in formattedHeaders" :key="index" :class="alignClass(header.align)">
            <Button
              v-if="header.type === 'button'"
              size="small"
              variant="outlined"
              :disabled="header.disableOn(header.value, item)"
              class="table-cell-button"
              @onClick.stop.prevent="handleTableCellClick(header.value, item, $event)"
            >
              {{ formatButtonField(header, item) }}
            </Button>
            <a
              v-else-if="header.type === 'link'"
              class="table-cell-link"
              @click.stop.prevent="handleTableCellClick(header.text, item, $event)"
            >
              {{ item[header.value] || header.defaultValue }}
            </a>
            <SplitButton
              v-else-if="header.type === 'dropdown'"
              :options="header.typeConfig.options"
              @onSelected="handleSelectedTableCellClick(header.value, item, $event)"
            />
            <component :is="header.component" v-else-if="header.component" v-bind="header" :item="item" />
            <span v-else>
              {{ formatField(header, item) }}
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import {
  MeasurementUnit,
  flattenHeaderValues,
  formatMeasurementHeaders,
  formatMeasurementFields,
  formatCustomFields,
  orderImperialDistances,
  orderDates,
  applyStartsWithSort,
  applyNumericalSort,
} from '@/utils';
import { Button, SplitButton } from '@/react';

export default {
  name: 'DataTable',
  components: {
    Button,
    SplitButton,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    primaryKey: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: null,
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDescending: {
      type: Array,
      default: () => [],
    },
    // Hack for when CSV generation required
    generateCsvTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRows: [],
      nonSortableColumns: ['button', 'dropdown', 'component'],
    };
  },
  computed: {
    formattedHeaders() {
      return this.headers
        .filter((header) => header.type !== 'hidden' && header.csv !== 'only') // hidden headers or only for csv
        .map(flattenHeaderValues)
        .map(formatMeasurementHeaders(this.unit)) // add units to headers
        .map((header) => ({
          disableOn: () => false, // default to not disabled
          ...header,
          sortable: !header.nonSortable && !this.nonSortableColumns.includes(header.type),
        }));
    },
    formattedItems() {
      return this.data
        .map(formatMeasurementFields(this.headers, this.unit)) // apply unit conversions
        .map(formatCustomFields(this.headers));
    },
  },
  watch: {
    formattedItems() {
      this.resetSelectedRows();
    },
    generateCsvTrigger(triggered) {
      if (triggered) {
        const formattedMeasurementHeader = (header) => formatMeasurementHeaders(this.unit)(header)?.text;

        const { fields, values } = this.headers.reduce(
          (headers, { text, type, value, csv }) =>
            csv === 'exclude'
              ? headers
              : {
                  fields: [...headers.fields, formattedMeasurementHeader({ text, type })], // add units to headers
                  values: [...headers.values, value],
                },
          { fields: [], values: [] }
        );

        const data = this.formattedItems.map((item) =>
          Object.entries(item)
            .filter(([key]) => values.includes(key))
            .flatMap(([_, value]) => value)
        );

        this.$emit('onCsvGenerated', { fields, data });
      }
    },
  },
  methods: {
    applyCustomSort(items, { customSort: { type, order }, value: column }, direction) {
      switch (type) {
        case 'startsWith':
          applyStartsWithSort(items, column, order, direction);
          break;
        case 'numerical':
          applyNumericalSort(items, column, direction);
          break;
        default:
          items.sort((a, b) => (a < b ? -direction : direction));
      }
    },
    // Accepts array of the column names to sort by and their corresponding sort directions
    //   but this DataTable only supports sorting by a single column
    sort(items, [column], [descending]) {
      const header = this.headers.find(({ value }) => value === column);
      if (header) {
        const direction = descending ? -1 : 1;

        if (header.customSort) {
          this.applyCustomSort(items, header, direction); // NOTE: will mutate items
        } else if (header.type === 'distance' && this.unit === MeasurementUnit.IMPERIAL) {
          items.sort((a, b) => orderImperialDistances(a[column], b[column]) * direction);
        } else if (['distance', 'area', 'percentage'].includes(header.type)) {
          items.sort((a, b) => (+a[column] < +b[column] ? -direction : direction));
        } else if (header.type === 'date') {
          items.sort((a, b) => {
            // Get the original dates from 'data' to use in the ordering
            const { [column]: aOriginal } = this.originalRow(a);
            const { [column]: bOriginal } = this.originalRow(b);
            return orderDates(aOriginal, bOriginal) * direction;
          });
        } else {
          items.sort((a, b) => a[column].localeCompare(b[column], 'en', { sensitivity: 'base' }) * direction);
        }
      }

      return items;
    },
    alignClass(align) {
      switch (align) {
        case 'right':
          return 'text-right';
        case 'center':
          return 'center';
        default:
          return 'text-start';
      }
    },
    originalRow(row) {
      // Use the original data to get the corresponding row, based on the primaryKey
      return this.data.find((data) => data[this.primaryKey] === row[this.primaryKey]);
    },
    formatField(field, item) {
      if (typeof field.formatter === 'function') {
        // use original value
        const original = this.originalRow(item);
        return field.formatter(original[field.value]);
      }
      return item[field.value];
    },
    formatButtonField(field, item) {
      return typeof field.formatter === 'function' ? field.formatter(item, field) : field.text;
    },
    resetSelectedRows() {
      this.selectedRows = [];
      this.$emit('onSelectedRowsChanged', []);
    },
    handleRowClick(row) {
      this.$emit('onRowClick', row);
    },
    handleTableCellClick(column, row, event) {
      this.$emit('onTableCellClick', { column, row, event });
    },
    handleSelectedTableCellClick(column, row, { selected, event }) {
      this.$emit('onTableCellClick', { column, row, selected, event });
    },
    handleSelectedRowsChanged(selectedRows) {
      this.$emit('onSelectedRowsChanged', selectedRows);
    },
  },
};
</script>

<style>
.data-table .v-data-table-header {
  vertical-align: top;
}
</style>

<style scoped>
.table-cell-button {
  height: 28px !important;
}
.table-cell-link {
  padding-right: 1em;
  text-decoration: none;
}
.selectable-table-cell {
  margin: 0px;
  padding: 0px;
}
</style>
