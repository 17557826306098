<template>
  <Client :inspections="inspections" :loading="loading" @onInspectionClick="handleInspectionClick" />
</template>

<script>
import { mapGetters } from 'vuex';
import Client from './ClientView.vue';

export default {
  name: 'ClientContainer',
  components: {
    Client,
  },
  computed: mapGetters({
    inspections: 'auth/inspections',
    loading: 'auth/isLoadingInspections',
  }),
  methods: {
    handleInspectionClick(id) {
      this.$router.push({ path: '/inspection', query: { id } });
    },
  },
};
</script>
