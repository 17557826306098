var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "data-table elevation-1",
    attrs: {
      headers: _vm.formattedHeaders,
      items: _vm.formattedItems,
      "item-key": _vm.primaryKey,
      "calculate-widths": "",
      "items-per-page": _vm.itemsPerPage,
      "footer-props": {
        "items-per-page-options": [5, 10, 20, 30, 40, 50, -1]
      },
      "show-select": _vm.selectable,
      dense: "",
      loading: _vm.loading,
      "loading-text": _vm.loadingText,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDescending,
      "custom-sort": _vm.sort
    },
    on: { input: _vm.handleSelectedRowsChanged },
    scopedSlots: _vm._u(
      [
        {
          key: "top",
          fn: function() {
            return [_vm._t("toolbar")]
          },
          proxy: true
        },
        {
          key: "body",
          fn: function(ref) {
            var items = ref.items
            return [
              _c(
                "tbody",
                _vm._l(items, function(item) {
                  return _c(
                    "tr",
                    {
                      key: item[_vm.primaryKey],
                      on: {
                        click: function($event) {
                          return _vm.handleRowClick(item)
                        }
                      }
                    },
                    [
                      _vm.selectable
                        ? _c(
                            "td",
                            [
                              _c("v-checkbox", {
                                staticClass: "selectable-table-cell",
                                attrs: { value: item, "hide-details": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                  }
                                },
                                model: {
                                  value: _vm.selectedRows,
                                  callback: function($$v) {
                                    _vm.selectedRows = $$v
                                  },
                                  expression: "selectedRows"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.formattedHeaders, function(header, index) {
                        return _c(
                          "td",
                          { key: index, class: _vm.alignClass(header.align) },
                          [
                            header.type === "button"
                              ? _c(
                                  "Button",
                                  {
                                    staticClass: "table-cell-button",
                                    attrs: {
                                      size: "small",
                                      variant: "outlined",
                                      disabled: header.disableOn(
                                        header.value,
                                        item
                                      )
                                    },
                                    on: {
                                      onClick: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.handleTableCellClick(
                                          header.value,
                                          item,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatButtonField(header, item)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : header.type === "link"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "table-cell-link",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.handleTableCellClick(
                                          header.text,
                                          item,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item[header.value] ||
                                            header.defaultValue
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : header.type === "dropdown"
                              ? _c("SplitButton", {
                                  attrs: { options: header.typeConfig.options },
                                  on: {
                                    onSelected: function($event) {
                                      return _vm.handleSelectedTableCellClick(
                                        header.value,
                                        item,
                                        $event
                                      )
                                    }
                                  }
                                })
                              : header.component
                              ? _c(
                                  header.component,
                                  _vm._b(
                                    { tag: "component", attrs: { item: item } },
                                    "component",
                                    header,
                                    false
                                  )
                                )
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatField(header, item)) +
                                      " "
                                  )
                                ])
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ]
          }
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.selectedRows,
      callback: function($$v) {
        _vm.selectedRows = $$v
      },
      expression: "selectedRows"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }