var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.list, function(ref, index) {
      var text = ref.text
      var value = ref.value
      var component = ref.component
      var props = ref.props
      return _c(
        "div",
        { key: index },
        [
          component
            ? _c(_vm.configComponent(component), {
                tag: "component",
                attrs: { props: props }
              })
            : _c("div", [
                _c("div", { staticClass: "subtitle-2" }, [
                  _vm._v(_vm._s(text))
                ]),
                _c("div", { staticClass: "body-2" }, [_vm._v(_vm._s(value))])
              ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }