<template>
  <div v-if="show">
    <div class="subtitle-2">Coating Breakdown</div>
    <div class="body-2">{{ greatestAreaName }}</div>
    <div class="subtitle-2">Likelihood of Failure</div>
    <div class="body-2">{{ binnedValue }}</div>
  </div>
</template>

<script>
import { greatestArea } from '@/utils';
import { applyBinning } from '../utils';

export default {
  name: 'CoatingBreakdown',
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    corrosionLayers() {
      const corrosionKeysRegex = new RegExp('^AC-[LMH]$');
      return this.props.coverageLayers.filter(({ key }) => corrosionKeysRegex.test(key));
    },
    show() {
      return this.corrosionLayers.length > 0;
    },
    greatestAreaName() {
      return greatestArea(this.corrosionLayers).friendlyName;
    },
    binnedValue() {
      const { metricSurfaceArea, bins } = this.props;
      const totalCoverage = this.corrosionLayers.reduce((total, { area }) => total + area, 0);
      return applyBinning((totalCoverage / metricSurfaceArea) * 100, bins);
    },
  },
};
</script>
