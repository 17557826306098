<template>
  <div>
    <WorkPacksStats />
    <LoadingSpinner v-if="loading || generatingDocx" />
    <WorkPacks
      title="Work Pack Manager"
      :logged-in-user-id="user._id"
      :headers="headers"
      :filter-keys="filterKeys"
      :data="data"
      :toolbar-buttons="toolbarButtons(['batchDelete'], selectedRows.length === 0)"
      @onToolbarButtonClicked="handleToolbarButtonClicked"
      @onSelectedRowsChanged="handleSelectedRowsChanged"
      @onActionRequested="handleActionRequested"
    />
    <v-dialog v-model="showCreation" max-width="500px" :retain-focus="false">
      <WorkPackCreator
        @onCreateWorkPackAttempt="handleCreateWorkPackAttempt"
        @onCreateWorkPackSuccess="handleCreateWorkPackSuccess"
        @onCreateWorkPackFailure="handleCreateWorkPackFailure"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { WorkPackController } from '@/controllers';
import { WorkPackLevel, WorkPackItemAction, download } from '@/utils';
import { LoadingSpinner } from '@/react';
import { WorkPackCreator, WorkPacksStats } from './components';
import WorkPacks from './WorkPacks.vue';

export default {
  name: 'WorkPacksContainer',
  components: {
    LoadingSpinner,
    WorkPacks,
    WorkPackCreator,
    WorkPacksStats,
  },
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCreation: false,
      generatingDocx: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'workPacks/loading',
      workPacks: 'config/workPacks',
      data: 'workPacks/data',
      user: 'auth/user',
      toolbarButtons: 'workPacks/currentLevelToolbarButtonsWithDisabledState',
      currentLevelDisplayName: 'workPacks/currentLevelDisplayName',
      enableExportWorkPackDocx: 'auth/exportWorkPackDocxCapability',
      changeWorkPackAccess: 'auth/changeWorkPackAccessCapability',
    }),
    headers() {
      // Remove docx export if no exportWorkPackDocx capability
      const headers = this.workPacks.headers.reduce(
        (acc, header) =>
          acc.concat({
            ...header,
            ...(!this.enableExportWorkPackDocx &&
              header.typeConfig?.type === 'export' && {
                typeConfig: {
                  ...header.typeConfig,
                  options: header.typeConfig.options.filter((option) => option !== 'DOCX'),
                },
              }),
          }),
        []
      );

      if (this.changeWorkPackAccess) {
        return headers;
      }
      // Remove restrict access if no changeWorkPackAccess capability
      return headers.filter(({ typeConfig }) => typeConfig?.type !== 'access');
    },
    filterKeys() {
      return this.workPacks.headers.filter(({ filterKey }) => filterKey);
    },
  },
  created() {
    this.initialise();
  },
  methods: {
    ...mapActions({
      initialise: 'workPacks/initialise',
      deleteWorkPack: 'workPacks/delete',
      createWorkPack: 'workPacks/createWorkPack',
      exportWorkPackAsCsv: 'workPacks/exportWorkPackAsCsv',
      setWorkPackAccess: 'workPacks/setWorkPackAccess',
      setError: 'workPacks/setError',
    }),
    handleToolbarButtonClicked(item) {
      switch (item) {
        case 'createBtn':
          this.handleCreateWorkPack();
          break;
        case 'batchDelete':
          this.handleBatchDelete();
          break;
        default:
          break;
      }
    },
    handleCreateWorkPack() {
      this.showCreation = true;
    },
    handleCreateWorkPackAttempt() {
      this.showCreation = false;
    },
    handleCreateWorkPackSuccess(resetFormFunction) {
      resetFormFunction();
    },
    handleCreateWorkPackFailure() {
      this.showCreation = true;
    },
    handleSelectedRowsChanged(rows) {
      this.$emit('onSelectedRowsChanged', rows);
    },
    handleActionRequested({ column, row, selected }) {
      switch (column) {
        case WorkPackItemAction.DELETE:
          this.handleDelete(row);
          break;
        case WorkPackItemAction.EXPORT:
          switch (selected) {
            case 'CSV':
              this.handleExportCsv(row);
              break;
            case 'DOCX':
              this.handleExportDocx(row);
              break;
            default:
              throw new Error(`Unexpected dropdown option ${selected}`);
          }
          break;
        case WorkPackItemAction.PRIVATE:
          this.handleMakePrivate(selected, row);
          break;
        default:
          this.handleNextStage(row);
          break;
      }
    },
    handleNextStage(item) {
      this.$router.push({
        name: WorkPackLevel.ITEMS,
        query: {
          id: this.$route.query.id,
          wpType: item.type,
        },
        params: { wpId: item.id },
      });
    },
    handleDelete(row) {
      this.$emit('onDeleteRequested', `You are about to delete ${this.currentLevelDisplayName} ${row.name}`, () =>
        this.deleteWorkPack(row.id)
      );
    },
    handleBatchDelete() {
      this.$emit(
        'onBatchDelete',
        `You are about to delete ${this.selectedRows.length}
          ${this.currentLevelDisplayName}${this.selectedRows.length > 1 ? 's' : ''}`,
        ({ id }) => this.deleteWorkPack(id)
      );
    },
    handleExportCsv(row) {
      this.$emit('onExportRequested', `Export ${row.name} as CSV?`, () =>
        this.exportWorkPackAsCsv({ id: row.id, inspectionId: this.$route.query.id, baseUrl: window.location.origin })
      );
    },
    async handleExportDocx({ id: workPackId, name }) {
      this.generatingDocx = true;
      const { data, error } = await WorkPackController.exportWorkPackAsDocx({
        inspectionId: this.$route.query.id,
        workPackId,
      });
      if (error) {
        this.setError(error);
      } else {
        download(`${name}WorkPack.docx`, data);
      }
      this.generatingDocx = false;
    },
    handleMakePrivate(makePrivate, row) {
      this.setWorkPackAccess({ inspectionId: this.$route.query.id, wpId: row.id, makePrivate });
    },
  },
};
</script>
