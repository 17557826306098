import Vue from 'vue';
import { MetricController, ClientController } from '@/controllers';
import { axiosApiInstance, LoginStatus, UserRoles, intersects } from '@/utils';
import { getInstance } from '@/auth';

const initialState = () => ({
  error: undefined,
  status: LoginStatus.LOADING,
  token: undefined,
  applicationId: undefined,
  clientName: undefined,
  user: {
    _id: undefined,
    name: undefined,
    email: undefined,
    picture: undefined,
    roles: [],
    groups: [],
    connection: undefined,
  },
  inspections: undefined,
  isLoadingInspections: false,
  isAdminUser: false,
  capabilities: {
    exportWorkPackDocx: false,
    changeWorkPackAccess: false,
    groupManagementInspection: false,
    groupManagementUser: false,
    sendInvitation: false,
  },
  roles: [],
  groups: [],
  users: [],
  connections: [],
});

const getters = {
  auth0ClientExists: () => {
    const instance = getInstance();
    return !!instance?.auth0Client;
  },
  token: (state) => state.token,
  isAuthenticated: (state) => !!state.token,
  applicationId: (state) => state.applicationId,
  clientName: (state) => state.clientName,
  user: (state) => state.user,
  users: (state) => state.users,
  roles: (state) => state.roles,
  groups: (state) => state.groups,
  connections: (state) => state.connections,
  inspections: (state) => state.inspections,
  isLoadingInspections: (state) => state.isLoadingInspections,
  isAdminUser: (state) => state.isAdminUser,
  exportWorkPackDocxCapability: (state) => state.capabilities.exportWorkPackDocx,
  changeWorkPackAccessCapability: (state) => state.capabilities.changeWorkPackAccess,
  groupManagementUserCapability: (state) => state.capabilities.groupManagementUser,
  groupManagementInspectionCapability: (state) => state.capabilities.groupManagementInspection,
  sendInvitationCapability: (state) => state.capabilities.sendInvitation,
  status: (state) => state.status,
  error: (state) => state.error,
};

const actions = {
  initialiseAuthToken: async ({ dispatch }) => {
    const instance = getInstance();
    if (!instance.loading) {
      const authToken = await instance.getTokenSilently();
      await dispatch('attachInterceptors', authToken);
    } else {
      instance.$watch('loading', async (loading) => {
        if (!loading && instance.isAuthenticated) {
          const authToken = await instance.getTokenSilently();
          await dispatch('attachInterceptors', authToken);
        }
      });
    }
  },
  attachInterceptors: async ({ commit, dispatch }, token) => {
    if (token) {
      axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      commit('setToken', token);
      await dispatch('loadUserInfo');
    }

    axiosApiInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
          dispatch('clearError');
          const accessToken = await getInstance().getTokenSilently();
          commit('setToken', accessToken);
          await dispatch('loadUserInfo');
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axiosApiInstance(originalRequest);
        }
        return Promise.reject(error);
      }
    );
  },
  async retrieveToken({ commit }) {
    const instance = getInstance();
    if (instance.loading === false) {
      const authToken = await instance.getTokenSilently();
      commit('setToken', authToken);
    } else {
      instance.$watch('loading', async (loading) => {
        if (loading === false && instance.isAuthenticated) {
          const authToken = await instance.getTokenSilently();
          commit('setToken', authToken);
        }
      });
    }
  },
  loadUserInfo: async ({ commit, dispatch }) => {
    try {
      commit('setInspectionsLoading', true);
      const userInfo = await ClientController.getUserInfo();
      const {
        applicationId,
        roles: clientRoles,
        auth0Roles: roles,
        auth0Groups: groups,
        auth0Connection: connection,
        name,
        email,
        picture,
        _id,
      } = userInfo;
      const [role] = clientRoles;
      const user = { _id, name, email, picture, connection, roles, groups };
      const [
        {
          data: [metricData],
        },
        { data: metricChildren },
      ] = await Promise.all([
        MetricController.getMetricData(role),
        MetricController.getMetricChildren(role, 'inspection', 2),
      ]);
      localStorage.setItem('locale', JSON.stringify(metricData.data.locale));

      commit('setApplicationId', applicationId);
      commit('setStatus', LoginStatus.LOGGED_IN);
      commit('setClientName', metricData.name);
      commit('setUser', user);
      commit(
        'setInspections',
        metricChildren.map(({ name, _id: id, data }) => ({
          name,
          id,
          inspection_date: data.inspection_date || 'No date',
          image: data.cover_image_url,
          description: data.description || '',
        }))
      );
      dispatch('setCapabilities', user.roles);
    } catch (error) {
      commit('setStatus', LoginStatus.ERROR);
      commit('setError', error.response?.data.message || error.message);
    } finally {
      commit('setInspectionsLoading', false);
    }
  },
  async setCapabilities({ commit }, userRoles) {
    const contains = intersects(userRoles);

    // minimum WorkPack manager role
    if (contains([UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.WORK_PACK_MANAGER])) {
      commit('enableCapability', 'changeWorkPackAccess');
    }
    // >= Admin
    if (contains([UserRoles.SUPER_ADMIN, UserRoles.ADMIN])) {
      commit('setAdminUser', true);
      commit('enableCapability', 'sendInvitation');
      commit('enableCapability', 'groupManagementInspection');
      const [connections, users, roles, groups] = await Promise.all([
        ClientController.getConnections(),
        ClientController.getUsers(),
        ClientController.getRoles(),
        ClientController.getGroups(),
      ]);
      commit('setUsers', users);
      commit('setRoles', roles);
      commit('setGroups', groups);
      commit('setConnections', connections);
    }
    // SuperAdmin only
    if (contains([UserRoles.SUPER_ADMIN])) {
      commit('enableCapability', 'exportWorkPackDocx');
      commit('enableCapability', 'groupManagementUser');
    }
  },
  login: () => {
    getInstance().loginWithRedirect();
  },
  logout: ({ commit, dispatch }) => {
    commit('reset');
    dispatch('workPacks/reset', undefined, { root: true });
    delete axiosApiInstance.defaults.headers.common.Authorization;
    localStorage.removeItem('locale');

    getInstance().logout();
  },
  setError: ({ commit }, message) => {
    commit('setError', message);
  },
  clearError: ({ commit }) => {
    commit('setError', '');
  },
  setLoading: ({ commit }, loading) => {
    commit('setInspectionsLoading', loading);
  },
};

const mutations = {
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setStatus(state, status) {
    state.status = status;
  },
  setError: (state, message) => {
    state.error = message;
  },
  setToken(state, token) {
    state.token = token;
  },
  setClientName(state, clientName) {
    state.clientName = clientName;
  },
  setUser(state, user) {
    state.user = user;
  },
  setConnections(state, connections) {
    state.connections = connections;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  setInspections(state, inspections) {
    state.inspections = inspections;
  },
  setInspectionsLoading(state, loading) {
    state.isLoadingInspections = loading;
  },
  setAdminUser(state, isAdminUser) {
    state.isAdminUser = isAdminUser;
  },
  reset(state) {
    Object.assign(state, initialState());
  },
  enableCapability(state, capability) {
    Vue.set(state.capabilities, capability, true);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
