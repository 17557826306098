<template>
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-subtitle>
        <LoadingSpinner v-if="loading" />
        <EquipmentDetails v-if="data && Object.entries(data).length" :equipment-data="data" isAggregatedTable />
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { LoadingSpinner } from '@/react';
import MetricController from '@/controllers/MetricController';
import EquipmentDetails from './EquipmentDetailsContainer.vue';

export default {
  name: 'EquipmentDetailWrapper',
  components: {
    LoadingSpinner,
    EquipmentDetails,
  },
  props: {
    filter: {
      type: [String, Array],
      default: undefined,
    },
  },
  data() {
    return {
      loading: true,
      data: {},
    };
  },
  watch: {
    filter(value, previous) {
      if (value !== previous) {
        this.loadData(value);
      }
    },
  },
  created() {
    this.loadData(this.filter);
  },
  methods: {
    async loadData(filter) {
      this.data = {};
      this.loading = true;
      if (Array.isArray(filter) && filter.length === 0) {
        this.data = {};
        this.loading = false;
      } else if (filter && filter !== 'loading') {
        const filterParam =
          typeof filter === 'string'
            ? [{ key: 'meta.AutoCad:LineKey', value: [filter] }]
            : [{ key: 'meta.AutoCad:LineKey', value: filter }];

        const aggregationKey = '$data.meta.AutoCad:LineKey';
        const { data } = await MetricController.getMetricAggregate(
          this.$route.query.id,
          'asset',
          2,
          filterParam,
          aggregationKey
        );

        if (data.length > 1) {
          this.data = this.mergeCells(data);
        } else {
          const [equipmentData] = data;
          this.data = equipmentData;
        }
        this.loading = false;
      }
    },
    mergeCells(data) {
      // data = []
      const dataProcessor = (data) =>
        data.reduce((merged, equipmentInfo) => {
          const recursiveMerge = (equipmentInfo, initialData) =>
            Object.entries(equipmentInfo).reduce((acc, [key, value]) => {
              if (typeof value === 'object' && value !== null) {
                return { ...acc, [key]: recursiveMerge(value, acc[key]) };
              }

              if (acc[key] && value !== undefined && value !== null && acc[key] !== value) {
                if (key === 'AutoCad:Service') {
                  if (acc[key].includes(value)) {
                    return { ...acc };
                  }
                }

                return { ...acc, [key]: typeof value === 'string' ? `${acc[key]}, ${value}` : acc[key] + value };
              }
              return { ...acc, [key]: value };
            }, initialData || {});

          return recursiveMerge(equipmentInfo, merged);
        }, {});
      return dataProcessor(data);
    },
  },
};
</script>
