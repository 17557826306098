var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { flat: "" } },
    [
      _c(
        "v-container",
        { staticClass: "py-0" },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "start" } },
            [
              !_vm.selections.length
                ? _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [_c("v-chip", { style: { visibility: "hidden" } })],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.selections, function(selection, i) {
                return _c(
                  "v-col",
                  { key: selection[_vm.idField], staticClass: "shrink" },
                  [
                    _c(
                      "v-chip",
                      {
                        attrs: { disabled: _vm.loading, close: "" },
                        on: {
                          "click:close": function($event) {
                            return _vm.selected[_vm.page].splice(i, 1)
                          }
                        }
                      },
                      [
                        selection[_vm.imageField]
                          ? _c("v-avatar", { attrs: { left: "" } }, [
                              _c("img", {
                                attrs: {
                                  src: selection[_vm.imageField],
                                  alt: "profile-pic"
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(selection[_vm.textField]) + " ")
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              !_vm.allSelected
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        ref: "search",
                        attrs: {
                          "full-width": "",
                          "hide-details": "",
                          disabled: _vm.page !== _vm.GroupSelectorPages.ITEMS,
                          label:
                            _vm.page === _vm.GroupSelectorPages.GROUPS
                              ? "Select Groups"
                              : "Search " + _vm.selectionName,
                          "single-line": "",
                          suffix:
                            _vm.filteredItems.length +
                            " / " +
                            _vm.pageItems.length
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                : _vm.page === _vm.GroupSelectorPages.CONFIRM
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-card-text", { style: { height: "28.05vh" } }, [
                        _vm._v(
                          " You are about to link " +
                            _vm._s(
                              _vm.selected[_vm.GroupSelectorPages.ITEMS].length
                            ) +
                            " " +
                            _vm._s(_vm.selectionName.toLowerCase()) +
                            " with " +
                            _vm._s(
                              _vm.selected[_vm.GroupSelectorPages.GROUPS].length
                            ) +
                            " groups. Click attach to add " +
                            _vm._s(_vm.selectionName.toLowerCase()) +
                            " to groups and detach to remove " +
                            _vm._s(_vm.selectionName.toLowerCase()) +
                            " from groups. "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      !_vm.allSelected ? _c("v-divider") : _vm._e(),
      _vm.page === _vm.GroupSelectorPages.ITEMS
        ? _c(
            "v-list",
            { style: { overflow: "auto" }, attrs: { height: "22.4vh" } },
            [
              _vm._l(_vm.filteredItems, function(item) {
                return [
                  !_vm.selected[_vm.page].includes(item)
                    ? _c(
                        "v-list-item",
                        {
                          key: item[_vm.idField],
                          attrs: { disabled: _vm.loading },
                          on: {
                            click: function($event) {
                              return _vm.selected[_vm.page].push(item)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-img", {
                                attrs: {
                                  disabled: _vm.loading,
                                  src: item[_vm.imageField]
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(item[_vm.textField])
                            }
                          }),
                          _c("v-spacer"),
                          item[_vm.subtextField]
                            ? _c("v-list-item-subtitle", {
                                staticClass: "text-right",
                                domProps: {
                                  textContent: _vm._s(item[_vm.subtextField])
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _vm.page === _vm.GroupSelectorPages.GROUPS
        ? _c(
            "v-list",
            { style: { overflow: "auto" }, attrs: { height: "22.4vh" } },
            [
              _vm._l(_vm.groups, function(item) {
                return [
                  !_vm.selected[_vm.page].includes(item)
                    ? _c(
                        "v-list-item",
                        {
                          key: item[_vm.idField],
                          attrs: { disabled: _vm.loading },
                          on: {
                            click: function($event) {
                              return _vm.selected[_vm.page].push(item)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-img", {
                                attrs: {
                                  disabled: _vm.loading,
                                  src: item[_vm.imageField]
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(item[_vm.textField])
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-card-actions",
        [
          _vm.page === _vm.GroupSelectorPages.CONFIRM
            ? _c(
                "v-btn",
                {
                  attrs: { color: "purple", text: "" },
                  on: { click: _vm.reset }
                },
                [_vm._v("Reset")]
              )
            : _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !(
                      _vm.selected[_vm.page] &&
                      _vm.selected[_vm.page].length > 0
                    ),
                    color: "purple",
                    text: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.selected[_vm.page] = []
                    }
                  }
                },
                [_vm._v(" Clear ")]
              ),
          _c("v-spacer"),
          _vm.page === _vm.GroupSelectorPages.CONFIRM
            ? _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        color: "purple",
                        text: ""
                      },
                      on: { click: _vm.handleDetach }
                    },
                    [_vm._v("Detach")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        color: "purple",
                        text: ""
                      },
                      on: { click: _vm.handleAttach }
                    },
                    [_vm._v("Attach")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.page === _vm.GroupSelectorPages.ITEMS,
                    loading: _vm.loading,
                    color: "purple",
                    text: ""
                  },
                  on: { click: _vm.previous }
                },
                [_vm._v(" Previous ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !(
                      _vm.selected[_vm.page] &&
                      _vm.selected[_vm.page].length > 0
                    ),
                    loading: _vm.loading,
                    color: "purple",
                    text: ""
                  },
                  on: { click: _vm.next }
                },
                [_vm._v(" Next ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }