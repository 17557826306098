<template>
  <div>
    <v-container class="sticky-search">
      <v-card>
        <v-toolbar flat>
          <v-text-field
            ref="searchBar"
            v-model="searchText"
            hide-details
            append-icon="mdi-magnify"
            label="Search Platforms"
            single-line
            autofocus
            :suffix="`${filteredInspections.length} / ${inspections.length}`"
          />
        </v-toolbar>
      </v-card>
    </v-container>
    <v-container>
      <v-card :loading="loading">
        <v-skeleton-loader v-if="loading" type="list-item-avatar-two-line@15" />
        <v-list v-else subheader two-line>
          <v-list-item
            v-for="inspection in filteredInspections"
            :key="inspection.id"
            link
            @click="handleInspectionClick(inspection.id)"
          >
            <v-list-item-avatar>
              <v-img :src="inspection.image" height="100%" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="inspection.name" />
              <v-list-item-subtitle v-text="inspection.description" />
            </v-list-item-content>
            <v-list-item-action>
              <v-chip>Latest Inspection: {{ inspection.inspection_date }}</v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      <Snackbar :type="snackBarType" :text="snackBarText" @onClose="handleSnackbarClose" />
    </v-container>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import { Snackbar, notificationType } from '@/components/widgets';

export default {
  name: 'ClientView',
  components: {
    Snackbar,
  },
  props: {
    inspections: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackBarText: '',
      snackBarType: notificationType.none,
      searchText: '',
      fuseOptions: {
        keys: ['name'],
        threshold: 0.3,
      },
    };
  },

  computed: {
    fuse() {
      return new Fuse(this.inspections, this.fuseOptions);
    },
    filteredInspections() {
      const matches = this.fuse.search(this.searchText);
      if (matches.length > 0) return matches.map(({ item }) => item);
      // return all inspections by default if no search matches
      return [...this.inspections];
    },
  },

  methods: {
    handleInspectionClick(id) {
      this.$emit('onInspectionClick', id);
    },
    handleError(error) {
      this.snackBarText = error;
      this.snackBarType = notificationType.error;
    },
    handleSnackbarClose() {
      this.snackBarType = notificationType.none;
    },
  },
};
</script>

<style scoped>
.sticky-search {
  position: sticky;
  z-index: 1;
  top: 64px;
  padding-bottom: 0;
  background: white;
}
</style>
