var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SphericalView", {
    attrs: {
      "numeric-unit": _vm.numericUnit,
      "inspection-config": _vm.inspectionConfig,
      "snack-bar-type": _vm.type,
      "snack-bar-text": _vm.message,
      "developer-mode": _vm.developerMode,
      assets: _vm.assets,
      "checked-layers": _vm.checkedLayers,
      "platform-name": _vm.inspectionDocument.name,
      "platform-id": _vm.inspectionDocument._id,
      "corrosion-data": _vm.corrosionData,
      "selected-asset": _vm.selectedAsset,
      "defect-layers": _vm.defectLayers,
      "asset-layers": _vm.assetLayers,
      "selected-polygon": _vm.selectedPolygon,
      markers: _vm.markers,
      "is-measurement-line-by-id-selected": _vm.isMeasurementLineByIdSelected,
      "image-document": _vm.imageDocument,
      "reload-image": _vm.reloadImage,
      "neighbouring-images": _vm.neighbouringImagesInRange,
      "neighbouring-images-max-range": _vm.neighbouringImagesMaxRange,
      "neighbouring-images-range": _vm.neighbouringImagesRange,
      "stroke-width": _vm.strokeWidth,
      "asset-fill-enabled": _vm.assetFillEnabled,
      "highlight-reviewed-enabled": _vm.highlightReviewedEnabled,
      "active-pano-mode": _vm.activePanoMode,
      "pano-initial-equipment-id": _vm.panoInitialEquipmentId,
      "set-camera-on-corrosion": _vm.setCameraOnCorrosion,
      "set-camera-on-value": _vm.setCameraOnValue,
      "blister-error": _vm.blisterError,
      "blister-loading": _vm.blisterLoading,
      "blister-data": _vm.blisterData,
      "blister-layers": _vm.selectedBlisterLayers
    },
    on: {
      onUpdateMarkers: _vm.handleUpdateMarkers,
      onSelectMarker: _vm.handleSelectMarker,
      onCtrlUpdateAnnotations: _vm.handleCtrlUpdateAnnotations,
      onAltUpdateAnnotations: _vm.handleAltUpdateAnnotations,
      onDeleteAnnotation: _vm.handleDeleteAnnotation,
      onUpdateCheckedLayers: _vm.handleUpdateCheckedLayers,
      onUpdateLayers: _vm.handleUpdateLayers,
      onSelectEquipment: _vm.handleSelectEquipment,
      onClearSelectedPolygon: _vm.handleClearSelectedPolygon,
      onLoadURLQuery: _vm.handleLoadURLQuery,
      onNeighbouringImagesRangeChange: _vm.handleNeighbouringImagesRangeChange,
      onToggleAssetFillEnabled: _vm.handleToggleAssetFillEnabled,
      onToggleHighlighReviewedEnabled: _vm.handleToggleHighlighReviewedEnabled,
      onSnackbarDisplay: _vm.handleSnackbarDisplay,
      onSnackbarClose: _vm.handleSnackbarClose,
      onToggleSelectedMeasurementLine: _vm.handleToggleSelectedMeasurementLine,
      onExportData: _vm.handleExportDataToCsv,
      onReviewPartConfirmed: _vm.handleReviewPartConfirmed,
      onLoadBlister: _vm.handleLoadBlister,
      onBlisterMarkerSelected: _vm.handleBlisterMarkerSelected,
      onUpdateCheckedBlisterLayers: _vm.handleUpdateCheckedBlisterLayers,
      onCloseBlisterHeight: _vm.handleCloseBlisterHeight
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }