<template>
  <EquipmentList :list="details" />
</template>

<script>
import { mapGetters } from 'vuex';
import { mapEquipmentFields } from '@/utils';
import EquipmentList from './components/EquipmentList.vue';

export default {
  name: 'EquipmentDetailsContainer',
  components: {
    EquipmentList,
  },
  props: {
    equipmentData: {
      type: Object,
      default: () => ({}),
    },
    isAggregatedTable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      inspectionConfig: 'config/inspectionConfig',
    }),
    details() {
      const { equipmentInfoFields, corrosionLayers, platformFeatures } = this.inspectionConfig;
      const aggregatedEquipmentInfoFields = equipmentInfoFields.filter(
        ({ path }) =>
          path !== "data.meta['AutoCad:Class']" &&
          path !== "data.meta['AutoCad:Size']" &&
          path !== "data.meta['AutoCad:LineKey']"
      );
      return mapEquipmentFields({
        equipmentInfoFields: this.isAggregatedTable ? aggregatedEquipmentInfoFields : equipmentInfoFields,
        corrosionLayers,
        platformFeatures,
        equipmentData: this.equipmentData,
      });
    },
  },
};
</script>
