<template>
  <v-combobox
    ref="input"
    :value="selected"
    :items="options"
    :label="label"
    :prepend-icon="prependIcon"
    :placeholder="placeholder"
    :rules="rules"
    :multiple="multiple"
    :required="required"
    :readonly="readonly"
    :disabled="disabled"
    :clearable="!readonly && !disabled"
    @blur="handleBlur"
    @update:search-input="handleUpdate"
    @input="handleChange"
    @keyup.stop.prevent
  />
</template>

<script>
export default {
  name: 'ListInputField',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    initialSelected: {
      type: [Array, String],
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    preventPartialMatch: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.getInitialSelected(),
      rules: [(v) => !this.required || v != null || 'An option is required'],
    };
  },
  watch: {
    initialSelected(value) {
      if (value === undefined) {
        // Hack to clear input
        this.$refs.input.reset();
      } else {
        this.selected = value;
      }
    },
  },
  methods: {
    getInitialSelected() {
      if (typeof this.initialSelected === 'string') {
        return this.initialSelected;
      }
      if (Array.isArray(this.initialSelected)) {
        const initialSelected = this.initialSelected.filter(Boolean);
        return initialSelected.length > 0 ? initialSelected : undefined;
      }
      return undefined;
    },
    update(text) {
      const firstStartsWithOrContains = (search, string) =>
        // Find first option that starts with string or else first option that contains string
        string &&
        (search.find((option) => new RegExp(`^${string}`, 'i').test(option)) ||
          search.find((option) => new RegExp(string, 'i').test(option)));

      if (this.checkInput) {
        this.checkInput = false;
        const originalSelected = this.selected;
        if (this.multiple) {
          const last = (this.selected ? this.selected.splice(-1) : []).join(text);
          last.split(/[ ,]+/).forEach((string) => {
            const selected = firstStartsWithOrContains(this.options, string);
            if (selected && !this.selected.includes(selected)) {
              this.selected.push(selected);
            }
          });
        } else {
          this.selected = firstStartsWithOrContains(this.options, text);
        }

        const selectedAsJsonString = JSON.stringify(this.selected);
        if (
          selectedAsJsonString !== JSON.stringify(originalSelected) &&
          (!this.preventPartialMatch || selectedAsJsonString === JSON.stringify(text))
        ) {
          this.$emit('onChange', this.selected);
        } else {
          // selected reset, Hack to detect change
          this.selected = undefined;
          this.$nextTick(() => {
            this.selected = originalSelected;
          });
        }
      }
    },
    handleUpdate(text) {
      this.update(text);
    },
    handleChange(value) {
      // Prevent multiple duplicate changes triggering
      if (JSON.stringify(this.selected) !== JSON.stringify(value)) {
        if (this.preventPartialMatch) {
          this.checkInput = true;
          this.update(value);
        } else {
          this.selected = value;
          this.$emit('onChange', value);
        }
      }
    },
    handleBlur() {
      if (this.autoComplete) {
        this.checkInput = true;
      }
    },
  },
};
</script>
